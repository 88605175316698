// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   Paper,
//   Button,
//   Stack,
//   Divider,
//   useMediaQuery,
//   useTheme,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
// } from "@mui/material";
// import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
// import FlightIcon from "@mui/icons-material/Flight";
// import FlightClassIcon from "@mui/icons-material/FlightClass";
// // ... other imports

// const RvFare = ({
//   fdetails,
//   durationCalculator,
//   isSelected,
//   onSelect,
// }) => {
//   const airlineCode = fdetails.Segments[0][0].Airline.AirlineCode;
//   const flightKey = `${airlineCode}-${fdetails.Segments[0][0].Airline.FlightNumber}`;
//   // ... (rest of the code, including importAllLogos and airlineLogos)
//   const importAllLogos = (requireContext) => {
//     const logos = {};
//     requireContext.keys().forEach((key) => {
//       const airlineCode = key.replace("./", "").replace(".gif", "");
//       logos[airlineCode] = requireContext(key);
//     });
//     return logos;
//   };
//   const airlineLogos = importAllLogos(
//     require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
//   );

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const isMediumScreen = useMediaQuery(
//     theme.breakpoints.up("sm") && theme.breakpoints.down("md")
//   );

//   const [selectedCard, setSelectedCard] = useState(false);

//   const handleCardSelection = () => {
//     setSelectedCard(!selectedCard);
//   };

//   return (
//     <Box sx={{ mt: 2, maxWidth: "100%" }}>
//       <Paper
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           borderRadius: "0px 0px 20px 20px",
//           mt: -1,
//           border: selectedCard ? "2px solid #007bff" : "none",
//           // border: selectedValue === flightKey ? "2px solid #007bff" : "1px solid #ddd",
//           backgroundColor: selectedCard ? "#f0f0f0" : "inherit",
//         }}
//         onClick={onSelect}
//       >
//         <Stack
//           direction="row"
//           alignItems="center"
//           justifyContent="space-between"
//         >
//           <Stack direction="row" alignItems="center">
//             {/* <Radio checked={isSelected} onChange={onSelect} /> */}
//             <img
//               src={airlineLogos[fdetails.Segments[0][0].Airline.AirlineCode]}
//               alt={`${fdetails.Segments[0][0].Airline.AirlineName} logo`}
//               style={{ width: 30, height: 30, marginRight: 10 }}
//             />
//             <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
//               {fdetails.Segments[0][0].Airline.AirlineName} -{" "}
//               {fdetails.Segments[0][0].Airline.FlightNumber}
//             </Typography>
//           </Stack>
//           <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
//             ₹ {fdetails.Fare.PublishedFare * 1.11}
//           </Typography>
//         </Stack>

//         <Box
//           sx={{
//             px: 1,
//             py: 3,
//             display: "flex",
//             justifyContent: "space-between",
//             flexDirection: isSmallScreen ? "column" : "row",
//             alignItems: "center", // Align items to the center
//           }}
//         >
//           <Stack
//             direction={isSmallScreen ? "column" : "row"}
//             spacing={2}
//             alignItems="center"
//           >
//             <Stack direction="column" alignItems="center">
//               <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
//                 {new Date(
//                   fdetails.Segments[0][0].Origin.DepTime
//                 ).toLocaleTimeString("en-GB", {
//                   hour: "2-digit",
//                   minute: "2-digit",
//                 })}
//               </Typography>
//               <Typography>
//                 {fdetails.Segments[0][0].Origin.Airport.CityName}
//               </Typography>
//             </Stack>

//             <Stack direction="row" alignItems="center">
//               <FlightIcon sx={{ fontSize: 20, color: "#027BFE" }} />
//               <Typography
//                 sx={{ fontSize: 12, fontWeight: 600, color: "#027BFE" }}
//               >
//                 {durationCalculator(
//                   fdetails.Segments[0][0].Origin.DepTime,
//                   fdetails.Segments[0][fdetails.Segments[0].length - 1]
//                     .Destination.ArrTime
//                 )}
//               </Typography>
//             </Stack>

//             <Stack direction="column" alignItems="center">
//               <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
//                 {new Date(
//                   fdetails.Segments[0][
//                     fdetails.Segments[0].length - 1
//                   ].Destination.ArrTime
//                 ).toLocaleTimeString("en-GB", {
//                   hour: "2-digit",
//                   minute: "2-digit",
//                 })}
//               </Typography>
//               <Typography>
//                 {
//                   fdetails.Segments[0][fdetails.Segments[0].length - 1]
//                     .Destination.Airport.CityName
//                 }
//               </Typography>
//             </Stack>
//           </Stack>
//           <Stack direction="column" alignItems="center">
//             <FlightClassIcon sx={{ fontSize: 20, color: "#027BFE" }} />
//             <Typography
//               sx={{ fontSize: 12, fontWeight: 600, color: "#027BFE" }}
//             >
//               {
//                 fdetails.Segments[0][fdetails.Segments[0].length - 1]
//                   .NoOfSeatAvailable
//               }{" "}
//               Left
//             </Typography>
//           </Stack>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default RvFare;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import FlightClassIcon from "@mui/icons-material/FlightClass";

const RvFare = ({ fdetails, durationCalculator, onSelect }) => {
  const airlineCode = fdetails.Segments[0][0].Airline.AirlineCode;

  // Import airline logos
  const importAllLogos = (requireContext) => {
    const logos = {};
    requireContext.keys().forEach((key) => {
      const airlineCode = key.replace("./", "").replace(".gif", "");
      logos[airlineCode] = requireContext(key);
    });
    return logos;
  };
  const airlineLogos = importAllLogos(
    require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper
      sx={{
        // borderRadius: "10px",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        border: "1px solid #ddd",
        "&:hover": {
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        },
        // width: "100%"
      }}
      onClick={onSelect}
    >
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        spacing={2}
        sx={{
          padding: isSmallScreen ? 2 : 3,
          alignItems: "center",
        }}
      >
        {/* Airline Logo and Name */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <img
            src={airlineLogos[airlineCode]}
            alt={`${fdetails.Segments[0][0].Airline.AirlineName} logo`}
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              border: "1px solid #ccc",
            }}
          />
          <Typography sx={{ fontSize: 16, fontWeight: 600, color: "#333" }}>
            {fdetails.Segments[0][0].Airline.AirlineName} -{" "}
            {fdetails.Segments[0][0].Airline.FlightNumber}
          </Typography>
        </Stack>

        {/* Flight Timing Details */}
        <Stack
          direction={isSmallScreen ? "column" : "row"}
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          sx={{ flex: 1 }}
        >
          {/* Departure Details */}
          <Stack alignItems="center">
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
              {new Date(
                fdetails.Segments[0][0].Origin.DepTime
              ).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: "#666" }}>
              {fdetails.Segments[0][0].Origin.Airport.CityName}
            </Typography>
          </Stack>

          {/* Flight Duration */}
          <Stack alignItems="center">
            <FlightIcon sx={{ fontSize: 24, color: "#027BFE" }} />
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, color: "#027BFE" }}
            >
              {durationCalculator(
                fdetails.Segments[0][0].Origin.DepTime,
                fdetails.Segments[0][fdetails.Segments[0].length - 1]
                  .Destination.ArrTime
              )}
            </Typography>
          </Stack>

          {/* Arrival Details */}
          <Stack alignItems="center">
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
              {new Date(
                fdetails.Segments[0][
                  fdetails.Segments[0].length - 1
                ].Destination.ArrTime
              ).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: "#666" }}>
              {
                fdetails.Segments[0][fdetails.Segments[0].length - 1]
                  .Destination.Airport.CityName
              }
            </Typography>
          </Stack>
        </Stack>

        {/* Seat Availability */}
        <Stack alignItems="center" spacing={1}>
          <FlightClassIcon sx={{ fontSize: 24, color: "#027BFE" }} />
          <Typography sx={{ fontSize: 14, fontWeight: 600, color: "#027BFE" }}>
            {
              fdetails.Segments[0][fdetails.Segments[0].length - 1]
                .NoOfSeatAvailable
            }{" "}
            Left
          </Typography>
        </Stack>

        {/* Fare */}
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            color: "#333",
            whiteSpace: "nowrap",
          }}
        >
          ₹ {(fdetails.Fare.PublishedFare * 1.11).toFixed(0)}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default RvFare;
