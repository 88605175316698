import React from "react";
import CreditCardBillPaymentsList from "../Services/CreditCardBillPaymentsList";
import { Box, Typography } from "@mui/material";

export const CCBillPayList = () => {
  return (
    <>
      <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Typography sx={{fontSize:'22px', fontWeight:'bold', marginBottom:'1%'}}>Credit Card Bill Payments</Typography>
        <CreditCardBillPaymentsList pageSize={25} />
      </Box>
    </>
  );
};
