import React, { useState, useEffect } from "react";
import {
  Grid,
  FormLabel,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import axios from "axios";

const NewBookCylinder = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [gasProviders, setGasProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [formData, setFormData] = useState({});
  const [inputParams, setInputParams] = useState({});

  // Fetch gas providers from API
  const fetchGasProviders = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getAllGasServicePro",
        {
          headers: {
            Authorization:
              `Bearer ${token}`,
          },
        }
      );
      setGasProviders(response.data);
    } catch (error) {
      console.error("Error fetching gas providers:", error);
      setGasProviders([]); // Handle errors by setting an empty array
    }
  };

  useEffect(() => {
    fetchGasProviders();
  }, []);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleProviderChange = (e) => {
    const providerId = e.target.value;
    setSelectedProvider(providerId);

    // Reset inputParams and formData for the selected provider
    const selectedProviderData = gasProviders.find(
      (provider) => provider.id === providerId
    );
    setInputParams(selectedProviderData?.inputParams || {});
    setFormData({});
  };

  const handleInputChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  return (
    <Grid style={{ marginTop: "1%" }}>
      <FormLabel
        component="legend"
        style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
      >
        Book a Cylinder
      </FormLabel>
      <Grid container spacing={2} style={{ marginTop: "1%" }}>
        {/* Gas Provider Dropdown */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            select
            label="Gas Provider"
            variant="outlined"
            value={selectedProvider}
            onChange={handleProviderChange}
          >
            {gasProviders.map((provider) => (
              <MenuItem key={provider.id} value={provider.id}>
                {provider.providerName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Conditional Input Fields */}
        {Object.entries(inputParams).map(([key, label]) => (
          <Grid item xs={12} md={4} key={key}>
            <TextField
              fullWidth
              label={label}
              variant="outlined"
              value={formData[key] || ""}
              onChange={(e) => handleInputChange(key, e.target.value)}
            />
          </Grid>
        ))}

        {/* Proceed Button */}
        <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              color: "white",
              backgroundColor: "#027BFE",
              textTransform: "none",
              fontSize: "16px",
              width: "16%",
            }}
            onClick={handleModalOpen}
            disabled={!selectedProvider || Object.keys(inputParams).length === 0}
          >
            Proceed
          </Button>
        </Grid>
      </Grid>

      {/* Payment Modal */}
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
        <DialogContent>
          <p>Payment and wallet balance sections go here...</p>
          <p>Form Data: {JSON.stringify(formData, null, 2)}</p>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default NewBookCylinder;
