import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, IconButton, Paper, Modal } from '@mui/material';
import { NewBBPSIcon } from '../../res/NewWebIcons'; // Bharat Connect Logo

// Material UI Icons
import creditcard from '../../res/Services/creditcard.svg';
import electricity from '../../res/Services/electricity.svg';
import dth from '../../res/Services/dth.svg';
import broadband from '../../res/Services/broadband.svg';
import educationloan from '../../res/Services/educationloan.svg';
import gascylinder from '../../res/Services/gascylinder.svg';
import landline from '../../res/Services/landline.svg';
import payloans from '../../res/Services/payloans.svg';
import rent from '../../res/Services/rent.svg';
import waterbills from '../../res/Services/waterbills.svg';
import mobilerecharge from '../../res/Services/mobilerecharge.svg';
import donation from '../../res/Services/donation.svg';
import fastag from '../../res/Services/fastag.svg';
import healthinsurance from '../../res/Services/healthinsurance.svg';
import hospital from '../../res/Services/hospital.svg';
import lifeinsurance from '../../res/Services/lifeinsurance.svg';
import municipalservices from '../../res/Services/muncipalservices.svg';
import municipaltaxes from '../../res/Services/muncipaltaxes.svg';
import recurringdeposit from '../../res/Services/recurringdeposits.svg';
import subscription from '../../res/Services/subscription.svg';
import ncmc from '../../res/Services/ncmc.svg';
import nps from '../../res/Services/nps.svg';
import electricityprepaid from '../../res/Services/electricityprepaid.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
const services = [
  { icon: <img src={mobilerecharge} alt="Mobile Recharge" />, label: 'Mobile Recharge', route: '/mobile-recharge' },
  { icon: <img src={creditcard} alt="Credit Card" />, label: 'Credit Card', route: '/credit-card' },
  { icon: <img src={electricity} alt="Electricity" />, label: 'Electricity', route: '/electricity' },
  { icon: <img src={dth} alt="DTH" />, label: 'DTH', route: '/dth' },
  { icon: <img src={dth} alt="Cable TV" />, label: 'Cable TV', route: '/cable-tv' },

  { icon: <img src={broadband} alt="Broadband" />, label: 'Broadband', route: '/broadband' },
  { icon: <img src={gascylinder} alt="Gas (LPG Cylinder)" />, label: 'Gas (LPG Cylinder)', route: '/gas-cylinder' },
  {
    icon: <img src={gascylinder} alt="Gas (PNG)" />, label: "Gas (PNG)", route: '/gas-png'
  },

  { icon: <img src={educationloan} alt="Education Fee" />, label: 'Education Fee', route: '/education-fee' },
  { icon: <img src={waterbills} alt="Water" />, label: 'Water', route: '/water' },
  { icon: <img src={landline} alt="Landline" />, label: 'Landline', route: '/landline' },
];

const moreServices = [
  { icon: <img src={donation} alt="Donation" />, label: 'Donation' },
  { icon: <img src={fastag} alt="FASTag Recharge" />, label: "FASTag Recharge" },
  { icon: <img src={lifeinsurance} alt="Insurance" />, label: 'Insurance' },
  { icon: <img src={municipaltaxes} alt="Municipal Taxes" />, label: 'Municipal Taxes' },
  { icon: <img src={recurringdeposit} alt="Recurring Deposit" />, label: 'Recurring Deposit' },
  { icon: <img src={subscription} alt="Subscription" />, label: 'Subscription' },
  { icon: <img src={subscription} alt="Hospital & Pathology" />, label: "Hospital & Pathology" },
  { icon: <img src={subscription} alt="Housing Society" />, label: "Housing Society" },
  { icon: <img src={ncmc} alt="Loan EMI" />, label: "Loan EMI" },
  { icon: <img src={payloans} alt="Pay Loans" />, label: 'Pay Loans', route: '/pay-loans' },
  { icon: <img src={rent} alt="Rental" />, label: 'Rental', route: '/rental' },

  { icon: <img src={ncmc} alt="NCMC Recharge" />, label: 'NCMC Recharge' },
  {
    icon: <img src={nps} alt="National Pension Scheme (NPS)" />, label: "National Pension Scheme (NPS)"
  },
  { icon: <img src={electricityprepaid} alt="Electricity (Prepaid)" />, label: "Electricity (Prepaid)" },
  { icon: <img src={electricityprepaid} alt="Clubs & Associations" />, label: 'Clubs & Associations' },

];

const NewPayBills = () => {
  const [open, setOpen] = useState(false);
  const [servicess, setServicess] = useState([]);
  // const [moreServices, setMoreServices] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleServiceClick = (route, label) => {
    navigate(`/retailerServicesNew`, { state: { label } }); // Pass label as state
  };
  const updatedMoreServices = [...moreServices];
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = sessionStorage.getItem('jwtToken');
        if (!token) {
          throw new Error('Token not found in sessionStorage');
        }

        const response = await fetch(
          'https://api.ipaisa.co.in/myapp/billPayments/getAllCategory',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const responseData = await response.json();

        // Extract categoryName values from the response data
        const categoryNames = responseData.body.data.map(item => item.categoryName);
        const existingLabels = new Set([
          ...services.map((service) => service.label),
          ...moreServices.map((service) => service.label),
        ]);

        // Find missing categories
        const missingCategories = categoryNames.filter(
          (category) => !existingLabels.has(category)
        );
        console.log(missingCategories);
        // Add missing categories to moreServices
        const combinedServices = [
          ...moreServices,
          ...categoryNames
            .filter((category) => !existingLabels.has(category))
            .map((category) => {
              if (["Mobile (Postpaid)", "Mobile (Prepaid)"].includes(category)) {
                return {
                  icon: <img src={mobilerecharge} alt="Mobile Recharge" />, // Default icon
                  label: 'Mobile Recharge',
                  route: '/mobile-recharge',
                };
              }

              return {
                icon: <img src={mobilerecharge} alt={category} />, // Default icon for others
                label: category,
              };
            }),
        ];
        setServicess(combinedServices);
        console.log(combinedServices);

      } catch (err) {
        setError(err.message);
      }
    };

    fetchServices();
  }, []);

  console.log(updatedMoreServices);

  return (
    <Box sx={{ padding: '2rem', margin: '0 auto', marginTop: '4%' }}>
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' }, // Responsive alignment
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Recharge & Pay Bills On IPaisa
        </Typography>
        <NewBBPSIcon style={{ width: 120, height: 'auto' }} />
      </Box>

      {/* Services Grid */}
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '1.5rem',
          borderRadius: '12px',
          boxShadow: 'none',
        }}
      >
        <Grid container spacing={3}>
          {services.map((service, index) => (
            <Grid
              item
              xs={6} // Two columns on small screens
              sm={4} // Three columns on medium screens
              md={3} // Four columns on large screens
              key={index}
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <IconButton
                sx={{
                  backgroundColor: 'rgba(2, 123, 254, 0.1)',
                  color: '#027BFE',
                  width: 70,
                  height: 70,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0 auto',
                  '&:hover': {
                    backgroundColor: 'rgba(2, 123, 254, 0.4)',
                  },
                }}
                onClick={() => handleServiceClick(service.route, service.label)} // Pass label along with route
              >
                {service.icon}
              </IconButton>

              <Typography sx={{ mt: 1, fontSize: '0.875rem', fontWeight: 500 }}>
                {service.label}
              </Typography>
            </Grid>
          ))}

          {/* "More" Button */}
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            <IconButton
              sx={{
                backgroundColor: 'rgba(2, 123, 254, 0.1)', // Set background with desired opacity
                color: '#027BFE',
                width: 70,
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                '&:hover': {
                  backgroundColor: 'rgba(2, 123, 254, 0.4)', // Slightly darker on hover
                },
              }}
              onClick={handleOpen}
            >
              <MoreHorizIcon />
            </IconButton>
            <Typography sx={{ mt: 1, fontSize: '0.875rem', fontWeight: 500 }}>
              More
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px',
            p: 4,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Other Services
            </Typography>
            <NewBBPSIcon style={{ width: 120, height: 'auto' }} />
          </Box>
          <Grid container spacing={3}>
            {servicess.map((service, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                key={index}
                sx={{ textAlign: 'center' }}
              >
                <IconButton
                  sx={{
                    backgroundColor: 'rgba(2, 123, 254, 0.1)',
                    color: '#027BFE',
                    width: 70,
                    height: 70,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    '&:hover': {
                      backgroundColor: 'rgba(2, 123, 254, 0.4)',
                    },
                  }}
                  onClick={() => handleServiceClick(service.route, service.label)} // Pass label along with route

                >
                  {service.icon}
                </IconButton>
                <Typography sx={{ mt: 1, fontSize: '0.875rem', fontWeight: 500 }}>
                  {service.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default NewPayBills;
