import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Download, FilterAlt } from "@mui/icons-material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import * as writeXLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const RazorHierarchyTrans = () => {
  const [trnsList, setTranslist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const [pageFrom, setPageFrom] = useState(0); // Total pages
  const [pageTo, setPageTo] = useState(0); // Total pages
  const [pageSize, setPageSize] = useState(50);
  const [hasNext, setHasNext] = useState(true);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null); // For filter icon menu
  const [availableUserTypes, setAvailableUserTypes] = useState([]);
  const [allavailableUsers, setUsers] = useState([]);
  const [userType, setUserType] = useState("");
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [openDownloadWindow, setOpenDownloadWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const userType2 = sessionStorage.getItem("uType");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [filters, setFilters] = useState({
    startDate: dayjs().startOf("day").format("YYYY-MM-DDTHH:mm"), // Default from night
    endDate: dayjs().format("YYYY-MM-DDTHH:mm"), // Default to today
  }); // Filter states
  var totalAmount = 0;
  const classes = useStyles();
  const navigate = useNavigate();

  const statusColors = {
    received: "#67C529",
    pending: "#E8DC22",
    uncaptured: "#E8DC22",
    "timed out": "#E29D95",
    failure: "#FF0000",
    unsettled: "#E8DC22",
    "partially refunded": "#3D8AC5",
    refunded: "#655AA1",
  };

  //Handle for usertype selection
  const handleUserChange = (event) => {
    // setUserType(event.target.value);
    const selectedUser = event.target.value;
    console.log(selectedUser.userid);
    // setSelectedUserId(null);
    setFilters((prevFilters) => ({
      ...prevFilters,
      uid: selectedUser.userid,
    }));
  };

  const handleClickOpenDownloadWindow = () => {
    setOpenDownloadWindow(true);
  };

  const handleCloseDownloadWindow = () => {
    console.log("close window clicked");
    setOpenDownloadWindow(false);
  };

  const onClickGetList = () => {
    fetchTransList(0);
  };
  //Handle for usertype selection
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    // setSelectedUserId(null);
  };

  // Handle filter menu open/close
  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchTransList(newPage);
  };
  // Separate function to handle DatePicker change
  const handleDateChange = (key, newValue) => {
    let dateWithTime = newValue;

    if (newValue && !newValue.hour() && !newValue.minute()) {
      // Set default time to 12:00 AM if only date is selected
      dateWithTime = newValue.set("hour", 0).set("minute", 0);
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: dateWithTime ? dayjs(dateWithTime).format("YYYY-MM-DDTHH:mm") : "",
    }));
  };

  const convertToDate = (timestamp) => {
    // Convert to milliseconds and create a Date object
    const date = new Date(timestamp * 1000);

    // Format the date as needed
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return formattedDate;
  };

  // Function to handle filter changes for text inputs
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  // Apply filters and call the fetchList function with page 1
  const applyFilters = () => {
    handleFilterMenuClose();
    if (
      filters.endDate &&
      filters.startDate &&
      dayjs(filters.endDate).isBefore(dayjs(filters.startDate))
    ) {
      // toast.error("End Date cannot be less than Start Date.");
      Swal.fire({
        title: "Something Went Wrong..!!",
        text: "End Date cannot be less than Start Date.",
        icon: "error",
      });
      return;
    } else {
      // setErrorMessage(""); // Clear error if validation passes
      fetchTransList(0); // Trigger fetch with the current filters
    }
    // console.log("Filters applied:", filters); // Debugging
  };

  const fetchList = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    // console.log(filteredTransactions + " this is filter");

    try {
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/razorqr/husers",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      //   console.log(response);
      const resultsArr = response.data;
      setUsers(resultsArr);
      //   console.log(resultsArr);
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };
  const handleNavigation = () => {
    navigate("/kingqrtrans"); // Specify your target route here
  };

  //   const Dropdown = ({ users, onUserSelect }) => {
  //     const [selectedUser, setSelectedUser] = useState('');

  //     const handleChange = (event) => {
  //       const userId = event.target.value;
  //       setSelectedUser(userId);
  //       onUserSelect(userId); // Call parent function to notify about selected user
  //     };
  const fetchTransList = async (page) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    // Prepare the params based on the filters state
    const params = {
      skip: page,
      from: filters.startDate
        ? dayjs(filters.startDate).format("YYYY-MM-DD")
        : ``,
      to: filters.endDate ? dayjs(filters.endDate).format("YYYY-MM-DD") : ``,
    };

    // console.log(params);
    try {
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/razorqr/hreport",
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            skip: page,
            from: filters.startDate
              ? dayjs(filters.startDate).format("YYYY-MM-DDTHH:mm")
              : ``,
            to: filters.endDate
              ? dayjs(filters.endDate).format("YYYY-MM-DDTHH:mm")
              : ``,
            userid: filters.uid || ``,
            pageSize: pageSize,
          },
        }
      );
      // console.log(response.data);
      const resultsArr = response.data;
      if (resultsArr.length < 50) {
        setHasNext(true);
      } else {
        setHasNext(false);
      }
      setTranslist(resultsArr);
      return resultsArr;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDownload = async () => {
    console.log("download clicked");
    setLoading(true);
    setPageSize(100);
    setTriggerDownload(true);
  };

  const listDownload = async () => {
    console.log("In List download");
    // setPageSize(1000);
    // setPageSize(1000);

    try {
      // Assuming fetchList is asynchronous and returns the data

      const response = await fetchTransList(0);
      //   console.log("thisis resp", response);
      const dataList = response;

      if (!dataList || !dataList.length) {
        console.error("No data available to download.");
        handleCloseDownloadWindow();
        Swal.fire({
          title: "Transactions Report",
          text: "There is no Transactions",
          icon: "info",
          timer: 2000,
        });
        return;
      }

      // Format the data for Excel (select necessary fields)
      const formattedData = dataList.map((transaction) => ({
        TransactionID: transaction.id,
        Status: transaction.status,
        CreatedAt: convertToDate(transaction.created_at),
        PaymentMode: transaction.method,
        RRN: transaction.acquirer_data.rrn,
        Currency: transaction.currency,
        Amount: transaction.amount / 100,
        RecieverID: transaction.customer_id,
        RecieverContact: transaction.contact,
        Payer_UPI: transaction.vpa,
        Payer_Account_Type: transaction.upi.payer_account_type,
      }));

      // Create a worksheet from the data
      const worksheet = writeXLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and add the worksheet
      const workbook = writeXLSX.utils.book_new();
      writeXLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

      // Create and download the Excel file
      const currentDateTime = dayjs().format("YYYY-MM-DD_HH-mm-ss");
      writeXLSX.writeFile(
        workbook,
        `Razor_UPI_Transactions_Report-${currentDateTime}.xlsx`
      );
      setLoading(false);
      handleCloseDownloadWindow();
      window.location.reload();
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  useEffect(() => {
    fetchList(0);
    if (triggerDownload) {
      console.log("in if trigger Download");
      listDownload();
    }
    const uType = sessionStorage.getItem("uType");
    if (uType === "ADMIN" || uType === "SUBADMIN") {
      setAvailableUserTypes([
        "CHANNELPARTNER",
        "SUPERDISTRIBUTOR",
        "MASTERDISTRIBUTOR",
        "AREADISTRIBUTOR",
        "RETAILER",
      ]);
    } else if (uType === "CHANNELPARTNER") {
      setAvailableUserTypes([
        "SUPERDISTRIBUTOR",
        "MASTERDISTRIBUTOR",
        "AREADISTRIBUTOR",
        "RETAILER",
      ]);
    } else if (uType === "SUPERDISTRIBUTOR") {
      setAvailableUserTypes([
        "MASTERDISTRIBUTOR",
        "AREADISTRIBUTOR",
        "RETAILER",
      ]);
    } else if (uType === "MASTERDISTRIBUTOR") {
      setAvailableUserTypes(["AREADISTRIBUTOR", "RETAILER"]);
    } else if (uType === "AREADISTRIBUTOR") {
      setAvailableUserTypes(["RETAILER"]);
    } else {
      setAvailableUserTypes([]);
    }
  }, [triggerDownload]);

  return (
    <>
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
    <Typography sx={{fontSize:'22px', fontWeight:'bold', marginBottom:'1%'}}>This is Hierarchy-wise QR Transactions List </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 size={{ md: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Select User Type</InputLabel>
              <Select
                value={userType}
                sx={{ width: "100%" }}
                label="Select User Type"
                labelId="user-type-label"
                onChange={handleUserTypeChange}
              >
                {availableUserTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 size={{ md: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="user-label">Select User</InputLabel>
              <Select
                //   value={userType}
                sx={{ width: "100%" }}
                label="Select User"
                labelId="user-label"
                onChange={handleUserChange}
              >
                {userType ? (
                  allavailableUsers.filter((user) => user.userRole === userType)
                    .length > 0 ? (
                    allavailableUsers
                      .filter((user) => user.userRole === userType)
                      .map((user) => (
                        <MenuItem key={user} value={user}>
                          {user.userName}
                        </MenuItem>
                      ))
                  ) : (
                    <MenuItem disabled>No users found</MenuItem>
                  )
                ) : (
                  <MenuItem disabled>Please select a user type first</MenuItem>
                )}
                {/* {allavailableUsers.length > 0 ? (
                  allavailableUsers
                    .filter((type) => type.userRole === userType)
                    .map((type) => (
                      <MenuItem key={type} value={type}>
                        {type.userName}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem disabled>No users found</MenuItem>
                )} */}
              </Select>
            </FormControl>
          </Grid2>

          <Grid2 size={{ md: 2 }}>
            <LoadingButton
              variant="contained"
              size="large"
              onClick={onClickGetList}
            >
              Get List
            </LoadingButton>
          </Grid2>
        </Grid2>
        {/* Filter Button with Icon */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          {/* <Select
            labelId="user-select-label"
            id="user-select"
            value=""
            label="Select User"
            onChange={handleChange}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select> */}
          <IconButton onClick={handleFilterMenuClick}>
            <FilterAlt />
          </IconButton>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleClickOpenDownloadWindow}>
              <Typography>Download Report </Typography> <Download />
            </IconButton>
            
            {userType2 !== "ADMIN" &&

              <Typography
                onClick={handleNavigation}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  marginLeft: 2,
                }}
              >
                Back
              </Typography>
            }
          </Box>
          {/* Modal Window */}
          <Dialog
            onClick={handleClickOpenDownloadWindow}
            fullScreen={fullScreen}
            open={openDownloadWindow}
            onClose={handleCloseDownloadWindow}
          >
            <DialogTitle id="responsive-dialog-title">
              Download Report
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You can Download Upto 100 Transactions
              </DialogContentText>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
              {/* <Autocomplete
                sx={{ width: "100%", marginTop: 2 }}
                options={accList}
                loading={!accList.length} // Show a spinner until data is loaded
                getOptionLabel={(option) =>
                  `${option.label} ${option.virtual_upi_handle || ""} `
                }
                inputValue={inputValue}
                onInputChange={(event, value) => {
                  // value will be the entire selected object
                  console.log("Selected value:", value); // Log the selected value
                  setInputValue(value);
                  if (value) {
                  } else {
                    console.log("No value selected");
                  }
                }}
                onChange={(event, value) => {
                  // This will handle the selection of the option (the entire object)
                  console.log("Selected object:", value); // Log the selected object
                  if (value) {
                    handleSearchInputChange(event, value); // Pass the selected object here
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search with label"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!accList.length ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  />
                )}
              /> */}
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleCloseDownloadWindow}>
                Cancel
              </Button> */}

              <LoadingButton
                autoFocus
                loading={loading}
                onClick={handleDownload}
              >
                <Download /> Download
              </LoadingButton>
            </DialogActions>
          </Dialog>
          {/* Filter Menu */}
          <Menu
            anchorEl={filterMenuAnchor}
            open={Boolean(filterMenuAnchor)}
            onClose={handleFilterMenuClose}
            disableAutoFocus
            disableEnforceFocus
          >
            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <Button variant="contained" onClick={applyFilters}>
                Apply Filters
              </Button>
            </MenuItem>
          </Menu>
        </Box>

        {/* Table Container Started...!!! */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{
                backgroundColor: "#027BFE",
                color: "white",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Sr. No.
                </TableCell>
                {/* <TableCell style={{ color: 'white' }} align="center">Type</TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Remitter UPI ID
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Payment Mode
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Amount
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Bank Reference Number
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Transaction Date
                </TableCell>
                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Full Name
                </TableCell> */}
                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Phone No.
                </TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Reciever Mobile Number
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Amount Refunded?
                </TableCell>
                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Remmitter IFSC No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Label
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Virtual UPI Handle
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {trnsList && trnsList.length > 0 ? (
                trnsList.map((txn, i) => {
                  if (txn.status === "failure") {
                  } else {
                    totalAmount = totalAmount + txn.amount;
                  }
                  return (
                    <TableRow
                      key={txn.id} // Use unique key based on the transaction ID
                      className={`${classes.tableRow} ${i % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell>{i + 1}</TableCell> {/* Serial Number */}
                      {/* <TableCell>{txn.id}</TableCell> Transaction ID */}
                      <TableCell>{txn.vpa}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {/* Colored dot */}
                          <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                statusColors[txn.status] || "black", // Default color if status is not found
                              marginRight: 1, // Space between dot and status text
                            }}
                          />
                          {/* <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                txn.status === "received" ? "green" : "red", // Set color based on status
                              marginRight: 1, // Space between dot and status text
                            }}
                          /> */}
                          {/* Status text */}
                          <Typography>{txn.status}</Typography>
                        </Box>
                        {/* Transaction Status */}
                      </TableCell>
                      {/* Payment Mode */}
                      <TableCell>{txn.method}</TableCell>
                      {/* {(totalAmount = totalAmount + txn.amount)} */}
                      {/* Transaction Amount */}
                      <TableCell>{txn.amount / 100}</TableCell>
                      <TableCell align="center">
                        {txn.acquirer_data.rrn}
                      </TableCell>
                      {/* UTR */}
                      {/* Remitter Name */}
                      <TableCell>{convertToDate(txn.created_at)}</TableCell>
                      {/* {"Transaction Date"} */}
                      {/* Remitter Phone */}
                      {/* {"Remitter Name "} */}
                      {/* UPI Handle */}
                      {/* <TableCell>{txn.remitter_phone_number}</TableCell> */}
                      {/* {"Remitter Phone Number "} */}
                      {/* Creation Date */}
                      <TableCell>{txn.contact}</TableCell>
                      {/* {"Remitter UPI "} */}
                      {/* Settlement Date */}
                      <TableCell>{txn.amount_refunded}</TableCell>
                      {/* {"Account Number "} */}
                      {/* Bank Settlement Date */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No transactions available
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={9} align="center" sx={{ width: "100%" }}>
                  <Typography>
                    Total Amount = Rs. {totalAmount / 100}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage - pageSize)}
                    disabled={currentPage == 0}
                    size="small"
                    sx={{ marginRight: 1 }}
                  >
                    Back
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage + pageSize)}
                    disabled={hasNext}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  >
                    Next
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default RazorHierarchyTrans;
