import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  Tabs,
  Tab,
  Divider,
  Link,
} from "@mui/material";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import FlightIcon from "@mui/icons-material/Flight";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import { toast } from "react-toastify";
import axios from "axios";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

const OneWayCard = ({
  fdetails,
  durationCalculator,
  traceid,
  searchDetails,
}) => {
  console.log("searchDetails from OneWayCard", searchDetails);
  const [showDetails, setShowDetails] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // const [fareRule, setFareRule] = useState([]);
  const [fareRuleDetail, setFareRuleDetail] = useState("");
  const navigate = useNavigate();

  const traceId = traceid;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const importAllLogos = (requireContext) => {
    const logos = {};
    requireContext.keys().forEach((key) => {
      const airlineCode = key.replace("./", "").replace(".gif", "");
      logos[airlineCode] = requireContext(key);
    });
    return logos;
  };

  const airlineLogos = importAllLogos(
    require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
  );

  const handleFlightDetails = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    // Toggle the display of flight details
    setShowDetails((prev) => !prev);

    // Check for missing authentication token
    if (!jwtToken) {
      alert("Authentication token is missing!");
      return;
    }

    const apiUrl = "https://api.ipaisa.co.in/myapp/flight/farerule";
    const requestBody = {
      traceId,
      resultIndex: fdetails.ResultIndex,
    };

    try {
      // Make the API request
      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      // Extract the fare rule details
      const { Response } = response.data;

      if (Response?.Error?.ErrorCode !== 0) {
        console.warn("API returned an error:", Response.Error?.ErrorMessage);
        toast.warn(
          Response.Error?.ErrorMessage || "Failed to retrieve flight details."
        );
        return;
      }

      if (Response?.FareRules?.length > 0) {
        const fareRuleDetail = Response.FareRules[0].FareRuleDetail;
        // console.log("FareRuleDetail:", fareRuleDetail);
        setFareRuleDetail(fareRuleDetail);
      } else {
        console.warn("No fare rules found.");
        toast.warn("No fare rules available for this flight.");
      }
    } catch (error) {
      console.error("Error fetching flight details:", error);
      toast.error("Failed to retrieve flight details. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 2,
          borderRadius: "20px 20px 0px 0px",
          background: "#027BFE",
          boxShadow: 3,
        }}
      >
        {fdetails.IsLCC === false ? (
          <DinnerDiningIcon
            sx={{ fontSize: "14px", mt: 0.3, color: "white" }}
          />
        ) : (
          <Box height={"18px"}></Box>
        )}
        {/* <DinnerDiningIcon sx={{ fontSize: "14px", mt: 0.3, color: "white" }} /> */}
        <Typography sx={{ ml: 1, fontSize: "14px", color: "white" }}>
          {fdetails.IsLCC === false ? "Free Meal" : ""}
        </Typography>
      </Box>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0px 0px 20px 20px",
          mt: -1,
        }}
      >
        <Box
          sx={{
            px: 1,
            py: 3,
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            {fdetails.Segments.map(
              (segment, sindex) =>
                segment.length > 0 && (
                  <>
                    <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
                      <img
                        src={
                          airlineLogos[segment[0].Airline.AirlineCode] ||
                          "../Assets/Services/Flight/logos/default.gif"
                        }
                        alt={`${segment[0].Airline.AirlineName} logo`}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                      />
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        // textAlign= "center"
                        alignItems={"center"}
                        // alignContent={"center"}
                      >
                        <Box>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: 600 }}
                          >
                            {segment[0].Airline.AirlineName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 300,
                              width: "100px",
                            }}
                          >
                            {`${segment[0].Airline.AirlineCode} - ${segment[0].Airline.FlightNumber}`}
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                          {new Date(
                            segment[0].Origin.DepTime
                          ).toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </Typography>
                        <Typography>
                          {segment[0].Origin.Airport.CityName}
                        </Typography>
                      </Stack>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 600,
                            fontFamily: "Open Sans",
                            color: "#027BFE",
                          }}
                        >
                          {durationCalculator(
                            segment[0].Origin.DepTime,
                            segment[segment.length - 1].Destination.ArrTime
                          )}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100px",
                            // alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography color="#027BFE">- - - - - </Typography>
                          <FlightIcon
                            sx={{
                              rotate: "90deg",
                              color: "#027BFE",
                              ml: 0.5,
                            }}
                          />
                        </Box>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 600,
                            fontFamily: "Open Sans",
                            color: "#027BFE",
                          }}
                        >
                          {segment.length === 1
                            ? "Direct Flight"
                            : `${segment.length - 1} stop${
                                segment.length - 1 > 1 ? "s" : ""
                              }, via ${segment
                                .slice(0, segment.length - 1)
                                .map(
                                  (stop) => stop.Destination.Airport.CityName
                                )
                                .join(", ")}`}
                        </Typography>
                      </Box>

                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          // alignContent: "center",
                          // justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                          {new Date(
                            segment[segment.length - 1].Destination.ArrTime
                          ).toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </Typography>
                        <Typography>
                          {
                            segment[segment.length - 1].Destination.Airport
                              .CityName
                          }
                        </Typography>
                      </Stack>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        // alignContent={"center"}
                        justifyContent={"center"}
                        // mt={1}
                      >
                        <FlightClassIcon
                          sx={{
                            color: "#027BFE",
                            ml: 0.5,
                            transform: "scaleX(-1)",
                            fontSize: "16px",
                          }}
                        />
                        <Typography
                          fontSize={10}
                          color="#027BFE"
                          fontWeight={700}
                          width={"28px"}
                          // display={'none'}
                        >
                          {segment[0].NoOfSeatAvailable > 0
                            ? segment[0].NoOfSeatAvailable
                            : 0}{" "}
                          Left
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )
            )}
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 600,
                  // ml: 2,
                  // mr: 2,
                  width: "85px",
                }}
              >
                ₹ {(fdetails.Fare.PublishedFare * 1.11).toFixed(0)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                onClick={() => {
                  navigate("/booking-details", {
                    state: { fdetails, traceId, searchDetails },
                  });
                }}
                sx={{
                  background: "#027BFE",
                  borderRadius: "10px",
                  color: "white",
                  textTransform: "none",
                  width: "120px",
                }}
              >
                Book Now
              </Button>

              <Button
                variant="text"
                sx={{
                  color: "#027BFE",
                  textTransform: "none",
                  mt: 0.5,
                  ":hover": {
                    background: "white",
                  },
                }}
                onClick={handleFlightDetails}
              >
                {showDetails ? "- Flight Details" : "+ Flight Details"}
              </Button>
            </Box>
          </Stack>
        </Box>

        {/* Tabs Section */}
        {showDetails && (
          <Box
            sx={
              {
                // mt: 2, borderRadius: 4
              }
            }
          >
            <Tabs
              // sx={{ background: "#F5F7FA", borderRadius: "20px 20px 0px 0px" }}
              value={activeTab}
              onChange={handleTabChange}
              sx={{ transition: "opacity 0.5s", opacity: showDetails ? 1 : 0 }}
            >
              <Tab label="Overview" />
              <Tab label="Fare Rules" />
            </Tabs>
            <Box>
              {activeTab === 0 && (
                <Box display={"flex"} flexDirection={"column"}>
                  {fdetails.Segments.map((segmentGroup, groupIndex) =>
                    segmentGroup.map((segment, index) => (
                      <Box key={`${groupIndex}-${index}`}>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          sx={{ background: "#E3F0FE" }}
                          px={2}
                          // py={1}
                        >
                          <Box display={"flex"} flexDirection={"row"} gap={2}>
                            <Box mt={0.5}>
                              <img
                                src={
                                  airlineLogos[segment.Airline.AirlineCode] ||
                                  "../Assets/Services/Flight/logos/default.gif"
                                }
                                alt={`${segment.Airline.AirlineName} logo`}
                                style={{
                                  width: "37px",
                                  height: "37px",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography fontWeight={600}>
                                {segment.Airline.AirlineName}
                              </Typography>
                              <Typography fontSize={12}>
                                {`${segment.Airline.AirlineCode} - ${segment.Airline.FlightNumber}`}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display={"flex"} flexDirection={"row"} gap={4}>
                            <Box display={"flex"} flexDirection={"column"}>
                              {/* <Typography>Aircraft</Typography> */}
                              {/* <Typography>{flight.aircraft}</Typography> */}
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                              {/* <Typography>Travel Class</Typography> */}
                              {/* <Typography>{flight.travelClass}</Typography> */}
                            </Box>
                          </Box>
                        </Box>
                        <Paper
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            px: 4,
                            py: 1,
                            borderRadius: "0px 0px 0px 0px",
                            // mb: 0.1,
                          }}
                        >
                          <Box textAlign={"right"}>
                            <Typography fontSize={24} fontWeight={700}>
                              {new Date(
                                segment.Origin.DepTime
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                            </Typography>

                            <Typography fontSize={20} fontWeight={500}>
                              {new Date(
                                segment.Origin.DepTime
                              ).toLocaleDateString("en-US", {
                                weekday: "short", // Short weekday name
                                day: "2-digit", // 2-digit day
                                month: "short", // Short month name
                                year: "2-digit", // 2-digit year
                              })}
                            </Typography>

                            <Typography
                              fontSize={16}
                              fontWeight={300}
                            >{`${segment.Origin.Airport.CityName} [ ${segment.Origin.Airport.CityCode} ]`}</Typography>
                            <Typography fontSize={16} fontWeight={300}>
                              {segment.Origin.Airport.AirportName}
                            </Typography>
                            <Typography fontSize={16} fontWeight={300}>
                              Terminal{" "}
                              {segment.Origin.Airport.Terminal || "---"}
                            </Typography>
                          </Box>

                          <Box alignContent={"center"} textAlign={"center"}>
                            <Typography
                              color="#027BFE"
                              fontSize={14}
                              fontWeight={300}
                            >
                              {`${Math.floor(segment.Duration / 60)} Hrs ${
                                segment.Duration % 60
                              } Mins`}
                            </Typography>
                            <Box display={"flex"} flexDirection={"row"}>
                              <Typography color="#027BFE">
                                - - - - -{" "}
                              </Typography>
                              <FlightIcon
                                sx={{
                                  rotate: "90deg",
                                  color: "#027BFE",
                                  ml: 0.5,
                                }}
                              />
                              <Typography color="#027BFE">
                                {" "}
                                - - - - -
                              </Typography>
                            </Box>
                          </Box>

                          <Box textAlign={"left"}>
                            <Box>
                              <Typography fontSize={24} fontWeight={700}>
                                {new Date(
                                  segment.Destination.ArrTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </Typography>

                              <Typography fontSize={20} fontWeight={500}>
                                {new Date(
                                  segment.Destination.ArrTime
                                ).toLocaleDateString("en-US", {
                                  weekday: "short", // Short weekday name
                                  day: "2-digit", // 2-digit day
                                  month: "short", // Short month name
                                  year: "2-digit", // 2-digit year
                                })}
                              </Typography>

                              <Typography
                                fontSize={16}
                                fontWeight={300}
                              >{`${segment.Destination.Airport.CityName} [ ${segment.Destination.Airport.CityCode} ]`}</Typography>
                              <Typography fontSize={16} fontWeight={300}>
                                {segment.Destination.Airport.AirportName}
                              </Typography>
                              <Typography fontSize={16} fontWeight={300}>
                                Terminal{" "}
                                {segment.Destination.Airport.Terminal || "---"}
                              </Typography>
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    ))
                  )}
                </Box>
              )}
              {activeTab === 1 && fareRuleDetail && (
                <>
                  <Box
                    sx={{
                      padding: 3,
                      overflow: "auto",
                      backgroundColor: "#f9f9f9", // Light background for the container
                      borderRadius: "8px", // Rounded corners for the container
                      boxShadow: "0 2px 10px rgba(0,0,0,0.1)", // Subtle shadow effect
                      "& table": {
                        width: "100%",
                        borderCollapse: "collapse",
                        marginBottom: "20px", // Space after the table
                        borderRadius: "20px",
                      },
                      "& th, & td": {
                        border: "2px solid #ddd", // Add border to table cells
                        padding: "12px", // Increased padding for better spacing
                        textAlign: "center",
                        // borderRadius: "20px",
                      },
                      "& th": {
                        // backgroundColor: "gray", // Green background for table headers
                        // backgroundColor: "#4caf50", // Green background for table headers
                        // color: "#fff", // White text for table headers
                        // fontWeight: "bold",
                        backgroundColor: "#ffffff",
                      },
                      "& td": {
                        backgroundColor: "#ffffff", // White background for table cells
                      },
                      "& ul": {
                        marginLeft: "20px", // Indent unordered lists
                        paddingLeft: "20px",
                        listStyle: "disc outside", // Disc style bullets
                      },
                      "& ul ul": {
                        listStyle: "circle", // Circle bullets for nested lists
                      },
                      "& li": {
                        marginBottom: "8px", // Spacing between list items
                      },
                      "& legend": {
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "8px", // Space below the legend
                        color: "#333", // Darker text color for better readability
                      },
                      "& fieldset": {
                        border: "1px solid #ccc", // Light border for fieldsets
                        borderRadius: "5px", // Rounded corners for fieldsets
                        padding: "10px 15px", // Padding inside the fieldset
                        marginBottom: "20px", // Space below fieldsets
                        backgroundColor: "#fdfdfd", // Subtle background color for fieldsets
                      },
                      "& b": {
                        color: "#333", // Darker color for bold text
                        // color: "#027BFE",
                      },
                      "& *": {
                        fontFamily: "'Roboto', sans-serif", // Use a modern font
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(fareRuleDetail),
                    }}
                  ></Box>
                </>
              )}
            </Box>
          </Box>
        )}
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            p: 2,
          }}
        >
          <Typography fontSize={12} color="#027BFE">
            Meal, seat, Rescheduling & Cancellation are chargeable. (T&C Apply){" "}
            <Link
              onClick={handleFlightDetails}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              (More)
            </Link>
          </Typography>
          <Typography
            fontSize={14}
            color={fdetails.IsRefundable ? "green" : "red"}
            fontWeight={800}
            title={fdetails.IsRefundable ? "Refundable" : "Non-Refundable"}
          >
            {fdetails.IsRefundable ? "Refundable" : "Non-Refundable"}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default OneWayCard;
