import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LocationSelector from './LocationSelector';  // Ensure this is the correct path to your LocationSelector component
import axios from "axios";
import ViewUserDialog from "./ViewUserDialog";
import { toast, ToastContainer } from 'react-toastify';
import AddUserDialog from "./AddUserDialog";
import 'react-toastify/dist/ReactToastify.css';
import EditUserDialog from "./EditUserDialog";
import { Container, Add, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Grid, TablePagination, Switch, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CardMedia, Card, InputBase, TextField, Typography, Modal } from '@mui/material';
import { Visibility, Edit, Delete } from '@mui/icons-material';
import SearchNormalIcon from '../Assets/Partner/Search.svg';
import AddUserIcon from '../Assets/Partner/Add.svg';
import { useSelector, useDispatch } from 'react-redux';
import { deletePartner, fetchPartners, togglePartnerStatus, updatePartners } from '../../redux/actions/partnerActions';
import { makeStyles } from '@mui/styles';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));


const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    middleName: Yup.string().required('Middle Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    dob: Yup.date().required('Date of Birth is required'),
    mobileNumber: Yup.string().required('Mobile Number is required'),
    alternateMobileNumber: Yup.string().required('Alternate Mobile Number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const RetailerlistTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [partners, setPartners] = useState([]);
    const retailer = partners || [];

    useEffect(() => {
        // dispatch(fetchPartners());
    }, [dispatch]);

    useEffect(() => {
        console.log('Fetched Partners:', partners);
    }, [partners]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [userType, setUserType] = useState('');
    const [viewRow, setViewRow] = useState(null);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [showSuccessPop, setShowSuccessPop] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [showFailedPop, setShowFailedPop] = useState(false);


    useEffect(() => {
        const storedUserType = sessionStorage.getItem('uType');
        setUserType(storedUserType);
    }, []);

    const [newUser, setNewUser] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        mobileNumber: '',
        alternateMobileNumber: '',
        email: '',
        country: null,
        state: null,
        city: null,
    });
    const [addUserOpen, setAddUserOpen] = useState(false);
    const handleSearchClick = () => {
        setShowSearchInput(!showSearchInput);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
        setViewRow(row);

    };

    const handleEditOpen = (row) => {
        setEditRow(row);
        setEditOpen(true);
    };

    const handleAddUserOpen = () => {
        setAddUserOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setEditRow(null);
    };

    const handleSearchQueryChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        if (value.trim() === '') {
            // If search query is cleared, fetch partners with default params
            fetchPartnersFromApi(0);
        }
    };
    const handleSearch = async () => {
        if (searchQuery.trim() === '') {
            toast.info('Please enter a search term.');
            return;
        }

        try {
            const token = sessionStorage.getItem('jwtToken');
            const response = await axios.get(
                `https://api.ipaisa.co.in/myapp/auth/searchInUserList?roleid=6&searchBy=${encodeURIComponent(searchQuery)}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.statusCodeValue === 200 && response.data.body) {
                console.log(response.data.body);
                setPartners(response.data.body);
                setTotalDataCount(response.data.totalCount || 0); // Update total count
            } else {
                setPartners([]); // Clear table if no results
                toast.info('No results found.');
            }
        } catch (error) {
            console.error('Error searching users:', error);
            toast.error('Error fetching search results. Please try again.');
        }
    };

    const fetchPartnersFromApi = async (pageNum) => {
        try {
            const token = sessionStorage.getItem('jwtToken');
            const response = await axios.get(
                `https://api.ipaisa.co.in/myapp/auth/list?pageNumber=${pageNum}&roleid=6`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.status === 200 && response.data.users) {
                setPartners(response.data.users);
                setIsLastPage(response.data.users.length < rowsPerPage);
                setTotalDataCount(response.data.length); // Assuming the API returns total records count
                console.log('Fetched Partners:', response.data.users);

            } else if (response.data.status === 404) {
                console.log('Response status 404:', response.data.message); // Ensure it reaches here
                toast.info(response.data.message);

                // If no data on the first page, navigate to the second page and check there
                if (pageNum === 0) {
                    setPageNumber(1); // Move to second page if first page returns 404
                    fetchPartnersFromApi(1); // Call the API for the second page
                } else {
                    setPartners([]); // No data, set empty table
                    setIsLastPage(true); // Set last page as true if no more data
                }
            }
        } catch (error) {
            console.error('Error fetching partners:', error);
            toast.error('Error fetching partners. Please try again later.');
        }
    };


    useEffect(() => {
        fetchPartnersFromApi(pageNumber);
    }, [pageNumber, rowsPerPage]); // Fetch data whenever pageNumber or rowsPerPage changes

    const handleSaveEdit = (editedUser) => {
        const editedIndex = partners.findIndex((user) => user.userid === editedUser.userid);
        if (editedIndex !== -1) {
            try {
                // Update the local state
                const newData = [...partners];
                newData[editedIndex] = editedUser;
                setPartners(newData);

                // Dispatch the Redux action
                dispatch(updatePartners(editedUser));

                // Show success toast and pop-up
                toast.success('User details updated successfully');
                setShowSuccessPop(true);
            } catch (error) {
                console.error('Error updating user:', error);
                toast.error('Error updating user details. Please try again later.');
                setShowFailedPop(true);
            }
        } else {
            toast.error('Error updating user details');
            setShowFailedPop(true);
        }
    };


    const pageDataCache = {};

    const handlePageChange = async (newPage) => {
        if (pageDataCache[newPage]) {
            setPartners(pageDataCache[newPage]); // Use cached data if available
        } else {
            await fetchPartnersFromApi(newPage);
            pageDataCache[newPage] = partners; // Cache data for the page
        }
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(0); // Reset to the first page
        fetchPartnersFromApi(0); // Fetch data for the new rowsPerPage
    };

    const handleAddUser = (values) => {
        // Assuming you handle adding the user via API or state
        // After adding, refresh the partners list
        fetchPartnersFromApi(0, 6);
        toast.success('User added successfully');
        setShowSuccessPop(true);
    };

    const handleAddUserClose = () => {
        setAddUserOpen(false);
        fetchPartnersFromApi(0, 6);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (editOpen) {
            setEditRow({ ...editRow, [name]: value });
        } else {
            setNewUser({ ...newUser, [name]: value });
        }
    };

    const [openModal, setOpenModal] = useState(false); // State to control modal visibility
    const [selectedUser, setSelectedUser] = useState(null); // Store selected user's details

    const handleOpenModal = (user) => {
        setSelectedUser(user); // Set the user to be deleted
        setOpenModal(true); // Open the modal
    };

    const handleCloseModal = () => {
        setOpenModal(false); // Close the modal without deleting
    };

    const handleDelete = () => {
        // Call the deletePartner action to handle deletion
        dispatch(deletePartner(selectedUser.userid));
        handleCloseModal(); // Close the modal after deletion
        fetchPartnersFromApi(0, 6);

    };


    // const handleDelete = async (rId) => {
    //     try {
    //         const token = sessionStorage.getItem('jwtToken');
    //         await axios.get(`https://api.ipaisa.co.in/myapp/auth/deletepartner/${rId}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         });

    //         dispatch(deletePartner(rId));
    //         toast.success('User deleted successfully');
    //     } catch (error) {
    //         console.error('Error deleting user:', error);
    //         toast.error('Error deleting user. Please try again later.');
    //     }
    // };

    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    const handleStatusToggle = async (rId, currentStatus) => {
        try {
            const token = sessionStorage.getItem('jwtToken');
            const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';

            const response = await axios.put(
                `https://api.ipaisa.co.in/myapp/admin/changeStatus/${rId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            );

            // Check the response status and show the appropriate toast message
            if (response.data.message === "true") {
                dispatch(togglePartnerStatus(rId));
                toast.success(response.data.status); // Success message from the response
                // dispatch(fetchPartners());
                fetchPartnersFromApi(0, 6);

            } else {
                toast.error(`Error: ${response.data.status}`);
            }
        } catch (error) {
            console.error('Error toggling user status:', error);
            toast.error('Error toggling user status. Please try again later.');
        }
    };

    const filteredData = partners.filter((row) => {
        return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const inputHeight = '56px';

    return (
        <Box sx={{ padding: 3, marginTop:{xs:'20%', md:'5%'},  backgroundColor: '#F4F5FA', height: '100vh' }}>
            <Box mt={4} mb={2}>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                    <Grid>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '22px', marginBottom: { xs: '4%', md: '0%' } }}>
                            Retailers
                        </Typography>
                    </Grid>

                    <Grid style={{ display: 'flex', gap: '2%' }} alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <TextField
                                label="Search"
                                variant="outlined"
                                value={searchQuery}
                                onChange={handleSearchQueryChange} // Handle search query changes
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: "green", color: "white", height: inputHeight,
                                }}
                                onClick={handleAddUserOpen}
                            >
                                <ControlPointIcon style={{ marginRight: 8 }} />
                                Add User
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
                <TableContainer component={Paper} style={{ marginTop: '2%' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE' }}>
                            <TableRow>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }}>Sr.No</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }}>User ID</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }}>Parent ID</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }}>Name</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }}>Business Name</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }}>Mobile Number</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }}>Wallet Balance</TableCell>
                                {userType === 'ADMIN' ? (
                                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }}>Status</TableCell>
                                ) : <></>}
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <TableRow key={row.userid}
                                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                    <TableCell sx={{ textAlign: 'center' }}>{index + 1 + page * rowsPerPage}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{row.userid}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{row.parentId}</TableCell>
                                    <TableCell sx={{ textAlign: 'start' }}>{row.firstName} {row.middleName} {row.lastName}</TableCell>
                                    <TableCell sx={{ textAlign: 'start' }}>{row.bussinessName}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{row.mobileNumber}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{Number(row.walletBalance).toFixed(2)}</TableCell>
                                    {userType === 'ADMIN' ? (
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {userType === 'ADMIN' ? (
                                                <Switch
                                                    onChange={() => handleStatusToggle(row.userid, row.status)}
                                                    checked={row.status === 'ACTIVE'}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                            color: 'green',
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: 'green',
                                                        },
                                                        '& .MuiSwitch-switchBase': {
                                                            color: 'red',
                                                        },
                                                        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                                            backgroundColor: 'red',
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Switch
                                                    checked={row.status === 'ACTIVE'}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                            color: 'green',
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: 'green',
                                                        },
                                                        '& .MuiSwitch-switchBase': {
                                                            color: 'red',
                                                        },
                                                        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                                            backgroundColor: 'red',
                                                        },
                                                    }}
                                                    disabled
                                                />
                                            )}
                                        </TableCell>
                                    ) : <></>}
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <IconButton onClick={() => handleClickOpen(row)}>
                                            <Visibility />
                                        </IconButton>
                                        {userType === 'ADMIN' && (
                                            <>
                                                <IconButton onClick={() => handleEditOpen(row)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton onClick={() => handleOpenModal(row)}>
                                                    <Delete />
                                                </IconButton>

                                                {/* Confirmation Modal */}
                                                <Modal open={openModal} onClose={handleCloseModal}>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: 400,
                                                        bgcolor: 'background.paper',
                                                        boxShadow: 24,
                                                        p: 4,
                                                        borderRadius: 2,
                                                    }}>
                                                        <Typography variant="h6" component="h2">
                                                            Confirm Delete?
                                                        </Typography>
                                                        <Typography sx={{ mt: 2 }}>
                                                            Are you sure you want to delete the User Number <b>{selectedUser?.mobileNumber}</b> with User ID: <b>{selectedUser?.userid}</b>?
                                                        </Typography>

                                                        {/* Buttons for Confirm and Cancel actions */}
                                                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                                            <Button variant="outlined" onClick={handleCloseModal} sx={{ mr: 2 }}>
                                                                Cancel
                                                            </Button>
                                                            <Button variant="contained" color="error" onClick={handleDelete}>
                                                                Delete
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Modal>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalDataCount} // Total number of rows
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    onPageChange={(event, newPage) => {
                        setPageNumber(newPage); // Set the new page number
                        handlePageChange(newPage); // Fetch partners for the new page or use cached data
                    }}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    nextIconButtonProps={{ disabled: isLastPage }} // Disable if it's the last page
                    backIconButtonProps={{ disabled: pageNumber === 0 }} // Disable if it's the first page
                />
            </Box>

            <AddUserDialog
                open={addUserOpen}
                handleClose={handleAddUserClose}
                handleAddUser={handleAddUser}
            />
            {editRow && (
                <EditUserDialog
                    open={editOpen}
                    handleClose={handleEditClose}
                    user={editRow}
                    handleSave={handleSaveEdit}
                    handlePath="RETAILER"
                    onEditComplete={() => fetchPartnersFromApi(0, 6)} // Callback to refetch partners
                />
            )}
            <ViewUserDialog
                open={open}
                handleClose={handleClose}
                selectedRow={selectedRow}
                user={viewRow}

            />

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar closeOnClick pauseOnHover />
        </Box>
    );
};

export default RetailerlistTable;