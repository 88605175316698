import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Switch,
    Typography,
    Box,
    Container,
    FormControl,
    MenuItem,
} from '@mui/material';
import axios from 'axios';
import { PartnerArrowIcon } from '../../../res/NewWebIcons';
import { Grid, Select } from '@material-ui/core';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const PayinServices = () => {
    const [providerpayinData, setPayinProviderData] = useState([]);
    const [providerpayoutData, setPayoutProviderData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState('PayIn'); // Default to PayIn
    const classes = useStyles();

    const fetchPayinProviderData = async () => {
        const jwtToken = sessionStorage.getItem('jwtToken');
        try {
            const response = await axios.get(
                'https://api.ipaisa.co.in/myapp/admin/get-all-gatway-provider',
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );

            if (response.data.status === 200 && response.data.users) {
                setPayinProviderData(response.data.users);
            } else {
                console.error('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching PayIn provider data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchPayoutProviderData = async () => {
        const jwtToken = sessionStorage.getItem('jwtToken');
        try {
            const response = await axios.get(
                'https://api.ipaisa.co.in/myapp/admin/get-all-payout-provider',
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );

            if (response.data.status === 200 && response.data.users) {
                setPayoutProviderData(response.data.users);
            } else {
                console.error('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching PayOut provider data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        setLoading(true); // Show loading indicator while fetching data

        if (value === 'PayIn') {
            fetchPayinProviderData();
        } else if (value === 'PayOut') {
            fetchPayoutProviderData();
        }
    };

    const handleToggle = async (providerName, currentStatus) => {
        try {
            const jwtToken = sessionStorage.getItem('jwtToken');
            const apiUrl =
                selectedValue === 'PayIn'
                    ? `https://api.ipaisa.co.in/myapp/admin/activate-gatway-provider/${providerName}`
                    : `https://api.ipaisa.co.in/myapp/admin/activate-payout-provider/${providerName}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

            if (response.data.status === 'status change successfully') {
                console.log('Provider status toggled successfully');
                // Refresh data based on the selectedValue
                if (selectedValue === 'PayIn') {
                    await fetchPayinProviderData();
                } else {
                    await fetchPayoutProviderData();
                }
            } else {
                console.error('Failed to toggle provider status:', response.data);
            }
        } catch (error) {
            console.error('Error toggling provider status:', error);
        }
    };

    useEffect(() => {
        fetchPayinProviderData(); // Fetch PayIn data by default on component mount
    }, []);

    const currentData =
        selectedValue === 'PayIn' ? providerpayinData : providerpayoutData;

    return (
        <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1%',
                    justifyContent: 'start',
                }}
            >
                <Typography
                    color="textSecondary"
                    style={{ fontFamily: 'sans-serif', fontSize: '14px' }}
                >
                    Balance
                </Typography>
                <PartnerArrowIcon />
                <Typography
                    color="textSecondary"
                    style={{ fontFamily: 'sans-serif', fontSize: '14px' }}
                >
                        {selectedValue === 'PayIn' ? 'PayIn Services' : 'PayOut Services'}
                </Typography>
            </Grid>
            <Grid
                container
                style={{
                    marginTop: '1%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Grid item>
                    <Typography
                        style={{
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            fontSize: '19px',
                            color: '#343C6A',
                        }}
                    >
                        {selectedValue === 'PayIn' ? 'PayIn Services' : 'PayOut Services'}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl
                        variant="outlined"
                        size="small"
                        style={{ minWidth: 150 }}
                    >
                        <Select
                            labelId="payin-payout-label"
                            value={selectedValue}
                            onChange={handleChange}
                            label="Select"
                        >
                            <MenuItem value="PayIn">PayIn</MenuItem>
                            <MenuItem value="PayOut">PayOut</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '1%' }}>
                <Grid item xs={12}>
                    <TableContainer
                        component={Paper}
                        sx={{ borderRadius: '12px 12px 0px 0px' }}
                    >
                        <Table>
                            <TableHead
                                style={{
                                    backgroundColor: '#027BFE',
                                    color: 'white',
                                }}
                            >
                                <TableRow>
                                    <TableCell style={{ color: 'white' }}>
                                        Provider Name
                                    </TableCell>
                                    <TableCell style={{ color: 'white' }}>
                                        Status
                                    </TableCell>
                                    <TableCell style={{ color: 'white' }} align='center'>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                ) : currentData.length > 0 ? (
                                    currentData.map((provider, index) => (
                                        <TableRow
                                            key={provider.providerName}
                                            className={`${classes.tableRow
                                                } ${index % 2 === 0
                                                    ? classes.oddRow
                                                    : classes.evenRow
                                                }`}
                                        >
                                            <TableCell>
                                                {provider.providerName}
                                            </TableCell>
                                            <TableCell>
                                                {provider.status}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Switch
                                                    checked={
                                                        provider.status ===
                                                        'ACTIVE'
                                                    }
                                                    onChange={() =>
                                                        handleToggle(
                                                            provider.providerName,
                                                            provider.status
                                                        )
                                                    }
                                                    inputProps={{
                                                        'aria-label': `Toggle ${provider.providerName} Status`,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            No provider data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PayinServices;
