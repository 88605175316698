import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  CardMedia,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import Footer from "./Footer";
import aboutuss1 from "../Assets/website/aboutus/aboutuss1.png";
import WhatDoWeDoImg from '../Assets/website/aboutus/WhatDoWeDoGrpImg.png'
import BlogGrpImg from '../Assets/website/services/BlogsGrpImg.png'   
import { useNavigate } from "react-router-dom";


import aboutuscard22 from "../Assets/website/aboutus/aboutuscard22.png";
import { WalletAboutIcon } from "../../res/icons";

const cardVariants = {
  hover: {
    boxShadow: "0 10px 20px rgba(0, 0, 255, 0.5)",
    transition: {
      duration: 0.3,
    },
  },
};



const Aboutus = () => {
  const navigate = useNavigate();
const handleOpenContactUs = () => {
navigate('/ContactUs')
};
const handleOpenServices = () => {
  navigate("/Services");
};

useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

  return (
    <div>
      <Header />
      <Container
        sx={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop:'3%'
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "700",
            fontSize: { xs: "25px", sm: "30px", md: "45px" },
            textAlign: "center",
            color: "#0B1131",
            marginBottom: "3rem",
          }}
        >
          About Us
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "600",
            fontSize: { xs: "17px", sm: "12px", md: "28px" },
            textAlign: "center",
            color: "#0B1131",
          }}
        >
         <span style={{color:'#3029D9', fontSize:'35px'}}> iPaisa </span>The Edsom Fintech Private Limited Product!
        </Typography>
        <Grid container justifyContent="center">
          <Typography
            variant="h5"
            sx={{
              color: "#4D5156",
              fontSize: { xs: "14px", sm: "16px" },
              fontWeight: "500",
              lineHeight: "28px",
              maxWidth: "700px",
              textAlign: "start",
            }}
          >
            <br />
            iPaisa is your all-in-one solution for managing bill payments, making transactions, and booking buses, flights, and hotels from a single, convenient application. Developed by
            <b> Edsom Fintech Private Limited.</b>
            <br />
            iPaisa is designed with your needs in mind, offering the most secure
            and top-notch technologies to ensure your experience is seamless
            and worry-free.
            <br /> <b>Experience the Future of Finance and Travel Management.</b>
            {" "} Discover the convenience and security of managing all your financial
            and bill payment needs with iPaisa.
            <br />
            Download the app today and take control of your transactions with
            confidence.
          </Typography>
        </Grid>
         <Grid style={{ display:'flex' , justifyContent:'center', marginTop:'3rem'}}>
        <img src={BlogGrpImg} style={{width:'70%'}} alt=''/>
        </Grid>
      </Container>
      
      <Grid container style={{display:'flex', justifyContent: 'space-around', marginTop:'4%', alignItems:'center', padding:'2rem'}}>
            <Grid>
              <Typography style={{color:'#3029D9', fontWeight:'bold', fontSize:'25px'}}>Our Mission</Typography>
              <Typography style={{fontWeight:'bold', fontSize:'40px'}}>Empowering Your Financial </Typography>
              <Typography style={{fontWeight:'bold', fontSize:'40px'}}>Future with Trust & Innovation</Typography>
              <Typography color='textSecondary' style={{fontSize:'15px', paddingTop:'1.2rem', lineHeight:'28px'}}>
              Our Mission is to create a fast and secure payment system<br/> that enables merchants and the user to make or receive<br/> payments, with convenience and trust through continues<br/> innovation leveraging technology.
              </Typography>
            </Grid>

           <img src={aboutuss1} alt="AboutUsImg1" style={{width:'35%', display:'flex'}} />
           </Grid>
           
      <Grid container style={{display:'flex', justifyContent: 'space-around', marginTop:'2%', alignItems:'center', padding:'2rem'}}>
      <img src={aboutuscard22} alt="AboutUsImg1" style={{width:'35%', display:'flex'}} />

            <Grid>
              <Typography style={{color:'#3029D9', fontWeight:'bold', fontSize:'25px'}}>Our Vision</Typography>
              <Typography style={{fontWeight:'bold', fontSize:'40px'}}>Leading the Future of Finance</Typography>
              <Typography style={{fontWeight:'bold', fontSize:'40px'}}>with Excellence and Trust</Typography>
              <Typography color='textSecondary' style={{fontSize:'15px', paddingTop:'1.2rem', lineHeight:'28px'}}>
              To revolutionise the way individuals and businesses <br/> manage their Bharat Connect Payments, providing a seamless,<br/> secure, and user-friendly platform that empowers our <br/> customers with convenience, efficiency, and financial<br/> peace of mind.
              </Typography>
            </Grid>
           </Grid>


      <Box
        sx={{
          padding: "2rem",
          backgroundColor: "#3029D9",
          display: "flex",
          justifyContent:'space-between',
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: "2rem",
            marginLeft: "4%",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            sx={{
               
              fontWeight: "600",
              fontSize: "24px",
              color: "#5975FF",
            }}
          >
            What do we do?
          </Typography>
          <Typography
            sx={{
               
              fontWeight: "700",
              fontSize: { xs: "32px", sm: "40px", md: "48px" },
              color: "white",
              marginBottom: "1rem",
              lineHeight: "68px",
            }}
          >
            Manage Your
            <br />
            Finances From <br />
            One Place
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "28px",
            }}
          >
            We have the aim of simplifying the payment process for <br/>
            businesses and consumers, by providing a seamless<br/>
            payment solution that is fast and secure.
          </Typography>
          <Typography
            sx={{
              color: "white",
               
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "28px",
              marginTop: "4%",
            }}
          >
            Download “ iPaisa”  today and experience the simple,<br/> fast,
            and safe way to pay. Say goodbye to cash and <br/> cards and
            embrace the future of digital payments.{" "}
          </Typography>

          <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#111849",
                marginTop: "4%",
                textTransform: 'none',
               '&:hover': {
                  backgroundColor: "white",
                  color: '#3029D9',
                  fontWeight: "bold",
                },
              }}
              onClick={handleOpenServices}
            >
              Check All Features
            </Button>
        </Grid>

        <Grid>
            <img src={WhatDoWeDoImg} alt="Group Image" style={{width:'70%', paddingLeft:'15%'}} />
        </Grid>
      </Box>

      <Grid container justifyContent="space-evenly" style={{ padding: '2.5rem' }}>
      <Grid
        item
        xs={12}
        md={8}
        lg={6}
        sx={{
          width: '80%',
          maxWidth: '550px',
          borderRadius: '12px',
          backgroundColor: '#D6D4F7',
          boxSizing: 'border-box',
          paddingBottom: '2rem'
        }}
      >
        <Grid display="flex" justifyContent="center">
          <WalletAboutIcon />
        </Grid>
        <Typography
          sx={{
            fontSize: { xs: '28px', sm: '34px', md: '40px' },
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#3029D9',
            paddingTop: '2rem',
          }}
        >
          Who Are We?
        </Typography>
        <Box sx={{ textAlign: 'start', padding: '0 15%' }}>
          <Typography
            sx={{
              color: '#3029D9',
              fontSize: { xs: '12px', sm: '14px', md: '15px' },
              paddingTop: '2rem',
              lineHeight: '25px',
            }}
          >
            At iPaisa, we are more than just a financial services provider. We
            are your trusted partner in achieving your financial goals. With a
            commitment to excellence and a deep understanding of the
            complexities of finance, we offer tailored solutions that empower
            individuals and businesses alike.
          </Typography>
          <Typography
            sx={{
              color: '#3029D9',
              fontSize: { xs: '12px', sm: '14px', md: '15px' },
              paddingTop: '2rem',
              lineHeight: '28px',
            }}
          >
            We are a payment aggregator, making payments easy and convenient,
            especially for small merchants and the underprivileged sector of
            society.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          {/* <Button
            sx={{
              color: 'white',
              backgroundColor: '#3029D9',
              marginTop: '2rem',
              textTransform: 'none',
              width: { xs: '60%', sm: 'auto' },
              '&:hover': {
                  backgroundColor: "white",
                  color: '#3029D9',
                  fontWeight: "bold",
                },
            }}
          >
            Read More
          </Button> */}
        </Box>
      </Grid>

      <Grid style={{paddingTop:'1rem'}}>
        <Typography style={{color:'#3029D9', fontWeight:'bold', fontSize:'25px'}}>Skills</Typography>
        <Typography style={{fontWeight:'bold', fontSize:'40px'}}>Our Professional Skills</Typography>
        <Typography color='textSecondary' lineHeight= '30px' style={{paddingTop:'3rem'}}>Are you tired of managing multiple bills and visiting different websites <br/> or offices to pay them? We understand the hassle, and that's why we <br/> offer a comprehensive bill payment service that covers a wide range <br/> of utilities and services.</Typography>

        <Typography color='textSecondary' lineHeight= '30px' style={{paddingTop:'2rem'}}>
      At iPaisa, we understand that making payments for buying daily things,<br/> can be a routine task, but it doesn't have to be boring or unrewarding.<br/> That's why we're thrilled to introduce our exclusive cashback offers and <br/> discounts for our valued users.
      </Typography>
      </Grid>
      
     
    </Grid>

    <div style={{ backgroundColor: "#3029D9" }}>
        <Container
          sx={{
            padding: { xs: "1rem", sm: "2rem" }, // Responsive padding
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "auto", md: "400px" }, // Responsive height
            textAlign: "center", // Center text alignment
          }}
        >
          <Typography
            sx={{
               
              fontWeight: "600",
              fontSize: { xs: "20px", sm: "24px" }, // Responsive font size
              color: "#FFFFFF",
              lineHeight: { xs: "1.5", sm: "68px" },
              marginBottom: "1rem", // Margin bottom for spacing
            }}
          >
            Contact with us
          </Typography>
          <Typography
            sx={{
              color: "white",
               
              fontWeight: "700",
              fontSize: { xs: "36px", sm: "48px", md: "64px" }, // Responsive font size
              lineHeight: { xs: "1.2", sm: "68px" },
              marginBottom: "1rem", // Margin bottom for spacing
            }}
          >
            Subscribe
            <br />
            to Newsletter
          </Typography>
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#111849",
              borderRadius: "4px",
              height: "39px",
              marginTop: "4%",
              padding: { xs: "0.5rem 1rem", sm: "1rem 2rem" }, // Responsive padding
              fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
              textTransform: 'none',
              '&:hover': {
                  backgroundColor: "white",
                  color: '#3029D9',
                  fontWeight: "bold",
                },
            }}
            onClick={handleOpenContactUs}
          >
            Subscribe
          </Button>
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default Aboutus;