import React, { useState, useEffect } from "react";
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Grid, TablePagination, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
    MenuItem,
    Switch,
    Typography
} from '@mui/material';
import { Visibility, Edit, Delete, Add } from '@mui/icons-material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));


const initialData = [
    { srNo: 1, techId: 'TS001', name: 'John Doe', expertise: 'Networking', location: 'New York', status: 'Active' },
    { srNo: 2, techId: 'TS002', name: 'Jane Smith', expertise: 'Hardware', location: 'San Francisco', status: 'Inactive' },
];

const TechSupport = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(initialData);
    const [userType, setUserType] = useState('');
    const [newTechSupport, setNewTechSupport] = useState({
        techId: '',
        name: '',
        expertise: '',
        location: '',
        status: 'Active',
    });

    useEffect(() => {
        const storedUserType = sessionStorage.getItem('uType');
        setUserType(storedUserType);
    }, []);

    const [addTechSupportOpen, setAddTechSupportOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };

    const handleEditOpen = (row) => {
        setEditRow({ ...row });
        setEditOpen(true);
    };

    const handleAddTechSupportOpen = () => {
        setAddTechSupportOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditOpen(false);
        setAddTechSupportOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (editOpen) {
            setEditRow({ ...editRow, [name]: value });
        } else if (addTechSupportOpen) {
            setNewTechSupport({ ...newTechSupport, [name]: value });
        } else {
            setSearchQuery(event.target.value);
        }
    };

    const handleSave = () => {
        const updatedData = data.map((item) => (item.techId === editRow.techId ? editRow : item));
        setData(updatedData);
        handleClose();
    };

    const handleDelete = (row) => {
        const newData = data.filter((item) => item.techId !== row.techId);
        setData(newData);
    };

    const handleAddTechSupport = () => {
        const newTech = { ...newTechSupport, srNo: data.length + 1 };
        setData([...data, newTech]);
        handleClose();
    };


    const filteredData = data.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const inputHeight = '56px'; 

    return (
        <Box sx={{ padding: 3, marginTop:{xs:'20%', md:'5%'},  backgroundColor: '#F4F5FA', height: '100vh' }}>
            <Box mt={4} mb={2}>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                    <Grid>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '22px', marginBottom:{xs:'4%', md:'0%' } }}>
                            Tech Supports
                        </Typography>
                    </Grid>
                    <Grid style={{ display: 'flex', gap: '2%' }} alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <TextField
                                label="Search"
                                variant="outlined"
                                // onChange={(e) => setSearchQuery(e.target.value)}
                                // onClick={() => setShowSearchInput(!showSearchInput)}
                                onChange={handleInputChange}

                            />
                        </Grid>
                        {userType !== 'RETAILER' && (
                            <Grid item>
                                <Button
                                    style={{
                                        backgroundColor: "green", color: "white", height: inputHeight,
                                    }}
                                    onClick={handleAddTechSupportOpen}>
                                    <ControlPointIcon style={{ marginRight: 8 }} />
                                    Add</Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ marginTop: '2%' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE' }}>
                            <TableRow>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Sr.No</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Tech ID</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Name</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Expertise</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Location</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Status</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const shouldDisplayRow = Object.values(row).some((value) =>
                                        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                                    );

                                    if (!shouldDisplayRow) {
                                        return null;
                                    }

                                    return (
                                        <TableRow key={index}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.srNo}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.techId}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.name}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.expertise}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.location}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                     {userType === 'ADMIN' ? (
                        <Switch
                        //    onChange={() => handleStatusToggle(row.userid, row.status)}
                           checked={row.status === 'ACTIVE'}
                            sx={{
                             '& .MuiSwitch-switchBase.Mui-checked': {
                                 color: 'green',},
                             '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                 backgroundColor: 'green',},
                             '& .MuiSwitch-switchBase': {
                                 color: 'red',},
                             '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                 backgroundColor: 'red',},
                                }}
                                            />
                                        ) : (
                                            <Switch
                                                checked={row.status === 'ACTIVE'}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                      color: 'green',
                                                    },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                      backgroundColor: 'green',
                                                    },
                                                    '& .MuiSwitch-switchBase': {
                                                      color: 'red',
                                                    },
                                                    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                                      backgroundColor: 'red',
                                                    },
                                                  }}
                                                disabled
                                            />
                                        )}
                                    </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton color="primary" aria-label="view" onClick={() => handleClickOpen(row)}>
                                                    <Visibility />
                                                </IconButton>
                                                <IconButton color="primary" aria-label="edit" onClick={() => handleEditOpen(row)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton color="secondary" aria-label="delete" onClick={() => handleDelete(row)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>

                    </Table>
                </TableContainer>
                <Grid container justifyContent="flex-end">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle bgcolor={'#027BFE'} color={'white'}>Tech Support Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} marginTop={5} borderRadius={10} border={1} boxShadow={3}>
                            {selectedRow && (
                                <div>

                                    <p><strong>Name:</strong> {selectedRow.name}</p>
                                    <p><strong>Expertise:</strong> {selectedRow.expertise}</p>
                                    <p><strong>Location:</strong> {selectedRow.location}</p>
                                    <p><strong>Status:</strong> {selectedRow.status}</p>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" 
                    style={{ backgroundColor: '#FF0000',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#FF0000', color: '#ffffff'
                        },}} >Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editOpen} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle bgcolor={'#027BFE'} color={'white'}>Edit Tech Support Details</DialogTitle>
                <DialogContent style={{ marginTop: '3%', boxShadow: '5' }}>
                    {editRow && (
                        <form>
                            <TextField
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                value={editRow.name}
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="dense"
                                name="expertise"
                                label="Expertise"
                                type="text"
                                fullWidth
                                value={editRow.expertise}
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="dense"
                                name="location"
                                label="Location"
                                type="text"
                                fullWidth
                                value={editRow.location}
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="dense"
                                name="status"
                                label="Status"
                                select
                                fullWidth
                                value={editRow.status}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </TextField>
                        </form>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{
                         backgroundColor: '#FF0000',
                         color: '#ffffff',
                         '&:hover': {
                             backgroundColor: '#FF0000', color: '#ffffff',
                         },
                         textTransform:'none'
                    }} >Cancel</Button>
                    <Button onClick={handleSave}
                    style={{ backgroundColor: '#027BFE',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#027BFE',
                            color: 'white'
                        },}}>Save</Button>

                </DialogActions>
            </Dialog>

            <Dialog open={addTechSupportOpen} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle bgcolor={'#027BFE'} color={'white'}>Add New Tech Support</DialogTitle>
                <DialogContent>
                    <form>
                        <TextField
                            margin="dense"
                            name="name"
                            label="Name"
                            type="text"
                            fullWidth
                            value={newTechSupport.name}
                            onChange={(e) => setNewTechSupport({ ...newTechSupport, name: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            name="expertise"
                            label="Expertise"
                            type="text"
                            fullWidth
                            value={newTechSupport.expertise}
                            onChange={(e) => setNewTechSupport({ ...newTechSupport, expertise: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            name="location"
                            label="Location"
                            type="text"
                            fullWidth
                            value={newTechSupport.location}
                            onChange={(e) => setNewTechSupport({ ...newTechSupport, location: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            name="status"
                            label="Status"
                            select
                            fullWidth
                            value={newTechSupport.status}
                            onChange={(e) => setNewTechSupport({ ...newTechSupport, status: e.target.value })}
                        >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                        </TextField>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                     style={{
                        backgroundColor: '#FF0000',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#FF0000', color: '#ffffff',
                        },
                        textTransform:'none'
                   }} >Cancel</Button>
                    <Button onClick={handleAddTechSupport}
                    style={{ backgroundColor: '#027BFE',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#027BFE',
                            color: 'white',
                            textTransform:'none',
                        },}}>Add</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TechSupport;
