import React from 'react';
import { Grid, Container, Paper } from '@material-ui/core';
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import logo from "../Assets/login/iPaisaLogo2.png";
import { Colors } from './Colors';


const DebitTransactionInvoice = ({data}) => {
    const userID = sessionStorage.getItem('userId');
    const userName = sessionStorage.getItem('username');


    if (!data) return null;

    const formatAddress = (address) => {
        return `${address?.address || ''}, ${address?.city || ''}, ${address?.state || ''} - ${address?.pincode || ''}, ${address?.country || ''}`;
    };

    return (
        <Container 
        style={{ 
            padding: '1rem', 
            width: '100%', 
            maxWidth: '1000px', 
            boxSizing: 'border-box',
            backgroundColor: '#f0f0f0', // Set the background color here
            minHeight: '90vh', // Ensure the background color covers the full height
            borderRadius:'14px'
        }}
    >
        <Paper 
            elevation={3}
            style={{
                paddingTop:'5%',
                padding: '2rem',
                backgroundColor: Colors.bgColor,
                boxSizing: 'border-box',
                color: Colors.textColor ,               
            }}
        >
            <Grid style={{display:'flex', justifyContent:'center'}}>
                    <Typography style={{fontSize:'18px', fontWeight:"bold", marginTop:'-2%'}}>Fund Transfer Receipt - (DEBIT)</Typography>
            </Grid>
            <Grid style={{marginTop:'1%'}}>
                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid>
                    <Grid style={{display:'flex', alignItems:'center', gap:'6%'}}>
                        <img src={logo} alt="iPaisa Logo" style={{ height: 65, width: 65, backgroundColor: 'transparent', borderRadius: '50%' }} />
                        <Typography style={{ fontWeight: 'bold', fontSize: '28px' }}>iPaisa</Typography>
                    </Grid> 
                    <Grid style={{marginTop:'2%'}}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                            {new Date(data.date).toLocaleDateString() || 'N/A'}
                        </Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                        {new Date(data.date).toLocaleTimeString() || 'N/A'}
                        </Typography>
                    </Grid>
                    </Grid>
                <Grid style={{display:'flex', alignItems:'center', marginTop:"3%"}}>
                    <Grid style={{textAlign:'end'}}>
                        <Typography style={{fontSize:"16px", fontWeight:'bold' }}>From :</Typography>
                        <Typography style={{ fontSize: '20px', fontWeight:'bold'}}>
                            {userName}
                        </Typography>
                        <Typography style={{ fontSize: '16px'}}>
                            {userID}
                        </Typography>
                        <Typography style={{ fontSize: '14px' }}>{formatAddress(data.sender_address)}</Typography>
                    </Grid>
                </Grid>   
                </Grid>
                <Grid style={{display:'flex', justifyContent:'start', alignItems:'center', marginTop:"3%"}}>
                    <Grid style={{textAlign:'start'}}>
                        <Typography style={{fontSize:"16px", fontWeight:'bold' }}>To :</Typography>
                        <Typography style={{ fontSize: '20px', fontWeight:'bold'}}>
                            {data.senderName}
                        </Typography>
                        <Typography style={{ fontSize: '16px'}}>
                            {data.senderId}
                        </Typography>
                        <Typography style={{ fontSize: '14px' }}>{formatAddress(data.receiver_address)}</Typography>
                    </Grid>
                </Grid>
                <TableContainer style={{ marginTop: '5%' }}>
                    <Table aria-label="transaction table" style={{ tableLayout: 'fixed', width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor}}>Receiver Name</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor}}>Receiver ID</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor, paddingLeft:'-7%'}}>Txn. ID</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', paddingLeft:'10%', color: Colors.textColor}}>Receiver Number</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', paddingLeft:'8%', color: Colors.textColor}}>Txn. Type</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', paddingLeft:'7%', color: Colors.textColor}}>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{fontSize:'12px', color: Colors.textColor}}>{data.senderName || 'N/A'}</TableCell>
                                <TableCell style={{fontSize:'12px', color: Colors.textColor}}>{data.senderId || 'N/A'}</TableCell>
                                <TableCell style={{fontSize:'12px', color: Colors.textColor, paddingLeft:'-7%'}}>{data.tranxid || 'N/A'}</TableCell>
                                <TableCell style={{fontSize:'12px', paddingLeft:'10%', color: Colors.textColor}}>{data.senderNumber || 'N/A'}</TableCell>                                                          
                                <TableCell style={{fontSize:'12px', paddingLeft:'8%', color: Colors.textColor}}>{data.transactionType || 'N/A'}</TableCell>                                                          
                                <TableCell style={{fontSize:'12px', paddingLeft:'7%', color: Colors.textColor}}>{data.amount || 'N/A'}</TableCell>                                   
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid style={{ marginTop:'4%', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                    <Grid>
                        <Typography style={{ fontWeight:"bold", fontSize:"22px" }}>EDSOM FINTECH PRIVATE LIMITED</Typography>
                        <Typography style={{ fontWeight:'bold', fontSize:'14px' }}>
                            Thank you for transacting at iPaisa!
                        </Typography>
                        <Typography color='textSecondary' style={{fontSize:'12px', color: Colors.textColor}}>
                            This is a system generated receipt hence does not require any signature.
                        </Typography>
                        <Typography color='textSecondary' style={{fontSize:'12px', color: Colors.textColor}}>
                            Queries? Write to care@ipaisa.co.in
                        </Typography>
                    </Grid>
                    <Grid>
                        <Divider/>
                        <Grid>
                            <Typography style={{ fontWeight:'bold' }}>Total Amount : {data.amount}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
        </Container>
    );
}

export default DebitTransactionInvoice
