import React, { useState, useEffect } from 'react';
import {
    Button,
    Container,
    Grid,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TextField,
    Box,
    Typography,
    InputBase,
    IconButton,
    Pagination,
    TablePagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from "@mui/icons-material/Search";
import axios from 'axios';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.jpg';
import { DownloadIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import DownloadIcon1 from "@mui/icons-material/Download";
import PayinReportInvoice from './PayinReportInvoice';
import { CSVLink } from 'react-csv';


const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const NewPayIn = () => {

    const [payInData, setPayInData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isNextDisabled, setIsNextDisabled] = useState(false); // State to manage next button state
    const [statusCodeValue, setStatusCodeValue] = useState(null); // State to manage status code
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const classes = useStyles();


    const handleDownloadInvoice = async (data) => {
        const receiptElement = document.getElementById(`receipt-${data.txnid}`);

        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${data.txnid}`);
            return;
        }

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
            filename: `Payin Receipt-${data.txnid}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
        };

        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };


    const handleDownloadCSV = () => {
        const csvData = filteredData.map((row, index) => ({
            SrNo: index + 1,
            TxnID: row.txnid,
            UTRNo: row.easepayid,
            Date: formatDate(row.addedon),
            PayInAmount: row.netAmountDebit || 'N/A',
            Amount: row.amount,
            Status: row.status === 'success' ? 'Successful' : 'Failed',
        }));
        return csvData;
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Pay In Report", 14, 10);
        doc.autoTable({
            head: [['Sr. No.', 'Txn ID', 'UTR No', 'Date', 'PayIn Amount','Amount', 'Status']],
            body: filteredData.map((row, index) => [
                index + 1,
                row.txnid,
                row.easepayid,
                formatDate(row.addedon),
                row.netAmountDebit || 'N/A',
                row.amount,
                row.status === 'success' ? 'Successful' : 'Failed'
            ])
        });
        doc.save('pay_in_report.pdf');
    };

    const handleSearch = () => {
        setCurrentPage(0); // Reset to the first page on new search

        // If searchQuery is empty or only whitespace, call the API with page=0 and rowsPerPage=10
        if (!searchQuery.trim()) {
            fetchCompleteData(0, fromDate, toDate, '', 10); // Call API with no search query
        } else {
            fetchCompleteData(0, fromDate, toDate, searchQuery, rowsPerPage); // Call API with search query
        }
    };

    // Handle search input change (no API call here)
    const handleSearchInput = (event) => {
        const updatedSearchQuery = event.target.value; // Get the updated search query value
        setSearchQuery(updatedSearchQuery); // Update search query state

        // Check if the search query length becomes 0 (i.e., the input is cleared)
        if (updatedSearchQuery.length === 0) {
            // Fetch data with default parameters when search query is cleared
            fetchCompleteData(0, '', '', '', rowsPerPage);
        }
    };


    // Function to fetch completed data from the API
    const fetchCompleteData = async (page, fromDate, toDate, searchQuery, rowsPerPage) => {
        setLoading(true); // Set loading to true before making the API call

        const token = sessionStorage.getItem('jwtToken');

        // Build the URL parameters conditionally
        let url = 'https://api.ipaisa.co.in/myapp/auth/easTxnDetails?';
        // Add search query if available
        if (searchQuery.trim()) {
            url += `searchBy=${searchQuery}&`;
        }

        // Add fromDate if available
        if (fromDate) {
            url += `fromDate=${fromDate}&`;
        }

        // Add toDate if available
        if (toDate) {
            url += `toDate=${toDate}&`;
        }

        // Add pagination parameters (page and rowsPerPage)
        url += `pageNumber=${page}&rowsPerPage=${rowsPerPage}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.statusCodeValue === 204 || response.data.body.message === "NO_CONTENT") {
                // Handle the case when no content is returned
                console.log('No content available');
                setFilteredData([]); // Clear the data state if no content
                setStatusCodeValue(response.data.statusCodeValue);
            } else {
                setFilteredData(response.data.body.data); // Set the data to the state if content is available
                setTotalRecords(response.data.body.totalRecords || 0); // Assuming totalRecords is provided in the response
            }
        } catch (error) {
            console.error('Error fetching completed transactions:', error);
            setFilteredData([]); // Optional: Clear data in case of error
        } finally {
            setLoading(false); // Set loading to false after API call
        }
    };

    useEffect(() => {
        fetchCompleteData(currentPage, fromDate, toDate, searchQuery, rowsPerPage); // Fetch data on component mount or when page/filters change
    }, [currentPage]); // Run when these dependencies change

    const handleClear = () => {
        setFromDate('');
        setSearchQuery('');
        setToDate('');
        setCurrentPage(0); // Reset to first page
        fetchCompleteData(0, '', '', '', rowsPerPage); // Fetch data with default params when cleared
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage); // Update the page state
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setCurrentPage(0); // Reset to first page when rows per page changes
    };

    const formatDate = (dateTimeString) => {
        if (!dateTimeString) return '';
        const [datePart] = dateTimeString.split('T');
        const [year, month, day] = datePart.split('-');
        return `${day}-${month}-${year}`;
    };



    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress />
            </Grid>
        );
    }



    return (
        <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Reports</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Pay In Report</Typography>
            </Grid>

            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    marginTop: '1%',
                    flexWrap: 'wrap', // Allow wrapping for smaller screens
                }}
            >
                <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: '1rem', sm: '0' } }}>
                    <Typography
                        style={{
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            fontSize: '19px',
                            color: '#343C6A',
                        }}
                    >
                        Pay In Report
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', sm: 'flex-end' },
                        gap: '1rem', // Adjust gap between buttons
                        flexWrap: 'wrap', // Ensure buttons wrap if needed
                    }}
                >
                    <Button
                        style={{
                            textTransform: 'none',
                            backgroundColor: '#027BFE',
                            color: '#FFFFFF',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px 16px',
                            fontFamily: 'sans-serif',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            gap: '5%',
                            height: '40px',
                        }}
                        onClick={handleDownloadPDF}
                    >
                        <DownloadIcon />
                        Download PDF File
                    </Button>

                    <CSVLink data={handleDownloadCSV()} filename={"pay_in_report.csv"} target="_blank">
                        <Button
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#027BFE',
                                color: '#FFFFFF',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px 16px',
                                fontFamily: 'sans-serif',
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                                gap: '5%',
                                height: '40px',
                            }}
                        >
                            <DownloadIcon />
                            Download CSV File
                        </Button>
                    </CSVLink>
                </Grid>
            </Grid>

            <Paper style={{ boxShadow: 'none', padding: '2rem', borderRadius: '8px', marginTop: '2%', boxShadow: 'none' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    {/* Search and Buttons */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginTop: '1%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            gap: '1rem',
                        }}
                    >
                        {/* Search Field */}
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                maxWidth: { xs: '100%', sm: '50%' }, // Dynamic width for responsiveness
                                borderRadius: '10px',
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                padding: '0.5rem',
                            }}
                        >
                            <InputBase
                                fullWidth
                                placeholder="Search"
                                value={searchQuery} // Bind the value of search input to the state
                                onChange={handleSearchInput} // Only update searchQuery, no API call here
                            />
                            <IconButton color="primary" onClick={handleSearch}>  {/* Trigger search when icon is clicked */}
                                <SearchIcon />
                            </IconButton>
                        </Box>

                        {/* Action Buttons */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap', // Wrap buttons on smaller screens
                                gap: '1rem',
                            }}
                        >
                            <Button
                                onClick={handleSearch} // Trigger search when search button is clicked
                                sx={{
                                    backgroundColor: '#027BFE',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: { xs: '100%', sm: '120px' }, // Responsive width
                                }}
                            >
                                Search
                            </Button>
                            <Button
                                onClick={handleClear} // Reset values and call API when clicked

                                sx={{
                                    backgroundColor: 'gray',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: { xs: '100%', sm: '120px' }, // Responsive width
                                }}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>


            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (

                <>
                    <Grid style={{ marginTop: '3%' }}>
                        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px', boxShadow: 'none' }}>
                            <Table>
                                <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                                    <TableRow>
                                        <TableCell style={{ color: 'white' }} align="start">Sr. No.</TableCell>
                                        <TableCell style={{ color: 'white' }} align="start">Txn ID</TableCell>
                                        <TableCell style={{ color: 'white' }} align="start">EasePay ID</TableCell>
                                        <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                                        <TableCell style={{ color: 'white' }} align="start">PayIn Amount</TableCell>
                                        <TableCell style={{ color: 'white' }} align="start">Amount</TableCell>
                                        <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                                        <TableCell style={{ color: 'white' }} align="center">Invoice</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.length > 0 ? (
                                        filteredData.map((row, index) => (
                                            <TableRow key={row.id}
                                                className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                                <TableCell>{(currentPage) * rowsPerPage + index + 1}</TableCell>
                                                <TableCell align="start">{row.txnid}</TableCell>
                                                <TableCell align="start">{row.easepayid}</TableCell>
                                                <TableCell align="start">{formatDate(row.addedon)}</TableCell>
                                                <TableCell align="start">{row.netAmountDebit || 'N/A'}</TableCell>
                                                <TableCell align="start">{row.amount}</TableCell>
                                                <TableCell align="start" style={{
                                                    color: row.status === 'success' ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}>
                                                    {row.status === 'success' ? 'success' : 'Failed'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <DownloadIcon1
                                                        onClick={() => handleDownloadInvoice(row)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </TableCell>
                                                <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                                    <Grid id={`receipt-${row.txnid}`}>
                                                        <PayinReportInvoice data={row} />
                                                    </Grid>
                                                </Grid>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">No Data Available</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                        <TablePagination
                        component="div"
                        count={totalRecords}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        sx={{ marginTop: 2 }}
                        nextIconButtonProps={{
                            disabled: (currentPage + 1) * rowsPerPage >= totalRecords || statusCodeValue === 204,
                        }}
                        backIconButtonProps={{
                            disabled: currentPage === 0,
                        }}
                    />
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default NewPayIn;
