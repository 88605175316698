import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Pagination,
  InputBase,
  IconButton,
  Box,
  TablePagination,
} from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { Formik, Form } from "formik";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DownloadIcon, PartnerArrowIcon } from "../../res/NewWebIcons";
import Papa from "papaparse"; // Import PapaParse
import iPaisaLogo from "../Assets/iPaisaLogo/iPaisaLogo.jpg";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import DownloadIcon1 from "@mui/icons-material/Download";
import DebitTransactionInvoice from "./DebitTransactionInvoice";
import CreditTransactionInvoice from "./CreditTransactionInvoice";

const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const NewFundTReport = () => {
  const [activeTab, setActiveTab] = useState("DEBIT"); // Track the active tab
  const [debitData, setDebitData] = useState([]);
  const [creditData, setCreditData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const userType = sessionStorage.getItem("uType");
  const userName = sessionStorage.getItem("username");
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validationSchema = Yup.object({
    fromDate: Yup.date().required("Required"),
    toDate: Yup.date().required("Required"),
  });

  const fetchDebitData = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/wtransReportDebt",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Check if response.data.response is an array and map the complete data structure
      return Array.isArray(response.data.response)
        ? response.data.response.map((item) => ({
          ...item.transaction,
          sender_address: item.sender_address,
          receiver_address: item.receiver_address,
        }))
        : [];
    } catch (error) {
      console.error("Error fetching debit data:", error);
      setError(error.message);
      return [];
    }
  };

  const fetchCreditData = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/wtransReportCred",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Check if response.data.response is an array and map the complete data structure
      return Array.isArray(response.data.response)
        ? response.data.response.map((item) => ({
          ...item.transaction,
          sender_address: item.sender_address,
          receiver_address: item.receiver_address,
        }))
        : [];
    } catch (error) {
      console.error("Error fetching credit data:", error);
      setError(error.message);
      return [];
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and pad
    const year = date.getFullYear(); // Get year
    return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const debit = await fetchDebitData();
      const credit = await fetchCreditData();
      setDebitData(debit);
      setCreditData(credit);
      setFilteredData(debit); // Default to showing Debit transactions
      setLoading(false);
      setActiveTab("DEBIT");
    };
    loadData();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setFilteredData(tab === "DEBIT" ? debitData : creditData);
    setPage(0); // Reset pagination to the first page
  };

  const handleSearch = (values) => {
    const { fromDate, toDate } = values;
    const from = new Date(fromDate);
    const to = new Date(toDate);
    to.setHours(23, 59, 59, 999);

    const filteredDebitData = debitData.filter(
      (item) => new Date(item.date) >= from && new Date(item.date) <= to
    );
    const filteredCreditData = creditData.filter(
      (item) => new Date(item.date) >= from && new Date(item.date) <= to
    );

    setFilteredData([...filteredDebitData, ...filteredCreditData]);
    setPage(0);
  };

  // New function to handle clear button
  const handleClear = (resetForm) => {
    // Reset form values to empty
    resetForm();
    setSearchQuery("");
    // Reset the filtered data to the full dataset
    setFilteredData([...debitData, ...creditData]);
    setActiveTab("DEBIT");
  };

  const handleDownloadInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.senderId}`);

    if (!receiptElement) {
      console.error("Receipt element not found:", `receipt-${data.senderId}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `Fund Transfer Receipt-${data.senderId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        orientation: "landscape",
        format: "a4",
        margin: 0.5,
      },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  const generateDebitPDF = () => {
    const doc = new jsPDF();
    doc.addImage(iPaisaLogo, "PNG", 10, 10, 30, 30);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Fund Transfer Report - DEBIT", 50, 20);

    const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
    const tableRows = filteredData
      .filter((row) => row.transactionType === "DEBIT") // Only DEBIT rows
      .map((row) => [
        new Date(row.date).toLocaleString(),
        `Paid to ${row.senderName}\nTransaction ID ${row.tranxid}\nRemark. ${row.remark}\nPaid by ${userName}`,
        row.transactionType,
        `${row.amount.toFixed(2)}`,
      ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontSize: 12,
      },
    });

    doc.save("DEBIT_Transaction_Statement.pdf");
  };

  const generateCreditPDF = () => {
    const doc = new jsPDF();
    doc.addImage(iPaisaLogo, "PNG", 10, 10, 30, 30);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Fund Transfer Report - CREDIT", 50, 20);

    const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
    const tableRows = filteredData
      .filter((row) => row.transactionType === "CREDIT") // Only CREDIT rows
      .map((row) => [
        new Date(row.date).toLocaleString(),
        `Received from ${row.senderName}\nTransaction ID ${row.tranxid}\nRemark. ${row.remark}\nCredited to ${userName}`,
        row.transactionType,
        `${row.amount.toFixed(2)}`,
      ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontSize: 12,
      },
    });

    doc.save("CREDIT_Transaction_Statement.pdf");
  };

  const handleDownloadPDF = () => {
    if (activeTab === "DEBIT") {
      generateDebitPDF();
    } else if (activeTab === "CREDIT") {
      generateCreditPDF();
    }
  };

  const generateDebitCSV = () => {
    const csvData = filteredData
      .filter((row) => row.transactionType === "DEBIT") // Only DEBIT rows
      .map((row, index) => ({
        "Sr. No.": index + 1,
        "Transaction ID": row.tranxid,
        "Receiver ID": row.senderId,
        "Receiver Name": row.senderName,
        "Receiver Number": row.senderNumber,
        Date: new Date(row.date).toLocaleString(),
        "Wallet Opening Balance": row.wOpeningBalance,
        Amount: row.amount.toFixed(2),
        "Wallet Closing Balance": row.wClosingBalance,
        Remark: row.remark,
        "Transaction Type": row.transactionType,
      }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "DEBIT_Transaction_Statement.csv");
    a.style.visibility = "hidden";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generateCreditCSV = () => {
    const csvData = filteredData
      .filter((row) => row.transactionType === "CREDIT") // Only CREDIT rows
      .map((row, index) => ({
        "Sr. No.": index + 1,
        "Transaction ID": row.tranxid,
        "Sender ID": row.senderId,
        "Sender Name": row.senderName,
        "Sender Number": row.senderNumber,
        Date: new Date(row.date).toLocaleString(),
        "Wallet Opening Balance": row.wOpeningBalance,
        Amount: row.amount.toFixed(2),
        "Wallet Closing Balance": row.wClosingBalance,
        Remark: row.remark,
        "Transaction Type": row.transactionType,
      }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "CREDIT_Transaction_Statement.csv");
    a.style.visibility = "hidden";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownloadCSV = () => {
    if (activeTab === "DEBIT") {
      generateDebitCSV();
    } else if (activeTab === "CREDIT") {
      generateCreditCSV();
    }
  };

  // const uniqueData = filteredData.filter((item, index, self) =>
  //   index === self.findIndex((t) => t.tranxid === item.tranxid)
  // );

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchButtonClick = async () => {
    const lowerCaseQuery = searchQuery.toLowerCase();

    // Ensure there is a search query before calling the API
    if (searchQuery.trim()) {
      try {
        const token = sessionStorage.getItem("jwtToken");

        // Perform API call with the search query as a parameter and the token in the Authorization header
        const response = await axios.get(
          `https://api.ipaisa.co.in/myapp/auth/searchInWalletTransaction?searchBy=${lowerCaseQuery}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Check the response and process data
        if (response.status === 200 && response.data?.body?.data?.length > 0) {
          const transactions = response.data.body.data.map(item => item.transaction);

          // Set the filtered data to your state with all transactions
          setFilteredData(transactions);
          setPage(0); // Reset to the first page
        } else {
          throw new Error("Invalid response or no data found.");
        }
      } catch (error) {
        console.error("Error during API call:", error.message || error);
        // Graceful error handling: notify user or log to analytics
      }
    } else {
      console.warn("Search query is empty.");
    }
  };




  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const debit = await fetchDebitData();
      const credit = await fetchCreditData();
      setDebitData(debit);
      setCreditData(credit);
      setFilteredData(debit); // Default to showing Debit transactions
      setLoading(false);
      setActiveTab("DEBIT");
    };
    loadData();
  }, []);

  // Ensure the uniqueData is sorted by date in descending order

  console.log("Filtered Data", filteredData);
  return (
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Reports
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Fund Transfer Report
        </Typography>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "1%" }}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "sans-serif",
              fontSize: { xs: "16px", md: "19px" },
              color: "#343C6A",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Fund Transfer Report
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Button
            onClick={handleDownloadCSV} // Single handler for both DEBIT and CREDIT
            sx={{
              textTransform: "none",
              backgroundColor: "#027BFE",
              color: "#FFFFFF",
              borderRadius: "10px",
              padding: "8px 16px",
              fontFamily: "sans-serif",
              fontSize: { xs: "12px", md: "14px" },
              height: "40px",
              gap: 1,
            }}
          >
            <DownloadIcon />
            Download CSV
          </Button>

          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#027BFE",
              color: "#FFFFFF",
              borderRadius: "10px",
              padding: "8px 16px",
              fontFamily: "sans-serif",
              fontSize: { xs: "12px", md: "14px" },
              height: "40px",
              minWidth: { xs: "120px", sm: "140px" },
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
            onClick={handleDownloadPDF} // Single handler for both DEBIT and CREDIT
          >
            <DownloadIcon />
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <Paper
        sx={{
          boxShadow: "none",
          boxShadow: "none",
          padding: "2rem",
          borderRadius: 2,
          marginTop: "2%",
          boxShadow: "none",
        }}
      >
        <Formik
          initialValues={{ fromDate: "", toDate: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSearch}
        >
          {({ errors, touched, setFieldValue, handleSubmit, resetForm }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    id="from-date"
                    name="fromDate"
                    label="From Date"
                    type="date"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={touched.fromDate && Boolean(errors.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    id="to-date"
                    name="toDate"
                    label="To Date"
                    type="date"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={touched.toDate && Boolean(errors.toDate)}
                    helperText={touched.toDate && errors.toDate}
                  />
                </Grid>

                {/* Buttons aligned to the right with space between them */}
                <Grid item xs={12} style={{ marginTop: "3%" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={2}
                  >
                    <Button
                      onClick={handleSubmit}
                      sx={{
                        backgroundColor: "#027BFE",
                        color: "white",
                        height: "56px",
                        textTransform: "none",
                        borderRadius: "10px",
                        width: "120px",
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={() => handleClear(resetForm)}
                      sx={{
                        backgroundColor: "gray",
                        color: "white",
                        height: "56px",
                        textTransform: "none",
                        borderRadius: "10px",
                        width: "120px",
                      }}
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
      {loading && (
        <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
          Loading...
        </Typography>
      )}
      {error && (
        <Typography color="error" sx={{ textAlign: "center", mt: 2 }}>
          {error}
        </Typography>
      )}
      {!loading && !error && (
        <>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {/* Search Bar */}
            <Grid
              item
              style={{
                display: "flex",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                maxWidth: "40%", // Adjust the width as needed
                borderRadius: 10,
                border: "1px solid rgba(0, 0, 0, 0.3)",
                padding: "1%",
              }}
            >
              <InputBase
                fullWidth
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && searchQuery.trim()) {
                    // Ensure there's input
                    handleSearchButtonClick(); // Call the search function when Enter is pressed
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                backgroundColor="textSecondary"
                onClick={handleSearchButtonClick}
                disabled={!searchQuery.trim()} // Disable button if no input
              >
                <SearchIcon />
              </IconButton>
            </Grid>

            {/* Buttons */}
            {userType !== "ADMIN" && (
              <Grid
                item
                style={{
                  display: "flex",
                  gap: "8px", // Space between buttons
                }}
              >
                {userType !== "RETAILER" && (
                  <Button
                    onClick={() => handleTabClick("DEBIT")}
                    sx={{
                      textTransform: "none",
                      backgroundColor: activeTab === "DEBIT" ? "red" : "gray",
                      color: "white",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      fontFamily: "sans-serif",
                      fontSize: { xs: "12px", md: "14px" },
                    }}
                  >
                    Debit
                  </Button>
                )}
                <Button
                  onClick={() => handleTabClick("CREDIT")}
                  sx={{
                    textTransform: "none",
                    backgroundColor: activeTab === "CREDIT" ? "green" : "gray",
                    color: "white",
                    borderRadius: "10px",
                    padding: "8px 16px",
                    fontFamily: "sans-serif",
                    fontSize: { xs: "12px", md: "14px" },
                  }}
                >
                  Credit
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid sx={{ marginTop: "3%" }}>
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "2%",
                overflowX: "auto",
                borderRadius: "12px 12px 0px 0px",
                boxShadow: "none",
              }}
            >
              <Table>
                <TableHead sx={{ backgroundColor: "#027BFE", color: "white" }}>
                  <TableRow>
                    {(activeTab === "DEBIT"
                      ? [
                        "Sr. No.",
                        "Receiver Name",
                        "Receiver ID",
                        "Transaction ID",
                        "Date",
                        "Receiver Number",
                        "Wallet Opening Balance",
                        "Amount",
                        "Wallet Closing Balance",
                        "Remark",
                        "Transaction Type",
                        "Invoice",
                      ]
                      : [
                        "Sr. No.",
                        "Sender Name",
                        "Sender ID",
                        "Transaction ID",
                        "Date",
                        "Sender Number",
                        "Wallet Opening Balance",
                        "Amount",
                        "Wallet Closing Balance",
                        "Remark",
                        "Transaction Type",
                        "Invoice",
                      ]
                    ).map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "white",
                          fontSize: { xs: "9px", sm: "14px" },
                          fontWeight: "600",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Array.isArray(filteredData) &&
                    filteredData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <TableRow
                          key={row.tranxid}
                          className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                        >
                          <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                          <TableCell>{row.senderName || row.receiverName || "N/A"}</TableCell>
                          <TableCell>{row.senderId || "N/A"}</TableCell>
                          <TableCell>{row.tranxid}</TableCell>
                          <TableCell>{formatDate(row.date)}</TableCell>
                          <TableCell>{row.receiverNumber || row.senderNumber || "N/A"}</TableCell>
                          <TableCell>
                            {Number(row.wOpeningBalance).toFixed(2)}
                          </TableCell>
                          <TableCell>{row.amount}</TableCell>
                          <TableCell>
                            {Number(row.wClosingBalance).toFixed(2)}
                          </TableCell>
                          <TableCell>{row.remark || "N/A"}</TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              color:
                                row.transactionType === "DEBIT"
                                  ? "red"
                                  : row.transactionType === "CREDIT"
                                    ? "green"
                                    : "inherit",
                            }}
                          >
                            {row.transactionType}
                          </TableCell>
                          <TableCell align="center">
                            <DownloadIcon1
                              onClick={() => handleDownloadInvoice(row)}
                              style={{ cursor: "pointer" }}
                            />
                          </TableCell>
                          <Grid
                            style={{
                              position: "absolute",
                              left: "-9999px",
                              top: "0px",
                            }}
                          >
                            <Grid id={`receipt-${row.senderId}`}>
                              {row.transactionType === "DEBIT" ? (
                                <DebitTransactionInvoice data={row} />
                              ) : row.transactionType === "CREDIT" ? (
                                <CreditTransactionInvoice data={row} />
                              ) : null}
                            </Grid>
                          </Grid>
                        </TableRow>
                      ))}
                </TableBody>


              </Table>
            </TableContainer>

            {/* Pagination */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
              component="div"
              count={filteredData.length} // Total number of rows
              rowsPerPage={rowsPerPage} // Current rows per page
              page={page} // Current page index
              onPageChange={handleChangePage} // Function to handle page change
              onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle rows per page change
            />
          </Grid>
        </>
      )}
    </Box>
  );
};

export default NewFundTReport;
