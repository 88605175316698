import React, { useState } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Faq() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container style={{ marginTop: '2%', padding: '5%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black' }}>
          FAQ of iPaisa
        </Typography>

        {[...Array(27).keys()].map((index) => (
          <Accordion
          sx={{boxShadow:'none'}}
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              sx={{boxShadow:'none'}}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
                {`Q${index + 1}:`} {getQuestionText(index + 1)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{boxShadow:'none'}}>
              <Typography variant="body1" sx={{ color: 'black' }}>
                <b>Ans:</b> {getAnswerText(index + 1)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}

function getQuestionText(index) {
  switch (index) {
    case 1:
      return 'What is iPaisa?';
    case 2:
      return 'How do I download and install the iPaisa app?';
    case 3:
      return 'How do I create an account on iPaisa?';
    case 4:
      return 'Is iPaisa safe and secure to use?';
    case 5:
      return 'Is iPaisa a secure platform for online money transfers?';
    case 6:
      return 'Can I link my bank account to iPaisa for easy transactions?';
    case 7:
      return 'Is iPaisa approved by the regulatory authorities?';
    case 8:
      return 'What are the privacy and security features offered by iPaisa?';
    case 9:
      return 'Can I transfer money instantly with BHIM UPI through iPaisa?';
    case 10:
      return 'Can I use iPaisa to pay my utility bills?';
    case 11:
      return 'What are the different recharge services available on iPaisa?';
    case 12:
      return 'Can I use multiple payment methods on iPaisa, such as credit/debit cards and UPI?';
    case 13:
      return 'Can I link multiple bank accounts to my iPaisa account?';
    case 14:
      return 'How can I add money to my iPaisa wallet?';
    case 15:
      return 'Can I transfer money from my iPaisa wallet to a bank account?';
    case 16:
      return 'Does iPaisa support FASTag payments for tolls?';
    case 17:
      return 'Does iPaisa offer any cashback or rewards programs?';
    case 18:
      return 'How secure is my information on iPaisa?';
    case 19:
      return 'Can I use iPaisa to make payments at physical stores?';
    case 20:
      return 'Can I use iPaisa to make purchases online?';
    case 21:
      return 'Can I use iPaisa outside of my country?';
    case 22:
      return 'Can I set reminders for due bills with iPaisa?';
    case 23:
      return 'What are the charges or fees associated with using iPaisa?';
    case 24:
      return 'What if someone tries to login to my account?';
    case 25:
      return 'How can I contact customer support if I face any issues with the app?';
    case 26:
      return 'How secure is my information on iPaisa?';
    case 27:
      return 'How can I contact customer support for any issues or queries related to iPaisa?';
    default:
      return '';
  }
}

function getAnswerText(index) {
  switch (index) {
    case 1:
      return 'iPaisa is a mobile payment platform that allows users to make transactions using their smartphones.';
    case 2:
      return 'To download and install the iPaisa app, you can visit the App Store or Google Play Store on your mobile device. Search for "iPaisa" and click on the download/install button. Follow the on-screen instructions to complete the installation process.';
    case 3:
      return 'To create an account on iPaisa, open the app and click on the "Sign Up" or "Register" button. Fill in the required details such as your mobile number, email address, and create a password. Verify your mobile number through an OTP (One-Time Password) sent to your registered mobile number. Once verified, your account will be created.';
    case 4:
      return 'Yes, iPaisa uses advanced security measures such as encryption and two-factor authentication to ensure the safety and security of users\' transactions.';
    case 5:
      return 'Yes, iPaisa is a secure platform for online money transfers. It uses secure encryption protocols to protect your personal and financial information. Additionally, it offers features like two-factor authentication and secure login to ensure the safety of your transactions.';
    case 6:
      return 'Yes, you can link your bank account to iPaisa for easy transactions. The app supports linking of bank accounts through BHIM UPI, allowing you to transfer money instantly to any mobile number or bank account using QR code.';
    case 7:
      return 'iPaisa is approved by the government and regulatory authorities. It complies with all necessary regulations and guidelines to ensure a secure and reliable platform for financial transactions.';
    case 8:
      return 'iPaisa offers privacy and security features such as secure encryption, two-factor authentication, and secure login. It also adheres to strict privacy policies to protect your personal information. You can find more details about the privacy and security features in the app or website.';
    case 9:
      return 'Yes, users can receive or transfer money instantly to any mobile number with UPI and also send money to any bank account using QR code.';
    case 10:
      return 'Yes, you can use iPaisa to pay your utility bills such as electricity, water, gas, broadband, and landline. Simply select the respective utility bill option in the app, enter the required details, and make the payment.';
    case 11:
      return 'iPaisa offers different recharge services including data card recharge, DTH recharge, and FasTag recharge. You can easily recharge your data card and DTH connections by selecting the appropriate option in the app and following the instructions. Additionally, you can recharge your FasTag for various providers with minimum documentation.';
    case 12:
      return 'Yes, you can use multiple payment methods on iPaisa, including credit/debit cards and UPI. The app supports various payment options to provide flexibility and convenience to users. During the payment process, you can choose your preferred payment method and complete the transaction accordingly.';
    case 13:
      return 'Yes, you can link multiple bank accounts to your iPaisa account for added convenience.';
    case 14:
      return 'You can add money to your iPaisa wallet by selecting the "Add Money" option in the app. Choose your preferred payment method such as debit/credit cards or UPI, enter the amount you want to add, and complete the transaction.';
    case 15:
      return 'Yes, you can transfer money from your iPaisa wallet to a bank account. Simply select the "Transfer to Bank Account" option in the app, enter the required details such as bank account number and IFSC code, and initiate the transfer.';
    case 16:
      return 'Yes, iPaisa supports FASTag payments for tolls. You can buy and manage FasTag for any vehicle with minimum documentation through the app. Additionally, you can recharge your FasTag for major providers like ICICI, HDFC, Bank of Baroda, IndusInd, etc.';
    case 17:
      return 'Yes, iPaisa offers cashback and rewards programs. You can earn cashback on certain transactions or participate in promotional offers to avail discounts or rewards. Keep an eye on the app for any ongoing offers or rewards programs.';
    case 18:
      return 'All your information is secured on iPaisa. As soon as you register on iPaisa, your number is validated via an OTP. There is a PIN or Password setup for all UPI transactions. Also, your login from any device is validated through an OTP verification.';
    case 19:
      return 'Yes, you can use iPaisa to make payments at physical stores. The app supports contactless payments using QR codes, allowing you to make secure and convenient payments at supported merchants.';
    case 20:
      return 'Yes, you can use iPaisa to make purchases online. The app provides a secure and convenient way to pay for goods and services on various e-commerce platforms and websites.';
    case 21:
      return 'Currently, iPaisa is available for use within the country. It is recommended to check the app\'s terms and conditions or contact customer support for specific details about using iPaisa outside the country.';
    case 22:
      return 'Yes, iPaisa allows you to set reminders for due bills. You can set up bill reminders within the app, ensuring you never miss a payment deadline. The app will notify you when a bill is due, helping you stay on top of your financial obligations.';
    case 23:
      return 'The charges and fees associated with using iPaisa may vary depending on the type of transaction and the payment method used. It is recommended to check the app\'s terms and conditions or contact customer support for specific details regarding fees.';
    case 24:
      return 'iPaisa will immediately send you an alert on your registered mobile number if someone tries to log into your account.';
    case 25:
      return 'You can contact customer support through the app by navigating to the "Help & Support" section. Alternatively, you can reach out to the iPaisa customer support team via email or phone. The contact details for customer support are available in the app or on the official website.';
    case 26:
      return 'All your information is secured on iPaisa. As soon as you register on iPaisa, your number is validated via an OTP. There is a PIN or Password setup for all UPI transactions. Also, your login from any device is validated through an OTP verification.';
    case 27:
      return 'You can contact customer support through the app by navigating to the "Help & Support" section. Alternatively, you can reach out to the iPaisa customer support team via email or phone. The contact details for customer support are available in the app or on the official website.';
    default:
      return '';
  }
}
