// import React from 'react';
// import {
//   Box,
//   Typography,
//   Grid,
//   Paper,
//   Button,
//   Stack,
//   Container
// } from '@mui/material';

// const RoundTripFare = ({ flightsData,fdetails,
//   handleToggleTabs,
//   showTabs,
//   activeTab,
//   durationCalculator,
//   selectedValue,
//   onRadioChange,
//   isSelected,
//   onSelect,
//  }) => {
//   const airlineCode = fdetails.Segments[0][0].Airline.AirlineCode;
//   const flightKey = `${airlineCode}-${fdetails.Segments[0][0].Airline.FlightNumber}`;
//   // ... (rest of the code, including importAllLogos and airlineLogos)
//   const importAllLogos = (requireContext) => {
//     const logos = {};
//     requireContext.keys().forEach((key) => {
//       const airlineCode = key.replace("./", "").replace(".gif", "");
//       logos[airlineCode] = requireContext(key);
//     });
//     return logos;
//   };
//   const airlineLogos = importAllLogos(
//     require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
//   );

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const isMediumScreen = useMediaQuery(
//     theme.breakpoints.up("sm") && theme.breakpoints.down("md")
//   );

//   const [selectedCard, setSelectedCard] = useState(false);

//   const handleCardSelection = () => {
//     setSelectedCard(!selectedCard);
//   };

//   return (

//     <Paper elevation={3} sx={{ p: 2 }}>
//       <Grid container spacing={2}>
//         <Grid item xs={6}>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Box sx={{ mr: 2 }}>{/* Add airline logo here */}</Box>
//             <Box>
//               <Typography variant="body1">{outbound.airline} - {outbound.flightNumber}</Typography>
//               <Typography variant="body2">{outbound.departureTime} - {outbound.arrivalTime}</Typography>
//               <Typography variant="body2">{outbound.duration}</Typography>
//               <Typography variant="body2">{outbound.stops}</Typography>
//             </Box>
//           </Box>
//           <Typography variant="h6">₹{outbound.price}</Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Box sx={{ mr: 2 }}>{/* Add airline logo here */}</Box>
//             <Box>
//               <Typography variant="body1">{inbound.airline} - {inbound.flightNumber}</Typography>
//               <Typography variant="body2">{inbound.departureTime} - {inbound.arrivalTime}</Typography>
//               <Typography variant="body2">{inbound.duration}</Typography>
//               <Typography variant="body2">{inbound.stops}</Typography>
//             </Box>
//           </Box>
//           <Typography variant="h6">₹{inbound.price}</Typography>
//         </Grid>
//       </Grid>
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="h5">Total Price: ₹{totalPrice}</Typography>
//         <Button variant="contained" color="primary">View Fare & Flight Details</Button>
//       </Box>
//     </Paper>
//   );
// };

// export default RoundTripFare;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import RoundTripCard from "./RoundTripCard";
import RvFare from "./RvFare";

const RoundTripFare = ({
  flightsData,
  fdetails,
  handleToggleTabs,
  showTabs,
  activeTab,
  durationCalculator,
  selectedValue,
  onRadioChange,
  isSelected,
  onSelect,
  outbound,
  inbound,
  totalPrice,
}) => {
  const importAllLogos = (requireContext) => {
    const logos = {};
    requireContext.keys().forEach((key) => {
      const airlineCode = key.replace("./", "").replace(".gif", "");
      logos[airlineCode] = requireContext(key);
    });
    return logos;
  };

  const airlineLogos = importAllLogos(
    require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.up("sm") && theme.breakpoints.down("md")
  );

  console.log(outbound);

  return (
    <Paper
      elevation={3}
      //  sx={{ p: 0.5 }}
      sx={{ width: "100%" }}
    >
      <Stack direction={"row"}>
        <Box>
          <RvFare durationCalculator={durationCalculator} fdetails={outbound} />
        </Box>
        <Box>
          <RvFare durationCalculator={durationCalculator} fdetails={inbound} />
        </Box>
        {/* <Box sx={{ mt: 2 }}>
          <Typography variant="h5">Total Price: ₹{totalPrice}</Typography>
          <Button variant="contained" color="primary">
            View Fare & Flight Details
          </Button>
        </Box> */}
      </Stack>
    </Paper>
  );
};

export default RoundTripFare;
