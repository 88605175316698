import axios from "axios";
import { toast } from "react-toastify";  // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import styles for toast

const setupInterceptor = (navigate) => {
  let timer = null;
  const timeoutDuration = 10 * 60 * 1000; // 2 minutes in milliseconds

  const resetTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // Remove JWT token after inactivity
      sessionStorage.removeItem("jwtToken");
    }, timeoutDuration);
  };

  // Reset timer on every API request
  axios.interceptors.request.use(
    (config) => {
      console.log('hello'); // Log on every request

      const token = sessionStorage.getItem("jwtToken");

      // If no token found, and we're not navigating to /SignIn already, redirect
      if (!token) {
        if (config.url !== '/SignIn') {
          navigate("/SignIn"); // Redirect to SignIn if token is not present
        }
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }

      resetTimer();
      return config;
    },
    (error) => {
      console.log('Request error'); // This will log if there's an error with the request
      return Promise.reject(error);
    }
  );

  // Handle API response errors (optional)
  axios.interceptors.response.use(
    (response) => {
      console.log('hello from response'); // This will log on successful response
      return response;
    },
    (error) => {
      console.log('Response error'); // This will log when there's an error in the response
      
      if (error.response) {
        // Check for status 300, indicating session expiration or a need to re-SignIn
        if (error.response.status === 300) {
          // Show toast for re-SignIn prompt
          toast.error("Your session has expired. Please log in again.", {
            position: "top-center",
            autoClose: 5000, // Close the toast after 5 seconds
          });

          sessionStorage.removeItem("jwtToken"); // Remove token if session has expired
          
          // Redirect to SignIn after showing the toast
          setTimeout(() => {
            navigate("/SignIn");
          }, 3000); // Redirect after a delay to allow the toast to be visible
        }

        // Handle other error statuses (like 401) if needed
        if (error.response.status === 401) {
          toast.error("Please SignIn again", {
            position: "top-center",
            autoClose: 5000,
          });

          sessionStorage.removeItem("jwtToken");
          setTimeout(() => {
            navigate("/SignIn");
          }, 3000);
        }
      }

      return Promise.reject(error);
    }
  );
};

export default setupInterceptor;
