import React, { useState, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Grid,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ResetPassword from './ResetMpin';
import ForgotPin from './ForgotPin';

const IpinModal = ({ open, onClose, onMpinValid }) => {
    const [mpinValue, setMpinValue] = useState(['', '', '', '']);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showForgotPin, setShowForgotPin] = useState(false); // State for showing ForgotPin component
    const [showMpin, setShowMpin] = useState(false); // State for toggling visibility of MPIN
    const inputRefs = useRef([]);
    const navigate = useNavigate();

    const handleInputChange = (value, index) => {
        if (/^\d$/.test(value)) {
            const newMpin = [...mpinValue];
            newMpin[index] = value;
            setMpinValue(newMpin);

            if (index < mpinValue.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            const newMpin = [...mpinValue];
            if (newMpin[index] !== '') {
                newMpin[index] = '';
                setMpinValue(newMpin);
                inputRefs.current[index].focus();
            } else if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handleSubmit = async () => {
        const mpin = mpinValue.join('');
        const token = sessionStorage.getItem('jwtToken');

        setLoading(true);

        try {
            const response = await axios.post('https://api.ipaisa.co.in/myapp/auth/validateTxnMpin', {
                mpin,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            toast(response.data.body.message);

            if (response.data.statusCodeValue === 200 && response.data.body.message === 'MPIN is valid') {
                onMpinValid(); // Call the payout API
                setMpinValue(['', '', '', '']);
                handleDialogClose();
            } else if (response.data.statusCodeValue === 401) {
                // Handle Invalid MPIN
                setErrorMessage('Invalid MPIN');
            } else {
                setErrorMessage(response.data.body.message);
            }

        } catch (error) {
            if (error.response) {
                if (error.response.status === 404 && error.response.data.body.message === 'MPIN not set for user') {
                    toast.error(error.response.data.body.message);
                    setErrorMessage('Please set your IPIN first!');
                } else if (error.response.status === 401) {
                    setErrorMessage('Invalid MPIN');
                } else {
                    setErrorMessage('An error occurred. Please try again.');
                }
            } else {
                setErrorMessage('An error occurred. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDialogClose = () => {
        setMpinValue(['', '', '', '']);
        setErrorMessage('');
        setShowForgotPin(false); // Reset ForgotPin visibility
        onClose();
    };

    const handleErrorClick = () => {
        navigate('/mpinlanding');
    };

    const handleForgotPinClick = () => {
        setShowForgotPin(true); // Show the ForgotPin modal
    };

    const inputHeight = '50px';

    return (
        <>
            <Dialog open={open} onClose={handleDialogClose} maxWidth="xs" fullWidth>
                <DialogTitle sx={{ padding: '3%' }}>
                    IPIN
                    <IconButton
                        aria-label="close"
                        onClick={handleDialogClose}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        {/* 4 MPIN Boxes */}
                        {mpinValue.map((digit, index) => (
                            <Grid item key={index} xs={2.4}>  {/* Adjust width to fit 4 boxes in a row */}
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type={showMpin ? 'text' : 'password'} // Toggle visibility based on state
                                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                    value={digit}
                                    onChange={(e) => handleInputChange(e.target.value, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                />
                            </Grid>
                        ))}

                        {/* Eye Icon to Toggle MPIN Visibility */}
                        <Grid item xs={1.2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10%' }}>
                            <IconButton onClick={() => setShowMpin(!showMpin)}>
                                {showMpin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>


                    {/* Error Message */}
                    {errorMessage && (
                        <Typography
                            variant="body2"
                            color="error"
                            style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            onClick={handleErrorClick}
                        >
                            ⚠️ {errorMessage}
                        </Typography>
                    )}

                    {/* Forgot IPIN Link */}
                    <Typography
                        variant="body2"
                        color="primary"
                        style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => alert('Redirect to Forgot IPIN page')}
                    >
                        Forgot IPIN?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setMpinValue(['', '', '', ''])}
                        sx={{
                            textTransform: 'none',
                            width: '18%',
                            height: '50px',
                            borderRadius: '8px',
                            color: '#808080',
                            border: '1px solid #D9D9D9',
                        }}>
                        Clear
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        disabled={loading}
                        sx={{
                            backgroundColor: "#027BFE",
                            color: "white",
                            textTransform: "none",
                            width: { xs: '100%', md: '18%' },
                            fontSize: '16px',
                            borderRadius: '8px',
                            height: inputHeight,
                            '&:hover': {
                                backgroundColor: "#027BFE",
                                color: '#FFFFFF',
                            },
                        }}
                    >
                        {loading ? <CircularProgress size={24} style={{ color: '#FFFFFF' }} /> : "Submit"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Render ForgotPin modal if showForgotPin is true */}
            {showForgotPin && (
                <ForgotPin open={showForgotPin} onClose={handleDialogClose} />
            )}
        </>
    );
};

export default IpinModal;
