import React from 'react';
import axios from 'axios';
import { Container } from '@mui/material';

export default function PaymentMethodChangeEvent() {
  // Function to create an order
  const createOrder = async (amount) => {
    try {
      const token = sessionStorage.getItem("jwtToken");

      // Ensure amount is an integer
      const integerAmount = Math.round(amount);

      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/razorpay/create-order",
        { amount: integerAmount },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Store the order data in response.data and return it
      return response.data;
    } catch (error) {
      console.error("Error creating order:", error);
      alert("Failed to create order.");
      return null;
    }
  };

  // Payment handling function
  const handlePayment = async (orderData) => {
    try {
      const { id: orderId, amount, currency, key } = orderData;

      // Check if Razorpay is loaded
      if (typeof window.Razorpay === "undefined") {
        alert("Razorpay SDK not loaded. Please check your internet connection.");
        return;
      }

      const options = {
        key: 'rzp_live_9lZyU2RZhKmttY',
        amount: amount,
        currency: currency,
        name: "ipaisa",
        description: "Transaction",
        order_id: orderId,
        image: "https://test.ipaisa.co.in/static/media/iPaisaLogo.3682d2408e2c0eb923ed.jpg",
        handler: async function (response) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };
          console.log("Order ID:", response.razorpay_order_id);
          console.log("Payment ID:", response.razorpay_payment_id);
          console.log("Signature:", response.razorpay_signature);
          alert("Order ID: " + response.razorpay_order_id);
          alert("Payment ID: " + response.razorpay_payment_id);

          try {
            const verifyResponse = await axios.post(
              "https://api.ipaisa.co.in/myapp/auth/verify-payment",
              paymentData
            );
            if (verifyResponse.status === 200) {
              alert("Payment successful and verified!");
            } else {
              alert("Payment verification failed");
            }
          } catch (error) {
            console.error("Payment verification error:", error);
            alert("Payment verification failed");
          }
        },
        prefill: {
          name: "Ashish Dhanorkar",
          email: "ashishdhanorkar282gmail.com",
          contact: "8421570262",
        },
        notes: {
          address: "Corporate Office",
        },
        theme: {
          color: "#03DBFC",
        },
      };

      // Open Razorpay checkout window
      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      // Payment failure handler
      rzp1.on("payment.failed", function (response) {
        console.error("Payment failed:", response.error);
        alert("Payment failed. Please try again.");
      });
    } catch (error) {
      console.error("Error in payment handler:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  // Button click handler to create order and initiate payment
  const handlePaymentClick = async () => {
    const orderData = await createOrder(1); // Specify amount here
    if (orderData) {
      handlePayment(orderData); // Proceed to payment if order creation is successful
    }
  };

  return (
    <Container sx={{ mt: '15%' }}>
      <button onClick={handlePaymentClick}>Pay Now</button>
    </Container>
  );
}
