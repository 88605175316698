import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  MobileFriendly,
  Tv,
  Lightbulb,
  Phone,
  Router,
  WaterDrop,
  GasMeterRounded,
  HealthAndSafety,
  VolunteerActivism,
  CreditCard,
  Home,
  LocalHospital,
  LocalTaxi,
  Repeat,
  Subscriptions,
} from "@mui/icons-material";

const serviceList = [
  { label: "Mobile Recharge", icon: <MobileFriendly />, component: "Mobile Recharge" },
  { label: "Credit Card", icon: <CreditCard />, component: "Credit Card" },
  { label: "Electricity", icon: <Lightbulb />, component: "Electricity" },
  { label: "DTH", icon: <Tv />, component: "DTH" },
  { label: "Broadband", icon: <Router />, component: "Broadband" },
  { label: "Gas Cylinder", icon: <GasMeterRounded />, component: "Gas Cylinder" },
  { label: "Gas (PNG)", icon: <GasMeterRounded />, component: "Gas (PNG)" },
  { label: "Education Fee", icon: <VolunteerActivism />, component: "Education Fee" },
  { label: "Water", icon: <WaterDrop />, component: "Water" },
  { label: "Pay Loans", icon: <Repeat />, component: "Pay Loans" },
  { label: "Landline", icon: <Phone />, component: "Landline" },
  { label: "Rental", icon: <Home />, component: "Rental" },
  { label: "Donation", icon: <VolunteerActivism />, component: "Donation" },
  { label: "FASTag Recharge", icon: <LocalTaxi />, component: "FASTag Recharge" },
  { label: "Insurance", icon: <HealthAndSafety />, component: "Insurance" },
  { label: "Hospital & Pathology", icon: <LocalHospital />, component: "Hospital & Pathology" },
  { label: "Housing Society", icon: <HealthAndSafety />, component: "Housing Society" },
  { label: "Municipal Taxes", icon: <Home />, component: "Municipal Taxes" },
  { label: "Recurring Deposit", icon: <Repeat />, component: "Recurring Deposit" },
  { label: "Subscription", icon: <Subscriptions />, component: "Subscription" },
  { label: "NCMC Recharge", icon: <MobileFriendly />, component: "NCMC Recharge" },
  { label: "National Pension Scheme (NPS)", icon: <Repeat />, component: "National Pension Scheme (NPS)" },
  { label: "Electricity (Prepaid)", icon: <Lightbulb />, component: "Electricity (Prepaid)" },
  { label: "Clubs & Associations", icon: <Lightbulb />, component: "Clubs & Associations" },
];

export default function ServiceTabs({ setSelectedService, selectedService }) {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const index = serviceList.findIndex(
      (service) => service.component === selectedService
    );
    if (index !== -1) {
      setActiveTab(index);
    }
  }, [selectedService]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedService(serviceList[newValue].component);
  };

  return (
    <Box
      sx={{
        borderRadius: "18px 18px 0px 0px",
        borderBottom: 1,
        borderColor: "divider",
        overflowX: "auto",
        backgroundColor: "#027BFE",
        padding: "2rem",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons
        aria-label="service tabs"
        ScrollButtonComponent={({ direction, onClick }) => (
          <IconButton
            onClick={onClick}
            sx={{
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            {direction === "left" ? <ArrowBackIos /> : <ArrowForwardIos />}
          </IconButton>
        )}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "white",
          },
        }}
      >
        {serviceList.map((service, index) => (
          <Tab
            key={index}
            label={service.label}
            icon={React.cloneElement(service.icon, {
              style: {
                color: activeTab === index ? "white" : "rgba(255, 255, 255, 0.6)",
              },
            })}
            sx={{
              color: activeTab === index ? "white" : "rgba(255, 255, 255, 0.6)",
              textTransform: "capitalize",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
