import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReportIcon from '@mui/icons-material/Report';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';  // Import useNavigate hook
import useMediaQuery from '@mui/material/useMediaQuery';
import DescriptionIcon from '@mui/icons-material/Description';
import PolylineIcon from '@mui/icons-material/Polyline';
import { ChangeCircleOutlined, CreateNewFolder, Delete, Download, HowToReg, NoteAddOutlined, QrCode, QrCode2 } from '@mui/icons-material';
import { CreateTicket_Icon, Logout_Icon } from '../../res/icons';
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import { Grid, OutlinedInput } from '@material-ui/core';
import iPaisaLogo from "../Assets/login/iPaisaLogo2.png";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReactComponent as ChargeBackIcon } from '../Assets/Dashboard/ChargeBackIcon.svg';
import { ReactComponent as ChargeBackIconBlack } from '../Assets/Dashboard/ChargeBackIconBlack.svg';
import { BmnemonicIcon } from '../../res/NewWebIcons';

const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: open ? 0 : `-${drawerWidth}px`,
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, styles = { backgroundColor: 'white', textColor: 'black' } }) => ({
    backgroundColor: styles.backgroundColor,
    color: styles.textColor,
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


export default function PersistentDrawerLeft() {
    const theme = useTheme();
    const navigate = useNavigate();  // Initialize useNavigate hook
    const [open, setOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('Dashboard');
    const [expanded, setExpanded] = useState(null);  // Tracks the expanded accordion
    const [anchorEl, setAnchorEl] = useState(null); // For avatar menu
    const [anchorEl2, setAnchorEl2] = useState(null); //
    const openMenu = Boolean(anchorEl); // To determine if menu is open
    const openSetting = Boolean(anchorEl2); // To determine if menu is open
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen
    const userType = sessionStorage.getItem("uType");
    const userName = sessionStorage.getItem('username');
    const [selectedItem, setSelectedItem] = useState(null); // Track selected item
    const [uType, setUType] = useState(sessionStorage.getItem('uType') || null);
    const drawerRef = useRef(null);


    // Fetch userType on initial render
    useEffect(() => {
        const storedUType = sessionStorage.getItem('uType');
        setUType(storedUType);
    }, []);

    // Handle outside click to close the drawer
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const getStylesForUserType = (userType) => {
        switch (userType) {
            case 'ADMIN':
                return { backgroundColor: '#027BFE', textColor: '#FFFFFF' };
            case 'CHANNELPARTNER':
                return { backgroundColor: '#092E1F', textColor: '#FFFFFF' };
            case 'SUPERDISTRIBUTOR':
                return { backgroundColor: '#8F00FF', textColor: '#FFFFFF' };
            case 'MASTERDISTRIBUTOR':
                return { backgroundColor: '#B25AEF', textColor: '#FFFFFF' };
            case 'AREADISTRIBUTOR':
                return { backgroundColor: '#FF7B31', textColor: '#FFFFFF' };
            case 'RETAILER':
                return { backgroundColor: '#00DDE5', textColor: '#000000' };
            default:
                return { backgroundColor: '#f0f0f0', textColor: '#000000' };
        }
    };

    const { backgroundColor, textColor } = getStylesForUserType(uType);

    const handleItemClick = (itemName) => {
        setSelectedItem(itemName);
        navigate('/dashboard')
        handleDrawerClose(); // Close the drawer

        // Your additional click logic here
    };
    const pdfFiles = {
        CHANNELPARTNER: 'AgreementCP.pdf',
        SUPERDISTRIBUTOR: 'AgreementSD.pdf',
        MASTERDISTRIBUTOR: 'AgreementMD.pdf',
        AREADISTRIBUTOR: 'AgreementAD.pdf',
        RETAILER: 'AgreementRetailer.pdf',
    };

    const handleDownloadAgreement = () => {
        const pdfUrl = pdfFiles[userType];

        if (pdfUrl) {
            // Create an anchor element and trigger the download
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `${userType}_Agreement.pdf`; // Optional: set a custom filename
            link.click();
        } else {
            console.error('No PDF available for this user type');
        }
    };


    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget); // Set the menu anchor
    };

    const handleMenuClose = () => {
        setAnchorEl(null); // Close the menu
    };

    const handleSettingsOpen = (event) => {
        setAnchorEl2(event.currentTarget); // Set the menu anchor
    };

    const handleSettingsClose = () => {
        setAnchorEl2(null); // Close the menu
    };


    const handleDashboardClick = () => {
        navigate('/dashboardfinal'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleDeletedUsersClick = () => {
        navigate('/deletedusertable'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleCreateTicketClick = () => {
        navigate('/createticket'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handlePaymentReqClick = () => {
        navigate('/requestpaymentissue'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleChangeParentClick = () => {
        navigate('/changeparent'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleKycVerifyClick = () => {
        navigate('/kycverification'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleQueenQRClick = () => {
        navigate('/applyqr'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleKingQRClick = () => {
        navigate('/razorpayrequestlist'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };
    const handleAllQueenListClick = () => {
        navigate('/vlist'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };
    const handleAllKingListClick = () => {
        navigate('/allrazorqrlist'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleLogout = () => {
        navigate('/SignIn'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
        sessionStorage.clear();
    };

    const handlePartnerClick = (partnerType) => {
        switch (partnerType) {
            case 'Channel Partner':
                navigate('/cplist');  // Navigate to '/cplist' when "Channel Partner" is clicked
                break;
            case 'Super Distributor':
                navigate('/sdlist'); // Navigate to appropriate route for Super Distributor
                break;
            case 'Master Distributor':
                navigate('/mdlist'); // Navigate to appropriate route for Master Distributor
                break;
            case 'Area Distributor':
                navigate('/adlist'); // Navigate to appropriate route for Area Distributor
                break;
            case 'Retailer':
                navigate('/retailer'); // Navigate to appropriate route for Retailer
                break;
            case 'Tech Support':
                navigate('/techsupport'); // Navigate to appropriate route for Tech Support
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };
    const handleBalanceClick = (balanceType) => {
        switch (balanceType) {
            case 'Fund Transfer':
                navigate('/fundtransfer');
                break;
            case 'Reverse Money':
                navigate('/reversemoney');
                break;
            case 'Direct FTR':
                navigate('/directftr');
                break;
            case 'Payout Checker':
                navigate('/payoutchecker');
                break;
            case 'Deeplink Client Reg':
                navigate('/DeepLinkClientReg');
                break;
            case 'PayIn Services':
                navigate('/payinservices');
                break;
            case 'Bank Charges':
                navigate('/bankcharges');
                break;
            case 'Beneficiary Charges':
                navigate('/benecharges');
                break;
            case 'Card Charges':
                navigate('/cardcharges');
                break;
            case 'User Services Management':
                navigate('/userservicemanagement');
                break;
            case 'Add Money':
                navigate('/addmoney');
                break;
            case 'Payout':
                navigate('/payout');
                break;
            case 'Add Beneficiary':
                navigate('/addBeneficiary');
                break;

            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };

    const handleServicesClick = (servicesType) => {
        switch (servicesType) {
            case 'Pay Bills':
                navigate('/PayBills');
                break;
            case 'Book and Buy':
                navigate('/bookandbuy');
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };

    const handleReportClick = (reportType) => {
        switch (reportType) {
            case 'PayIn':
                navigate('/payinreport');
                break;
            case 'PayOut':
                navigate('/payoutreport');
                break;
            case 'CompletedTrans':
                navigate('/CompletedTransactions');
                break;
            case 'Queen QR Trans':
                navigate('/virtualtrans');
                break;
            case 'King QR Trans':
                navigate('/kingqrtrans');
                break;
            case 'CC Bill Payments':
                navigate('/ccbilllist');
                break;
            case 'CC Hierarchy Reports':
                navigate('/hwiseccbilllist');
                break;
            case 'Fund Transfer':
                navigate('/FundTransferReports');
                break;
            case 'Reverse Money':
                navigate('/ReverseMoneyReports');
                break;
            case 'Commission':
                navigate('/commissionreport');
                break;
            case 'UserCommission':
                navigate('/PartnerCommissionReports');
                break;
            case 'Hierarchy Wise':
                navigate('/userdropdown');
                break;
            case 'Pending':
                navigate('/pendingreports');
                break;
            case 'Total Payouts':
                navigate('/TotalPayout');
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };

    const handleChargeBack = (chargeBack) => {
        switch (chargeBack) {
            case 'Charge Back Report':
                navigate('/ChargeBackReports');
                break;
            case 'Charge Back Allotement':
                navigate('/ChargeBackAllotement');
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };

    const handleSubAdmin = (subAdmin) => {
        switch (subAdmin) {
            case 'Add Sub Admin':
                navigate('/AddSubAdmin');
                break;
            case 'Sub Admin List':
                navigate('/SubAdminList');
                break;
            case 'Update Sub Admin':
                navigate('/UpdateSubAdmin');
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };

    const handleRetailerChargeBack = (retailerChargeBack) => {
        switch (retailerChargeBack) {
            case 'Retailer Charge Back Report':
                navigate('/ChargeBackReports');
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };

    const handleSettingClick = (settingtype) => {
        switch (settingtype) {
            case 'IPin Setting':
                navigate('/mpinlanding');
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };
    const handleQRCodeOpen = () => {
        handleMenuClose();
        navigate("/qrcode");
    };
    const handleRazorQRCodeOpen = () => {
        handleMenuClose();
        navigate("/razorpayqrcode");
    };
    const handleUserProfileView = () => {
        handleMenuClose();
        navigate("/userprofile");
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar style={{ boxShadow: 'none', backgroundColor, color: textColor }} position="fixed" open={open}>
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Left Section */}
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton

                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            style={{ mr: 2, ...(open && { display: 'none' }), color: textColor }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/* iPaisa Logo */}
                        {iPaisaLogo && (
                            <Avatar
                                alt="Profile"
                                src={iPaisaLogo} // Dynamic image source
                                style={{
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px',
                                    marginRight: '8px',
                                    border:
                                        userType === "RETAILER" ?
                                            '2px solid #FFFFFF' :
                                            'none', // Ensures no border is applied
                                    borderRadius: '50%', // Ensures proper circular shape
                                }}
                            />
                        )}

                        {/* iPaisa Text */}
                        <Typography
                            noWrap
                            component="div"
                            style={{
                                flexGrow: 1,
                                color: textColor,
                                fontSize: '18px',
                                fontWeight: 'bold',
                                marginRight: '16px',
                            }}
                        >
                            iPaisa
                        </Typography>
                    </Grid>

                    {/* Right Section */}
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                        {/* User Details */}
                        <Grid style={{ textAlign: 'right', marginRight: '16px' }}>
                            <Typography
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: textColor,
                                }}
                            >
                                {userName}
                            </Typography>
                            {/* <Typography style={{ fontSize: '12px', }}> {userType} </Typography> */}
                        </Grid>
                        {/* User Avatar */}
                        <Avatar
                            alt={userName ? userName.charAt(0).toUpperCase() : 'P'} // Use the first letter of userName or 'U' as fallback
                            src="/static/images/avatar/1.jpg" // Dynamic image source
                            onClick={handleMenuOpen}
                            style={{ cursor: 'pointer' }}
                        />
                    </Grid>
                </Toolbar>

                <Menu
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleUserProfileView}>My Profile</MenuItem>
                    <MenuItem onClick={handleQRCodeOpen}>Queen QR Code</MenuItem>
                    <MenuItem onClick={handleRazorQRCodeOpen}>King QR Code</MenuItem>
                    <MenuItem onClick={handleLogout} style={{ color: 'red' }}>Logout</MenuItem>

                    <Divider />
                    <Grid style={{ display: 'flex', justifyContent: 'center', padding: '8px 0' }}>
                        <Avatar
                            alt="iPaisa Logo"
                            src={iPaisaLogo} // Dynamic image source
                            style={{ cursor: 'pointer', width: '50px', height: '50px' }}
                        />
                    </Grid>
                </Menu>
            </AppBar>

            <Drawer
                ref={drawerRef}
                sx={{
                    width: isMobile ? 250 : 0,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isMobile ? 250 : drawerWidth,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: 'none',
                        backgroundColor,
                        color: textColor,
                        // Scrollbar customization
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: userType === 'SUBADMIN' ? '#000000' : '#FFFFFF', // Conditional color
                            borderRadius: '12px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: userType === 'SUBADMIN' ? '#000000' : '#FFFFFF', // Conditional hover color
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'none', // Scrollbar track color
                        },
                    },
                }}

                variant={isMobile ? "temporary" : "persistent"}
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
            >
                <DrawerHeader>
                    <IconButton sx={{ color: textColor }} onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List style={{ backgroundColor, color: textColor }}>
                    <ListItemButton
                        onClick={() => handleItemClick('Dashboard')}
                        style={{
                            backgroundColor,
                            color: textColor,
                            // bgcolor: selectedItem === 'Dashboard' ? 'primary.light' : 'transparent', // Selected color
                            // ':hover': {
                            //     bgcolor: 'primary.main', // Hover color
                            //     color: 'white',
                            // },
                            // color: selectedItem === 'Dashboard' ? 'white' : 'inherit', // Text color on select
                        }}
                    >
                        <ListItemIcon>
                            <DashboardIcon style={{ backgroundColor, color: textColor }} />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>

                    {/* Partner Section Accordion */}

                    {uType !== 'RETAILER' && userType !== 'SUBADMIN' && (

                        <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')} style={{ backgroundColor, color: textColor, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: textColor }} />}>
                                <Grid style={{ alignItems: 'center', display: 'flex' }}>
                                    <ListItemIcon>
                                        <GroupsIcon style={{ backgroundColor, color: textColor }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Partner" />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                {uType === 'ADMIN' && (
                                    <>
                                        <ListItemButton onClick={() => handlePartnerClick('Channel Partner')}>
                                            <ListItemText primary="Channel Partner" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Super Distributor')}>
                                            <ListItemText primary="Super Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Master Distributor')}>
                                            <ListItemText primary="Master Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                            <ListItemText primary="Area Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                            <ListItemText primary="Retailer" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Tech Support')}>
                                            <ListItemText primary="Tech Support" />
                                        </ListItemButton>
                                    </>
                                )}
                                {/* Channel Partner: Show Super Distributor, Master Distributor, Area Distributor, Retailer */}
                                {uType === 'CHANNELPARTNER' && (
                                    <>
                                        <ListItemButton onClick={() => handlePartnerClick('Super Distributor')}>
                                            <ListItemText primary="Super Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Master Distributor')}>
                                            <ListItemText primary="Master Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                            <ListItemText primary="Area Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                            <ListItemText primary="Retailer" />
                                        </ListItemButton>
                                    </>
                                )}

                                {/* Super Distributor: Show Master Distributor, Area Distributor, Retailer */}
                                {uType === 'SUPERDISTRIBUTOR' && (
                                    <>
                                        <ListItemButton onClick={() => handlePartnerClick('Master Distributor')}>
                                            <ListItemText primary="Master Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                            <ListItemText primary="Area Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                            <ListItemText primary="Retailer" />
                                        </ListItemButton>
                                    </>
                                )}

                                {/* Master Distributor: Show Area Distributor, Retailer */}
                                {uType === 'MASTERDISTRIBUTOR' && (
                                    <>
                                        <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                            <ListItemText primary="Area Distributor" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                            <ListItemText primary="Retailer" />
                                        </ListItemButton>
                                    </>
                                )}

                                {/* Area Distributor: Show Retailer */}
                                {uType === 'AREADISTRIBUTOR' && (
                                    <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                        <ListItemText primary="Retailer" />
                                    </ListItemButton>
                                )}
                            </AccordionDetails>

                        </Accordion>

                    )}

                    {/* PayBills Section Accordion */}
                    {uType === 'RETAILER' && (
                        <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')} style={{ backgroundColor, color: textColor, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: textColor }} />}>
                                <Grid style={{ alignItems: 'center', display: 'flex' }}>
                                    <ListItemIcon>
                                        <BmnemonicIcon style={{ backgroundColor, color: textColor }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Pay Bills" />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ListItemButton onClick={() => handleServicesClick('Pay Bills')}>
                                    <ListItemText primary="Pay Bills" />
                                </ListItemButton>
                                <ListItemButton onClick={() => handleServicesClick('Book and Buy')}>
                                    <ListItemText primary="Book and Buy" />
                                </ListItemButton>
                            </AccordionDetails>
                        </Accordion>
                    )}


                    {/* Balance Section Accordion */}

                    {uType !== "SUBADMIN" && (
                        <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')} style={{ backgroundColor, color: textColor, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: textColor }} />}>
                                <Grid style={{ alignItems: 'center', display: 'flex' }}>
                                    <ListItemIcon>
                                        <AccountBalanceIcon style={{ backgroundColor, color: textColor }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Balance" />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                {userType !== 'RETAILER' && (

                                    <ListItemButton onClick={() => handleBalanceClick('Fund Transfer')}>
                                        <ListItemText primary="Fund Transfer" />
                                    </ListItemButton>
                                )}
                                {/* Conditionally render based on userType */}
                                {userType === 'ADMIN' && (
                                    <>
                                        <ListItemButton onClick={() => handleBalanceClick('Reverse Money')}>
                                            <ListItemText primary="Reverse Money" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('Direct FTR')}>
                                            <ListItemText primary="Direct FTR" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('Payout Checker')}>
                                            <ListItemText primary="Payout Checker" />
                                        </ListItemButton>
                                        {/* <ListItemButton onClick={() => handleBalanceClick('Deeplink Client Reg')}>
                                        <ListItemText primary="Deeplink Client Reg" />
                                    </ListItemButton> */}
                                        <ListItemButton onClick={() => handleBalanceClick('PayIn Services')}>
                                            <ListItemText primary="PayIn & PayOut Services" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('Bank Charges')}>
                                            <ListItemText primary="Bank Charges" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('Beneficiary Charges')}>
                                            <ListItemText primary="Beneficiary Charges" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('Card Charges')}>
                                            <ListItemText primary="Card Charges" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('User Services Management')}>
                                            <ListItemText primary="User Services Management" />
                                        </ListItemButton>
                                    </>
                                )}

                                {(userType === 'AREADISTRIBUTOR' || userType === 'RETAILER') && (
                                    <>
                                        <ListItemButton onClick={() => handleBalanceClick('Add Money')}>
                                            <ListItemText primary="Add Money" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('Payout')}>
                                            <ListItemText primary="Payout" />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleBalanceClick('Add Beneficiary')}>
                                            <ListItemText primary="Add Beneficiary" />
                                        </ListItemButton>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )}



                    {/* Report Section Accordion */}
                    <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleAccordionChange("panel4")}
                        style={{ backgroundColor, color: textColor, boxShadow: "none" }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: textColor }} />}>
                            <Grid style={{ alignItems: "center", display: "flex" }}>
                                <ListItemIcon>
                                    <DescriptionIcon style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="Reports" />
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/** Render reports only if userType is SUBADMIN */}
                            {userType === "SUBADMIN" &&
                                [
                                    { key: "FUND TRANSFER REPORT", label: "Fund Transfer" },
                                    { key: "Credit Card Report", label: "CC Hierarchy Reports" },
                                    { key: "Completed Transaction Report", label: "CompletedTrans" },
                                    { key: "Reverse Money Report", label: "Reverse Money" },
                                    { key: "Commission Report", label: "Commission" },
                                    { key: "Hierarchy Wise Report", label: "Hierarchy Wise" },
                                    { key: "Pending Report", label: "Pending" },
                                    { key: "Total Payout Report", label: "Total Payouts" },
                                    { key: "Queen QR Trans Report", label: "Queen QR Trans" },
                                    { key: "King QR Trans Report", label: "King QR Trans" },
                                    { key: "PayIn Report", label: "PayIn" },
                                    { key: "PayOut Report", label: "Payouts" },
                                ]
                                    .filter((report) => sessionStorage.getItem(report.key) === "true")
                                    .map((report) => (
                                        <ListItemButton key={report.key} onClick={() => handleReportClick(report.label)}>
                                            <ListItemText primary={report.key} />
                                        </ListItemButton>
                                    ))}

                            {/** Render other user-specific reports */}
                            {userType !== "SUBADMIN" && (
                                <>
                                    {userType === "RETAILER" ? (
                                        <ListItemButton onClick={() => handleReportClick("CC Bill Payments")}>
                                            <ListItemText primary="CC Bill Payments" />
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton onClick={() => handleReportClick("CC Hierarchy Reports")}>
                                            <ListItemText primary="CC Hierarchy Reports" />
                                        </ListItemButton>
                                    )}

                                    <ListItemButton onClick={() => handleReportClick("Queen QR Trans")}>
                                        <ListItemText primary="Queen QR Trans Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick("King QR Trans")}>
                                        <ListItemText primary="King QR Trans Report" />
                                    </ListItemButton>

                                    <ListItemButton onClick={() => handleReportClick("Fund Transfer")}>
                                        <ListItemText primary="Fund Transfer Report" />
                                    </ListItemButton>
                                    {(userType === "CHANNELPARTNER" || userType === "SUPERDISTRIBUTOR" || userType === "MASTERDISTRIBUTOR" || userType === "AREADISTRIBUTOR") && (
                                        <ListItemButton onClick={() => handleReportClick("UserCommission")}>
                                            <ListItemText primary="Commission Reports" />
                                        </ListItemButton>
                                    )}


                                    {userType !== "RETAILER" || userType !== "ADMIN" && (
                                        <ListItemButton onClick={() => handleReportClick("UserCommission")}>
                                            <ListItemText primary="Commission Reports" />
                                        </ListItemButton>
                                    )}
                                    {userType === "ADMIN" && (
                                        <>
                                            <ListItemButton onClick={() => handleReportClick("CompletedTrans")}>
                                                <ListItemText primary="Completed Trans Report" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleReportClick("Reverse Money")}>
                                                <ListItemText primary="Reverse Money Report" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleReportClick("Commission")}>
                                                <ListItemText primary="Commission Report" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleReportClick("Hierarchy Wise")}>
                                                <ListItemText primary="Hierarchy Wise Report" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleReportClick("Pending")}>
                                                <ListItemText primary="Pending Report" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleReportClick("Total Payouts")}>
                                                <ListItemText primary="Total Payouts" />
                                            </ListItemButton>
                                        </>
                                    )}

                                    {["AREADISTRIBUTOR", "RETAILER"].includes(userType) && (
                                        <>
                                            <ListItemButton onClick={() => handleReportClick("PayIn")}>
                                                <ListItemText primary="PayIn Report" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleReportClick("PayOut")}>
                                                <ListItemText primary="Payout Report" />
                                            </ListItemButton>
                                        </>
                                    )}
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>


                    {(userType === 'ADMIN') && (
                        <>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')} style={{ backgroundColor, color: textColor, boxShadow: 'none' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: textColor }} />}>
                                    <Grid style={{ alignItems: 'center', display: 'flex' }}>
                                        <ListItemIcon>
                                            <GroupIcon style={{ backgroundColor, color: textColor }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Sub Admin" />
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ListItemButton onClick={() => handleSubAdmin('Add Sub Admin')}>
                                        <ListItemText primary="Add Sub Admin" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleSubAdmin('Sub Admin List')}>
                                        <ListItemText primary="Sub Admin List" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleSubAdmin('Update Sub Admin')}>
                                        <ListItemText primary="Update Sub Admin" />
                                    </ListItemButton>
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </>
                    )}

                    {/* Charge Back Section Accordion */}
                    {userType !== 'ADMIN' && userType !== 'SUBADMIN' && (
                        <>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')} style={{ backgroundColor, color: textColor, boxShadow: 'none' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: textColor }} />}>
                                    <Grid style={{ alignItems: 'center', display: 'flex' }}>
                                        <ListItemIcon>
                                            {userType === "RETAILER" ? <ChargeBackIconBlack /> : <ChargeBackIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary="Charge Back" />
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {userType !== 'ADMIN' && (
                                        <>
                                            <ListItemButton onClick={() => handleChargeBack('Charge Back Report')}>
                                                <ListItemText primary="Charge Back Report" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleChargeBack('Charge Back Allotement')}>
                                                <ListItemText primary="Charge Back Allotement" />
                                            </ListItemButton>
                                        </>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </>
                    )}


                    {userType !== 'ADMIN' && userType !== 'SUBADMIN' && (
                        <>
                            <ListItemButton onClick={handleDownloadAgreement}>
                                <ListItemIcon>
                                    <Download style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="Download Agreement" />
                            </ListItemButton>
                            <ListItemButton onClick={handlePaymentReqClick}>
                                <ListItemIcon>
                                    <CurrencyRupeeIcon style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="Payment Request" />
                            </ListItemButton>
                        </>
                    )}

                    {userType !== 'SUBADMIN' && (
                        <ListItemButton onClick={handleKycVerifyClick}>
                            <ListItemIcon>
                                <HowToReg style={{ backgroundColor, color: textColor }} />
                            </ListItemIcon>
                            <ListItemText primary="KYC Verification" />
                        </ListItemButton>
                    )}
                    {userType === 'ADMIN' && (
                        <>
                            <ListItemButton onClick={handleChangeParentClick}>
                                <ListItemIcon>
                                    <ChangeCircleOutlined style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="Change Parent" />
                            </ListItemButton>

                            <ListItemButton onClick={handleQueenQRClick}>
                                <ListItemIcon>
                                    <QrCode2 style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="Queen QR Requests" />
                            </ListItemButton>

                            <ListItemButton onClick={handleKingQRClick}>
                                <ListItemIcon>
                                    <QrCode style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="King QR Requests" />
                            </ListItemButton>

                            <ListItemButton onClick={handleAllQueenListClick}>
                                <ListItemIcon>
                                    <QrCode2 style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="All Queen QR List" />
                            </ListItemButton>

                            <ListItemButton onClick={handleAllKingListClick}>
                                <ListItemIcon>
                                    <QrCode style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="All King QR List" />
                            </ListItemButton>

                            <ListItemButton onClick={handleDeletedUsersClick}>
                                <ListItemIcon>
                                    <Delete style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="Deleted Users" />
                            </ListItemButton>
                        </>
                    )}

                    {userType !== 'SUBADMIN' && (
                        <ListItemButton onClick={handleCreateTicketClick}>
                            <ListItemIcon>
                                <CreateNewFolder style={{ backgroundColor, color: textColor }} />
                            </ListItemIcon>
                            <ListItemText primary="Create Ticket" />
                        </ListItemButton>
                    )}

                    <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')} style={{ backgroundColor, color: textColor, boxShadow: 'none' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: textColor }} />}>
                            <Grid style={{ alignItems: 'center', display: 'flex' }}>
                                <ListItemIcon>
                                    <SettingsIcon style={{ backgroundColor, color: textColor }} />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton onClick={() => handleSettingClick('IPin Setting')}>
                                <ListItemText primary="IPin Setting" />
                            </ListItemButton>
                            {/* <ListItemButton onClick={() => handleReportClick('General Setting')}>
                                <ListItemText primary="General Setting" />
                            </ListItemButton>
                            <ListItemButton onClick={() => handleReportClick('Blogs Updater')}>
                                <ListItemText primary="Blogs Updater" />
                            </ListItemButton>
                            <ListItemButton onClick={() => handleReportClick('Send SMS')}>
                                <ListItemText primary="Send SMS" />
                            </ListItemButton> */}

                        </AccordionDetails>
                    </Accordion>
                    <Divider />

                    <ListItemButton
                        onClick={handleLogout} // Define handleLogout to log out the user
                        sx={{
                            mt: 2, // Top margin for separation
                            mb: 2, // Optional bottom margin for extra spacing
                            bgcolor: 'error.main', // Optional: background color to highlight the logout button
                            color: 'white', // White text for contrast
                            ':hover': {
                                bgcolor: 'error.dark', // Darker color on hover
                            },
                        }}
                    >
                        <ListItemIcon>
                            <Logout_Icon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </List>
                <Box sx={{ flexGrow: 1 }} />

                {/* Logout button styled separately at the bottom */}

            </Drawer>
        </Box>
    );
}

