import { Button, Container, Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination, DialogTitle, DialogContent, Dialog, DialogActions } from '@mui/material'
import React, {useState} from 'react'
import { AddUserIcon, PartnerArrowIcon } from '../../res/NewWebIcons'
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { DeleteUserIcon, ViewDetailsIcon } from '../../res/NewWebIcons';
import axios from "axios";
import { toast } from "react-toastify";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  evenRow: {
      backgroundColor: '#D0EFFF',
  },
  oddRow: {
      backgroundColor: '#FFFFFF',
  }
}));

const initialData = [
    {
      srNo: 1,
      date: "25-06-2024",
      blogtitle: "Funday",
      blogby: "Tripathi",
      status: "Active",
    },
    {
      srNo: 2,
      date: "25-06-2024",
      blogtitle: "Funday",
      blogby: "Jane Doe",
      status: "Inactive",
    },
  ];



const NewBlogUpdater = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState(initialData);
    const navigate = useNavigate();

    const handleAddBlogsClick = () => {
        navigate("/blogsuploader");
      };

 
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
      const handleClickOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
      };
    
      const handleEditOpen = (row) => {
        setEditRow(row);
        setEditOpen(true);
      };
    
      const handleEditClose = () => {
        setEditOpen(false);
        setEditRow(null);
      };
    
      const handleInputChange = (event) => {
        // const { name, value } = event.target;
        // if (editOpen) {
        //   setEditRow({ ...editRow, [name]: value });
        // } else {
        //   setNewUser({ ...newUser, [name]: value });
        // }
      };
    
      const handleDelete = async (cpId) => {
        try {
          const token = sessionStorage.getItem("jwtToken");
          // Make an API call to delete the user with the given cpId
          await axios.delete(`/api/users/${cpId}`, {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          });
    
          // Remove the deleted row from the local data state
          const newData = data.filter((row) => row.cpId !== cpId);
          setData(newData);
    
          toast.success("User deleted successfully");
        } catch (error) {
          console.error("Error deleting user:", error);
          toast.error("Error deleting user. Please try again later.");
        }
      };
    
      const filteredData = data.filter((row) => {
        return Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Grid>
            <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Settings</Typography>
                <PartnerArrowIcon/>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Blog Updater</Typography>
            </Grid>

            <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
                <Grid>
                <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'22px', color:'#343C6A'}}>Blog Updater</Typography>
                </Grid>
            
                <Grid style={{display:'flex', gap:'5%'}}>
                <Grid style={{
                    display:'flex',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    maxWidth: '60%',
                    borderRadius: 10,
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    }}>

                <IconButton>
                    <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
                </IconButton>
                <InputBase
                    style={{ color: 'rgba(0, 0, 0, 3)' }}
                    placeholder="Search"
                    sx={{ width: '100%'}}
                    value={searchQuery}
                    onChange={handleInputChange}
                />
                </Grid>
                    <Button style={{textTransform:'none', backgroundColor:'#027BFE', color:'#FFFFFF', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', paddingRight:'5%', fontFamily:'sans-serif'}} onClick={handleAddBlogsClick}
                    >
                    <AddUserIcon/>
                    Add User</Button>
                </Grid>
                
            </Grid>
        </Grid>

        <Grid style={{marginTop:'3%'}}>
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
          <Table>
            <TableHead style={{ backgroundColor: '#027BFE'}}>
              <TableRow>
                <TableCell style={{color:'white'}}>Sr.No</TableCell>
                <TableCell style={{color:'white'}}>Date</TableCell>
                <TableCell style={{color:'white'}}>Blog Title</TableCell>
                <TableCell style={{color:'white'}}>Blog By</TableCell>
                <TableCell style={{color:'white', textAlign:'center'}}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const shouldDisplayRow = Object.values(row).some((value) =>
                    value
                      .toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  );

                  if (!shouldDisplayRow) {
                    return null;
                  }

                  return (
                    <TableRow key={index}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                      <TableCell>{row.srNo}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.blogtitle}</TableCell>
                      <TableCell>{row.blogby}</TableCell>
                      <TableCell style={{textAlign:'center'}}>
                        <IconButton
                          color="primary"
                          aria-label="view"
                          onClick={() => handleClickOpen(row)}
                        >
                          <ViewDetailsIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          onClick={() => handleDelete(row.cpId)}
                        >
                          <DeleteUserIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent="flex-end">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        </Grid>
        <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle bgcolor={"#D1C4E9"} strong>
          User Details
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              marginTop={5}
              borderRadius={10}
              border={1}
              boxShadow={3}
            >
              {selectedRow && (
                <div>
                  <p>
                    <strong>CP ID:</strong> {selectedRow.cpId}
                  </p>
                  <p>
                    <strong>Parent ID:</strong> {selectedRow.parentId}
                  </p>
                  <p>
                    <strong>Name:</strong> {selectedRow.name}
                  </p>
                  <p>
                    <strong>Business Name:</strong> {selectedRow.businessName}
                  </p>
                  <p>
                    <strong>Mobile Number:</strong> {selectedRow.mobileNumber}
                  </p>
                  <p>
                    <strong>DOB:</strong> {"17-07-1998"}
                  </p>
                  <p>
                    <strong>Address:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>City:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>State:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>Pincode:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>Wallet Balance:</strong> {selectedRow.walletBalance}
                  </p>
                  <p>
                    <strong>Status:</strong> {selectedRow.status}
                  </p>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <p>
                <strong>Uploaded Agreement:</strong>
              </p>
              <iframe
                src={`${process.env.PUBLIC_URL}/IpaisaApplication.pdf`}
                width="100%"
                height="400px"
                style={{ border: "none" }}
                title="PDF Document"
              ></iframe>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default NewBlogUpdater
