import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Download, FilterAlt } from "@mui/icons-material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import * as writeXLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const CreditHierarchyPays = () => {
  const [trnsList, setTranslist] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currPage, setCurrentPage] = useState(0); // Current page
  const [isFirstPage, setFirstPage] = useState(true);
  const [isLastPage, setLastPage] = useState(true);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null); // For filter icon menu
  const [availableUserTypes, setAvailableUserTypes] = useState([]);
  const [allavailableUsers, setUsers] = useState([]);
  const [userType, setUserType] = useState("");
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [openDownloadWindow, setOpenDownloadWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [filters, setFilters] = useState({
    startDate: dayjs().startOf("day").format("YYYY-MM-DDTHH:mm"), // Default from night
    endDate: dayjs().format("YYYY-MM-DDTHH:mm"), // Default to today
  }); // Filter states
  var totalAmount = 0;
  const classes = useStyles();
  const navigate = useNavigate();

  const statusColors = {
    received: "#67C529",
    pending: "#E8DC22",
    uncaptured: "#E8DC22",
    "timed out": "#E29D95",
    failure: "#FF0000",
    unsettled: "#E8DC22",
    "partially refunded": "#3D8AC5",
    refunded: "#655AA1",
  };

  //Handle for usertype selection
  const handleUserChange = (event) => {
    // setUserType(event.target.value);
    const selectedUser = event.target.value;
    console.log(selectedUser.userid);
    // setSelectedUserId(null);
    setFilters((prevFilters) => ({
      ...prevFilters,
      uid: selectedUser.userid,
    }));
  };

  const handleClickOpenDownloadWindow = () => {
    setOpenDownloadWindow(true);
  };

  const handleCloseDownloadWindow = () => {
    console.log("close window clicked");
    setOpenDownloadWindow(false);
  };

  const onClickGetList = () => {
    fetchTransList(0);
  };
  //Handle for usertype selection
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    // setSelectedUserId(null);
  };

  // Handle filter menu open/close
  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchTransList(newPage);
  };
  // Separate function to handle DatePicker change
  const handleDateChange = (key, newValue) => {
    let dateWithTime = newValue;

    if (newValue && !newValue.hour() && !newValue.minute()) {
      // Set default time to 12:00 AM if only date is selected
      dateWithTime = newValue.set("hour", 0).set("minute", 0);
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: dateWithTime ? dayjs(dateWithTime).format("YYYY-MM-DDTHH:mm") : "",
    }));
  };

  // Function to handle filter changes for text inputs
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  // Apply filters and call the fetchList function with page 1
  const applyFilters = () => {
    handleFilterMenuClose();
    if (
      filters.endDate &&
      filters.startDate &&
      dayjs(filters.endDate).isBefore(dayjs(filters.startDate))
    ) {
      // toast.error("End Date cannot be less than Start Date.");
      Swal.fire({
        title: "Something Went Wrong..!!",
        text: "End Date cannot be less than Start Date.",
        icon: "error",
      });
      return;
    } else {
      // setErrorMessage(""); // Clear error if validation passes
      fetchList(1); // Trigger fetch with the current filters
    }
    // console.log("Filters applied:", filters); // Debugging
  };

  const fetchList = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    // console.log(filteredTransactions + " this is filter");

    try {
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/creditcard/husers",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      //   console.log(response);
      const resultsArr = response.data;
      setUsers(resultsArr);
      // console.log(resultsArr);
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };

  //   const Dropdown = ({ users, onUserSelect }) => {
  //     const [selectedUser, setSelectedUser] = useState('');

  //     const handleChange = (event) => {
  //       const userId = event.target.value;
  //       setSelectedUser(userId);
  //       onUserSelect(userId); // Call parent function to notify about selected user
  //     };
  const fetchTransList = async (page) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    // console.log(params);
    try {
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/creditcard/hreports",
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            currentPage: page,
            pageSize: 50,
            userid: filters.uid || ``,
          },
        }
      );
      // console.log(response.data);
      const resultsArr = response.data.content;
      setTranslist(resultsArr);
      setTotalPages(response.data.totalPages);
      setFirstPage(response.data.first);
      setLastPage(response.data.last);
      // console.log(resultsArr);
      return resultsArr;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDownload = async () => {
    console.log("download clicked");
    setLoading(true);
    setTriggerDownload(true);
  };

  const listDownload = async () => {
    console.log("In List download");
    // setPageSize(1000);
    // setPageSize(1000);

    try {
      // Assuming fetchList is asynchronous and returns the data

      const response = await fetchTransList(1);
      //   console.log("thisis resp", response);
      const dataList = response;

      if (!dataList || !dataList.length) {
        console.error("No data available to download.");
        handleCloseDownloadWindow();
        Swal.fire({
          title: "Transactions Report",
          text: "There is no Transactions",
          icon: "info",
          timer: 2000,
        });
        return;
      }

      // Format the data for Excel (select necessary fields)
      const formattedData = dataList.map((transaction) => ({
        TransactionID: transaction.id,
        Status: transaction.status,
        transaction_date: transaction.transaction_date,
        CreatedAt: transaction.created_at,
        PaymentMode: transaction.payment_mode,
        Narration: transaction.narration,
        UTR: transaction.unique_transaction_reference,
        RemitterName: transaction.remitter_full_name,
        RemitterUPI: transaction.remitter_upi_handle,
        Amount: transaction.amount,
        VirtualAccount: transaction.virtual_account.virtual_account_number,
        VirtualAccountUPI: transaction.virtual_account.virtual_upi_handle,
      }));

      // Create a worksheet from the data
      const worksheet = writeXLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and add the worksheet
      const workbook = writeXLSX.utils.book_new();
      writeXLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

      // Create and download the Excel file
      writeXLSX.writeFile(workbook, "UPI_Transactions_Report.xlsx");
      setLoading(false);
      handleCloseDownloadWindow();
      window.location.reload();
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  useEffect(() => {
    fetchList();
    if (triggerDownload) {
      console.log("in if trigger Download");
      listDownload();
    }
    const uType = sessionStorage.getItem("uType");
    setAvailableUserTypes(["RETAILER"]);
  }, [triggerDownload]);


  const handleClear = () => {
    setUserType('');
    setTranslist([]);
    // setFilteredUsers(users); // Reset the filtered list
  };

  return (
    <>
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Typography sx={{fontSize:'22px', fontWeight:'bold', marginBottom:'1%'}}>This is Hierarchy-wise Credit Card Bill Pay List </Typography>
        <Paper style={{ boxShadow:'none', padding: '2rem', borderRadius: '10px', boxShadow:'none' }}>
          <Grid2
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid2 size={{ md: 6 }}>
              <FormControl fullWidth>
                <InputLabel id="user-type-label">Select User Type</InputLabel>
                <Select
                  value={userType}
                  sx={{ width: "100%" }}
                  label="Select User Type"
                  labelId="user-type-label"
                  onChange={handleUserTypeChange}
                >
                  {availableUserTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 6 }}>
              <FormControl fullWidth>
                <InputLabel id="user-label">Select User</InputLabel>
                <Select
                  //   value={userType}
                  sx={{ width: "100%" }}
                  label="Select User"
                  labelId="user-label"
                  onChange={handleUserChange}
                >
                  {userType ? (
                    allavailableUsers.filter((user) => user.userRole === userType)
                      .length > 0 ? (
                      allavailableUsers
                        .filter((user) => user.userRole === userType)
                        .map((user) => (
                          <MenuItem key={user} value={user}>
                            {user.userName}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem disabled>No users found</MenuItem>
                    )
                  ) : (
                    <MenuItem disabled>Please select a user type first</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid2>

            <Grid2 sx={{display:'flex', justifyContent:'flex-end', gap:'1%', marginTop:'2%'}} size={{ md: 12 }}>
              <LoadingButton
                onClick={handleClear}
                sx={{
                  height: '46px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  backgroundColor:'gray',
                  color: 'white',
                  textTransform: 'none',
                  width: { xs: '100%', md: '16%' },
              }}
              disabled={loading}
              >
                Clear
              </LoadingButton>
              <LoadingButton
                style={{color: 'white', backgroundColor: '#027BFE', height:'46px', width:'16%', borderRadius: '8px',}}
                onClick={onClickGetList}
              >
                Get List
              </LoadingButton>
            </Grid2>
          </Grid2>
        </Paper>

        {/* Filter Button with Icon */}
        <Box sx={{marginTop:'3%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {/* <IconButton onClick={handleFilterMenuClick}>
            <FilterAlt />
          </IconButton>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleClickOpenDownloadWindow}>
              <Typography>Download Report </Typography> <Download />
            </IconButton>
            <Typography
              onClick={handleNavigation}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginLeft: 2,
              }}
            >
              Back
            </Typography>
          </Box> */}
          {/* Modal Window */}
          <Dialog
            onClick={handleClickOpenDownloadWindow}
            fullScreen={fullScreen}
            open={openDownloadWindow}
            onClose={handleCloseDownloadWindow}
          >
            <DialogTitle id="responsive-dialog-title">
              Download Report
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You can Download Upto 100 Transactions
              </DialogContentText>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                autoFocus
                loading={loading}
                onClick={handleDownload}
              >
                <Download /> Download
              </LoadingButton>
            </DialogActions>
          </Dialog>
          {/* Filter Menu */}
          <Menu
            anchorEl={filterMenuAnchor}
            open={Boolean(filterMenuAnchor)}
            onClose={handleFilterMenuClose}
            disableAutoFocus
            disableEnforceFocus
          >
            <MenuItem>
              <TextField
                label="Transaction ID"
                name="id"
                value={filters.id}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
                autoFocus
              />
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <TextField
                label="UTR Number"
                name="utr"
                value={filters.utr}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
              />
            </MenuItem>

            <MenuItem>
              <Button variant="contained" onClick={applyFilters}>
                Apply Filters
              </Button>
            </MenuItem>
          </Menu>
        </Box>

        {/* Table Container Started...!!! */}
        <TableContainer component={Paper} style={{boxShadow:'none', borderRadius:'12px 12px 0px 0px'}}>
          <Table>
            <TableHead
              style={{
                backgroundColor: "#027BFE",
                color: "white",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Sr No
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Date and Time
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Card Number
                </TableCell>

                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Card Network
                </TableCell>

                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Card Bank
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Card Holder Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Payment Status
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Bill Amount
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  User Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  User ID
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Order id
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trnsList && trnsList.length > 0 ? (
                trnsList?.map((item, i) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell align="center">{item.timestamp}</TableCell>
                      <TableCell align="center">{item.payee_account}</TableCell>
                      <TableCell align="center">
                        {item.payee_cardNetwork}
                      </TableCell>
                      <TableCell align="center">
                        {item.payee_bankName}
                      </TableCell>
                      <TableCell align="center">{item.payee_name}</TableCell>
                      <TableCell align="center">
                        {item.status === "Transaction Successful" ? (
                          <span style={{ color: "green" }}>Success</span>
                        ) : (
                          item.status
                        )}
                      </TableCell>
                      <TableCell align="center">{item.txnValue}</TableCell>
                      <TableCell align="center">
                        {item.user.firstName}
                      </TableCell>
                      <TableCell align="center">{item.user.userid}</TableCell>
                      <TableCell align="center">{item.orderid}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    List Not Available
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={12} align="center">
                  {/* <span>
                    Showing Transactions From {pageFrom} To {pageTo}
                  </span> */}

                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handlePageChange(currPage - 1)}
                    disabled={isFirstPage}
                  >
                    Back
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handlePageChange(currPage + 1)}
                    disabled={isLastPage}
                    sx={{ marginLeft: 1 }}
                  >
                    Next
                  </Button>
                  <Typography>Total Pages : {totalPages}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CreditHierarchyPays;
