import React from 'react';
import { Grid, Container, Paper } from '@material-ui/core';
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import logo from "../../Assets/iPaisaLogo/iPaisaLogo2.png";
import  {Colors}  from '../../Reports/Colors'; // colors.js ka path adjust karein

const HiePayInInvoice = ({data},username) => {
    const userID = sessionStorage.getItem('userId');
    const userName = sessionStorage.getItem('username');


    if (!data) return null;
    if(!username) return null;

    const address = data.userAddress 
    ? `${data.userAddress.address || ''}, ${data.userAddress.city || ''}, ${data.userAddress.state || ''}, ${data.userAddress.country || ''} - ${data.userAddress.pincode || ''}`
    : 'Address not available';
  
    console.log('Generating PDF for transaction:', data.txnid);

    return (
        <Container 
            style={{ 
                padding: '1rem', 
                width: '100%', 
                maxWidth: '1000px', 
                boxSizing: 'border-box',
                backgroundColor: '#f0f0f0', // Set the background color here
                minHeight: '90vh', // Ensure the background color covers the full height
                borderRadius:'14px'
            }}
        >
            <Paper 
                elevation={3}
                style={{
                    paddingTop:'5%',
                    padding: '2rem',
                    backgroundColor: Colors.bgColor,
                    boxSizing: 'border-box',
                    color: Colors.textColor,
                }}
            >
                <Grid style={{display:'flex', justifyContent:'center'}}>
                    <Typography style={{fontSize:'18px', fontWeight:"bold", marginTop:'-2%'}}>Hierarchy Payin Receipt </Typography>
                </Grid>
                <Grid style={{marginTop:'1%'}}>
                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid>
                            <Grid style={{display:'flex', alignItems:'center', gap:'6%'}}>
                                <img src={logo} alt="iPaisa Logo" style={{ height: 65, width: 65, backgroundColor: 'transparent', borderRadius: '50%' }} />
                                <Typography style={{ fontWeight: 'bold', fontSize: '28px' }}>iPaisa</Typography>
                            </Grid> 
                            <Grid style={{marginTop:'2%'}}>
                                <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                                    {new Date(data.addedon).toLocaleDateString() || 'N/A'}
                                </Typography>
                                <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                                {new Date(data.addedon).toLocaleTimeString() || 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>                   
                        <Grid style={{display:'flex', alignItems:'center', marginTop:"3%"}}>
                            <Grid style={{textAlign:'end'}}>
                                <Typography style={{fontSize:"16px", fontWeight:'bold' }}>From :</Typography>
                                <Typography style={{ fontSize: '20px', fontWeight:'bold'}}>
                                    {data.name}
                                </Typography>
                                <Typography style={{ fontSize: '16px'}}>
                                    {data.userId}
                                </Typography>
                                <Typography style={{ fontSize: '14px' }}>{address}</Typography>
                            </Grid>
                        </Grid> 
                    </Grid>
                    <TableContainer style={{ marginTop: '5%' }}>
                        <Table aria-label="transaction table" style={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor}}>Txn ID</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor, paddingLeft:'5%'}}>UTR No.</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor, paddingLeft:'7%'}}>PayIn Amount</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor, paddingLeft:'5%'}}>Amount</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor}}>Payment Type</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor}}>Payment By</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor, paddingLeft:'7%'}}>Status</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'12px', color: Colors.textColor, paddingLeft:'5%'}}>Settlement</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow>
                                    <TableCell style={{fontSize:'12px', color: Colors.textColor}}>{data.txnid || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'12px', color: Colors.textColor, paddingLeft:'5%'}}>{data.easepayid || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'12px', color: Colors.textColor, paddingLeft:'7%'}}>{data.netAmountDebit || 'N/A'}</TableCell>  
                                    <TableCell style={{fontSize:'12px', color: Colors.textColor, paddingLeft:'5%'}}>{data.amount || 'N/A'}</TableCell>  
                                    <TableCell style={{fontSize:'12px', color: Colors.textColor}}>{data.cardType}</TableCell>
                                        {/* Conditional rendering for "Payment By" based on cardType */}
                                        {data.cardType === 'UPI' ? (
                                        <TableCell style={{fontSize:'12px', color: Colors.textColor}}>{data.upiVa}</TableCell>  // Payment By UPI
                                        ) : data.cardType === 'Credit Card' || data.cardType === 'Debit Card' ? (
                                        <TableCell style={{fontSize:'12px', color: Colors.textColor}}>{data.cardnum}</TableCell>  // Payment By Card Number
                                        ) : (
                                        <TableCell style={{fontSize:'12px', color: Colors.textColor}}>N/A</TableCell>  // Handle other card types if necessary
                                        )}                              
                                    <TableCell style={{fontSize:'12px', color: Colors.textColor, paddingLeft:'7%'}}>
                                        {
                                        data.status === 'success' ? 'Successful'
                                        : data.status === 'failure' ? 'Failed' 
                                        : data.status === 'userCancelled' ? 'User Cancelled' 
                                        : data.status === 'preinitiated' ? 'Preinitiated' 
                                        : data.status || 'N/A'} {/* Default value if no match */}
                                    </TableCell>
                                    <TableCell style={{fontSize:'12px', color: Colors.textColor, paddingLeft:'5%'}}>{data.settlement}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid style={{ marginTop:'4%', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                    <Grid>
                        <Typography style={{ fontWeight:"bold", fontSize:"22px" }}>EDSOM FINTECH PRIVATE LIMITED</Typography>
                        <Typography style={{ fontWeight:'bold', fontSize:'14px' }}>
                            Thank you for transacting at iPaisa!
                        </Typography>
                        <Typography color='textSecondary' style={{fontSize:'12px', color:Colors.textColor}}>
                            This is a system generated receipt hence does not require any signature.
                        </Typography>
                        <Typography color='textSecondary' style={{fontSize:'12px', color:Colors.textColor}}>
                            Queries? Write to care@ipaisa.co.in
                        </Typography>
                    </Grid>
                        <Grid>
                            <Divider/>
                            <Grid>
                                <Typography style={{fontWeight:"bold"}}>Total Amount : {data.amount}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default HiePayInInvoice
