import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  MenuItem,
  CardMedia,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
import logo from "../Assets/login/iPaisaLogo2.png";
import { NewBBPSIcon } from "../../res/NewWebIcons";

export default function RetailerWater() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [operators, setOperators] = useState([]);  // State for storing operators

  useEffect(() => {
    // Fetch the operator data from the API
    const fetchOperators = async () => {
      try {
        const response = await fetch('https://api.ipaisa.co.in/myapp/auth/getAllWaterServicePro', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4Mzc4MDU0MDkwIiwiaWF0IjoxNzMxNzgxNTI5LCJleHAiOjE3MzE4NDE1Mjl9.WHJA0PIj_VmPAmJD3neyom5hTpMw_3LR2YkfxW_aGd7g1-6ZwB6rtqVGMKuo7czO6Ox5GnqG6v2xMvMu5Ci2og'
          }
        });
        const data = await response.json();
        // Assuming the API returns an array of operators
        setOperators(data);
      } catch (error) {
        console.error('Error fetching operator data:', error);
      }
    };

    fetchOperators();
  }, []); // Empty dependency array to run once on mount

  const formik = useFormik({
    initialValues: {
      operator: "",
      consumerNumber: "",
    },
    validationSchema: Yup.object({
      operator: Yup.string().required("Operator is required"),
      consumerNumber: Yup.string().required("RR Number is required"),
    }),
    onSubmit: (values) => {
      // Perform form submission logic here
      handleConfirm(values);
    },
  });

  // Function to handle the confirm button click
  const handleConfirm = async (values) => {
    const { operator, consumerNumber } = values;

    // Find the selected operator data from the operators state
    const selectedOperator = operators.find(op => op.providerName === operator);
    if (!selectedOperator) {
      console.error("Operator not found");
      return;
    }

    // Prepare the API URL with the selected operator and consumer number
    const url = `https://api.ipaisa.co.in/myapp/auth/getWaterbillInfo?tel=${consumerNumber}&operator=${selectedOperator.mplanShortcut}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4Mzc4MDU0MDkwIiwiaWF0IjoxNzMxNzgxNTI5LCJleHAiOjE3MzE4NDE1Mjl9.WHJA0PIj_VmPAmJD3neyom5hTpMw_3LR2YkfxW_aGd7g1-6ZwB6rtqVGMKuo7czO6Ox5GnqG6v2xMvMu5Ci2og'
        }
      });

      const data = await response.json();
      // Handle the response data (e.g., display the bill info or success message)
      console.log("Bill Info:", data);

      // Show success message
      setSubmitSuccess(true);
    } catch (error) {
      console.error('Error fetching bill info:', error);
    }
  };

  const inputHeight = '56px';

  return (
    <Container maxWidth="lg" style={{ marginTop: '12%', marginBottom: '3%' }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        bgcolor="#DDF1F5"
        borderRadius={8}
        sx={{
          background: 'linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)',
          color: 'white',
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                marginBottom: '4%',
              }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
              }}
            />
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '35px' }}>
              Online Water Bill Simplified
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: '20px', marginTop: '5%' }}>
              Simplify your life with effortless online water bill payments,
              ensuring a continuous flow of convention at your fingertips.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography variant="h4" color="#027BFE" sx={{ fontWeight: 'bold', fontSize: '30px' }}>
                Online Water Bill Payment
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <NewBBPSIcon />
              </Box>
              <TextField
                select
                fullWidth
                name="operator"
                label="Operator"
                value={formik.values.operator}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.operator && Boolean(formik.errors.operator)}
                helperText={formik.touched.operator && formik.errors.operator}
                style={{ marginTop: "20px" }}
              >
                <MenuItem value="">Select Operator</MenuItem>
                {operators.map((operator, index) => (
                  <MenuItem key={index} value={operator.providerName}>
                    {operator.providerName}
                  </MenuItem>
                ))}
              </TextField>
              {formik.touched.operator && formik.errors.operator && (
                <Typography variant="body2" color="error">
                  {formik.errors.operator}
                </Typography>
              )}
              <TextField
                fullWidth
                type="text"
                label="RR Number"
                name="consumerNumber"
                value={formik.values.consumerNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.consumerNumber && Boolean(formik.errors.consumerNumber)}
                helperText={formik.touched.consumerNumber && formik.errors.consumerNumber}
                style={{ marginTop: "20px" }}
              />
              <Button
                fullWidth
                type="button"
                onClick={() => formik.handleSubmit()}
                style={{
                  marginTop: "4%",
                  backgroundColor: '#027BFE',
                  color: 'white',
                  textTransform: 'none',
                  height: inputHeight,
                  fontSize: 20
                }}
              >
                Confirm
              </Button>
              {submitSuccess && (
                <Typography
                  variant="body2"
                  style={{ color: "green", marginTop: "20px" }}
                >
                  Bill successfully submitted!
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
