import React from "react";
import { SwipeableDrawer, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DOMPurify from "dompurify";

const RightSwipeableDrawer = ({ open, onClose, onOpen, content }) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      sx={{ zIndex: 1300 }}
    >
      <Box sx={{ width: 750, p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold" fontFamily={"open sans"}>
            Fare Details
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={2}>
          {content ? (
            <Box
              sx={{
                overflow: "auto",
                fontFamily: "open sans",
                "& table": {
                  width: "100%",
                  borderCollapse: "collapse",
                  marginBottom: "20px",
                  borderRadius: "20px",
                  fontFamily: "open sans",
                },
                "& th, & td": {
                  border: "2px solid #ddd",
                  padding: "12px",
                  textAlign: "center",
                },
                "& th": {
                  backgroundColor: "#ffffff",
                },
                "& td": {
                  backgroundColor: "#ffffff",
                },
                "& ul": {
                  marginLeft: "20px",
                  paddingLeft: "20px",
                  listStyle: "disc outside",
                },
                "& ul ul": {
                  listStyle: "circle",
                },
                "& li": {
                  marginBottom: "8px",
                },
                "& legend": {
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#333",
                },
                "& fieldset": {
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px 15px",
                  marginBottom: "20px",
                  backgroundColor: "#fdfdfd",
                },
                "& b": {
                  color: "#333",
                },
                "& *": {
                  fontFamily: "'Roboto', sans-serif",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content),
              }}
            ></Box>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No fare rule details available.
            </Typography>
          )}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default RightSwipeableDrawer;
