import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Modal,
} from "@mui/material";
import { BAssuredIcon, NewBBPSIcon } from "../../res/NewWebIcons"; // Import Bharat Connect logo
import VisibilityIcon from "@mui/icons-material/Visibility"; // Eye icon for "Action"

// Data for Table
const transactionData = [
  {
    name: "ASLAM RASHIDKHAN PATHAN",
    mobile: "9876543210",
    date: "04-01-2025 06:45 PM",
    paymentFor: "Electricity Bill",
    txnRefrenceId: "TXN09865HGFG6",
    amount: "₹ 620",
    status: "Completed",
  },
  {
    name: "Kamran Khan",
    mobile: "9876543210",
    date: "03-01-2025 08:00 AM",
    paymentFor: "Landline Bill",
    txnRefrenceId: "TXN5687445H54G6",
    amount: "₹ 1,236",
    status: "Completed",
  },
  {
    name: "Yash Suryawanshi",
    mobile: "9876543210",
    date: "31-12-2024 08:45 PM",
    paymentFor: "Water Bill",
    txnRefrenceId: "TXN8765465HG76",
    amount: "₹ 830",
    status: "Completed",
  },
];

// Modal Component for Receipt 
const ReceiptModal = ({ open, handleClose, data }) => (
  <Modal open={open} onClose={handleClose} aria-labelledby="receipt-modal">
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: 600,
      bgcolor: "background.paper",
      boxShadow: 24,
      borderRadius: "8px",
      p: 4,
    }}
  >
    <Box sx={{ display:'flex', justifyContent:'space-between', alignItems:'center' }}>
      <Typography fontWeight="bold" sx={{fontSize:'18px'}}>
        Payment Receipt
      </Typography>
      <BAssuredIcon style={{ width: 80, height: 40 }} /> {/* Logo */}
    </Box>

    {/* Key-Value Pairs with Space Between */}
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between", marginTop:'8%' }}>
      <Typography variant="body1">
        <strong>Name/Business:</strong>
      </Typography>
      <Typography variant="body1">ASLAM RASHIDKHAN PATHAN</Typography>
    </Box>
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
      <Typography variant="body1">
        <strong>Phone:</strong>
      </Typography>
      <Typography variant="body1">9876543210</Typography>
    </Box>
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
      <Typography variant="body1">
        <strong>Txn Reference ID:</strong>
      </Typography>
      <Typography variant="body1">TXN09865HGFG6</Typography>
    </Box>
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
      <Typography variant="body1">
        <strong>CIN:</strong>
      </Typography>
      <Typography variant="body1">hgfjdhfgsj5645645fajhjgj</Typography>
    </Box>
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
      <Typography variant="body1">
        <strong>Date:</strong>
      </Typography>
      <Typography variant="body1">{data.date}</Typography>
    </Box>
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
      <Typography variant="body1">
        <strong>Receipt:</strong>
      </Typography>
      <Typography variant="body1">{data.txnRefrenceId}</Typography>
    </Box>
    <Box sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}>
      <Typography variant="body1">
        <strong>Payment Mode:</strong>
      </Typography>
      <Typography variant="body1">Online</Typography>
    </Box>

    {/* Table for Services */}
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#D9F2FF" }}>
            <TableCell>Service</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>GST@18%(INR)</TableCell>
            <TableCell>Total (INR)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{data.paymentFor}</TableCell>
            <TableCell>
              {parseFloat(data.amount.replace("₹", "")) - 40.83}
            </TableCell>
            <TableCell>40.83</TableCell>
            <TableCell>{data.amount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    {/* Total Box */}
    <Box
      sx={{
        textAlign: "right",
        mt: 2,
        p: 2,
        backgroundColor: "#D9F2FF",
        borderRadius: "4px",
      }}
    >
      <Typography variant="h6">
        <strong>Total: {data.amount}</strong>
      </Typography>
    </Box>
  </Box>
</Modal>
);


const TransactionsHistory = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleOpenModal = (row) => {
    setSelectedData(row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedData(null);
  };

  return (
    <Box sx={{ padding: "2rem", marginTop: "4%" }}>
      <Grid spacing={2} style={{ padding: "20px" }}>
        {/* Header Section */}
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Transaction
          </Typography>
          <NewBBPSIcon style={{ width: 120, height: 40 }} /> {/* Logo */}
        </Grid>

        <Box
          component={Paper}
          sx={{
            boxShadow: "none",
            padding: "2rem",
            borderRadius: "12px",
            marginTop: "2%",
          }}
        >
          {/* Search Filters */}
          <Grid container spacing={2} style={{ marginBottom: "2%" }}>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Mobile No / Transaction Reference ID"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Form Date"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="To Date"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  height: "100%",
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          {/* Transaction History */}
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginBottom: 10 }}
          >
            Transaction History
          </Typography>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table>
              <TableHead style={{ backgroundColor: "#D9D9D9" }}>
                <TableRow>
                  <TableCell>Name/Business</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Txn Reference ID</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.txnRefrenceId}</TableCell>
                    <TableCell>{row.paymentFor}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell style={{ color: "green" }}>
                      {row.status}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenModal(row)}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>

      {/* Receipt Modal */}
      {selectedData && (
        <ReceiptModal
          open={modalOpen}
          handleClose={handleCloseModal}
          data={selectedData}
        />
      )}
    </Box>
  );
};

export default TransactionsHistory;
