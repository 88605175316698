import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { RBLBankIcon2 } from "../../res/icons"; // Replace with correct path

const MyCards = () => {
    const userType = sessionStorage.getItem("uType");

    const cardsData = [
        {
            bankName: "RBL Bank",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "4090 2155 9927",
            ifsc: "RATN0000512",
            branch: "Pashan, Pune",
            icon: RBLBankIcon2,
            color: "#21317D",
        },
    ];

    return (
        <Paper
            elevation={4}
            style={{
                padding: "1.5rem",
                backgroundColor: "#FFFFFF",
                borderRadius: "18px",
                boxShadow:'none'
            }}
        >
            <Typography
                style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "1rem" }}
            >
                My Banks
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {cardsData.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                    elevation={3}
                    style={{
                        backgroundColor: card.color,
                        color: "#fff",
                        padding: "1rem",
                        borderRadius: "12px",
                        boxShadow:'none'
                    }}
                >
                    {/* Top Section: Logo and Bank Name */}
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ marginBottom: "1rem" }}
                    >
                        {/* Bank Logo */}
                        <Grid item>
                            <card.icon style={{ fontSize: "3rem", color: "#fff" }} />
                        </Grid>

                        {/* Bank Name */}
                        <Grid item>
                            <Typography
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    textAlign: "right",
                                }}
                            >
                                {card.bankName}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Remaining Details */}
                    <Typography style={{ fontSize: "18px", marginBottom: "0.5rem", color:'#F6D657', fontWeight:'bold' }}>
                        {card.company}
                    </Typography>
                    <Typography style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
                        Account No: {card.accountNo}
                    </Typography>
                    <Typography style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
                        IFSC: {card.ifsc}
                    </Typography>
                    <Typography style={{ fontSize: "14px" }}>Branch: {card.branch}</Typography>
                </Paper>
            </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default MyCards;
