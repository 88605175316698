import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, Paper, CircularProgress, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { Formik } from 'formik';

const SearchAndFilters = ({ searchParams, setSearchParams, handleSearch }) => {
  const { userType, selectedUserId, searchTerm, fromDate, toDate } = searchParams;
  const [users, setUsers] = useState([]);
  const [availableUserTypes, setAvailableUserTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleUserTypeChange = async (event) => {
    const selectedType = event.target.value;
    setSearchParams((prev) => ({ ...prev, userType: selectedType, selectedUserId: null }));
    const jwtToken = sessionStorage.getItem('jwtToken');

    try {
      setLoading(true);
      const response = await axios.get('https://api.ipaisa.co.in/myapp/admin/getAllPartner', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        params: { userrole: selectedType },
      });

      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Error fetching partners:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prev) => ({ ...prev, [field]: value }));
  };

  const handleSearchClick = () => {
    handleSearch(searchParams);
  };

  useEffect(() => {
    const uType = sessionStorage.getItem('uType');
    if (uType === 'MASTERDISTRIBUTOR') {
      setAvailableUserTypes(['AREADISTRIBUTOR', 'RETAILER']);
    } else if (uType === 'AREADISTRIBUTOR') {
      setAvailableUserTypes(['RETAILER']);
    } else {
      setAvailableUserTypes(['AREADISTRIBUTOR', 'RETAILER']);
    }
  }, []);

    // New function to handle clear button
    const handleClear = (resetForm, setSearchQuery) => {
      resetForm();
      setSearchParams('');
      setLoading(false);
    };

  return (
    <Paper sx={{ boxShadow:'none', boxShadow:'none', padding: '3%', borderRadius: '8px', marginTop: '2%', boxShadow:'none' }}>
      <Formik
        initialValues={{ fromDate: '', toDate: '' }}
        onSubmit={handleSearch} >
          {({ resetForm }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>User Type</InputLabel>
                <Select value={userType} onChange={handleUserTypeChange} label="User Type">
                  {availableUserTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type === 'AREADISTRIBUTOR' ? 'Area Distributor' : 'Retailer'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => `${option.userId} - ${option.Name}`}
                value={users.find((user) => user.userId === selectedUserId) || null}
                onChange={(event, newValue) => handleInputChange('selectedUserId', newValue?.userId || null)}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    placeholder="Select or Search User"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                type="date"
                label="From Date"
                fullWidth
                variant="outlined"
                value={fromDate}
                onChange={(e) => handleInputChange('fromDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                type="date"
                label="To Date"
                fullWidth
                variant="outlined"
                value={toDate}
                onChange={(e) => handleInputChange('toDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>


            {/* Buttons aligned to the right with space between them */}
            <Grid item xs={12} style={{ marginTop: '3%' }}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                <Button
                onClick={handleSearchClick}
                  sx={{
                    backgroundColor: '#027BFE',
                    color: 'white',
                    height: '56px',
                    textTransform: 'none',
                    borderRadius: '10px',
                    width: '120px',
                  }}
                  disabled={!userType || !selectedUserId}
                >
                  Search
                </Button>
                <Button
                  onClick={() => handleClear(resetForm)}
                  sx={{
                    backgroundColor: 'gray',
                    color: 'white',
                    height: '56px',
                    textTransform: 'none',
                    borderRadius: '10px',
                    width: '120px',
                  }}
                  >
                    Clear
                </Button>
              </Box>
            </Grid>
          </Grid>
          )}
      </Formik>
    </Paper>
  );
};

export default SearchAndFilters;
