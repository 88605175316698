import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  Slider,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import brand from "../Assets/Services/Flight/brand.svg";
import before6am from "../Assets/Services/Flight/sun.svg";
import partlyCloudy from "../Assets/Services/Flight/partly_cloudy.svg";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import { Grid } from "@material-ui/core";
import EastIcon from "@mui/icons-material/East";
import Line from "../Assets/Services/Flight/Line.svg";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import FlightIcon from "@mui/icons-material/Flight";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useLocation, useNavigate } from "react-router-dom";
import RoundTripCard from "./RoundTripCard";
import RoundTripFare from "./RoundTripFare";
import RvFare from "./RvFare";
// import TestComp from "./TestComp";

function RoundTrip() {
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [outSelectedValue, setOutSelectedValue] = useState("");
  const [inSelectedValue, setInSelectedValue] = useState("");
  const [selectedCard, setSelectedCard] = useState(null); // Store the ID of the selected card
  const [selectedOutbound, setSelectedOutbound] = useState(null); // Selected outbound flight
  const [selectedInbound, setSelectedInbound] = useState(null);

  const handlePriceChange = (event, newValue) => setPriceRange(newValue);
  const handleOutRadioChange = (event) =>
    setOutSelectedValue(event.target.value);
  const handleInRadioChange = (event) => setInSelectedValue(event.target.value);

  const navigate = useNavigate();

  const location = useLocation();
  const { flightsData, searchDetails } = location.state || {};

  console.log("searchDetails from roundtrip", searchDetails);
  const searchFrom = searchDetails.from;
  const searchTo = searchDetails.to;
  const departDate = searchDetails.departDate;

  const flights = flightsData?.Response?.Results || [];

  const traceid = flightsData?.Response?.TraceId || null;
  console.log("Flights Data------: ", flights);

  const handleCardSelection = (flightId) => {
    setSelectedCard(flightId); // Update the selected card globally
  };

  // const handleOutRadioChange = (event) => {
  //   setOutSelectedValue(event.target.value);
  // };

  const handleOutboundSelection = (flightId) => {
    console.log("Outbound Selection------: ", flightId);
    setSelectedOutbound(flightId); // Update the selected outbound card
  };

  const handleInboundSelection = (flightId) => {
    console.log("Inbound Selection------: ", flightId);
    setSelectedInbound(flightId); // Update the selected inbound card
  };

  const durationCalculator = (source, destination) => {
    const start = new Date(source);
    const end = new Date(destination);
    // Check if the dates are valid
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return "Invalid Date"; // Return if any date is invalid
    }

    // Calculate the difference in milliseconds
    const diffInMs = end.getTime() - start.getTime();

    // Convert the difference from milliseconds to hours
    const diffInHours = diffInMs / (1000 * 60 * 60);
    // Calculate whole hours and remaining minutes
    const hours = Math.floor(diffInHours); // Whole hours
    const minutes = Math.round((diffInHours - hours) * 60); // Remaining minutes

    return `${hours} hrs ${minutes} mins`; // Optional: limit the decimal places
  };

  useEffect(() => {
    // Select the first outbound and inbound flights on initial render
    if (flights.length > 0) {
      setSelectedOutbound(flights[0][0].flightId); // Assuming flightId is a unique identifier
      setSelectedInbound(flights[1][0].flightId);
    }
  }, []);

  return (
    <Container maxWidth="lg" sx={{ marginTop: "8%" }}>
      <Typography>Service &gt; Flight Booking</Typography>

      <Paper
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {searchDetails.from}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <EastIcon />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {searchDetails.to}
            </Typography>
          </Box>
          <img src={Line} alt="Line" />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                opacity: 0.8,
              }}
            >
              Departure
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "short",
                year: "2-digit",
                weekday: "long",
              }).format(new Date(searchDetails.departDate))}
            </Typography>
          </Box>
          <img src={Line} alt="Line" />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                opacity: 0.8,
              }}
            >
              Travellers
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {searchDetails.travelers}
            </Typography>
          </Box>
          <img src={Line} alt="Line" />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                opacity: 0.8,
              }}
            >
              Travel Class
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {searchDetails.travelClass}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              background: "#027BFE",
              color: "white",
              textTransform: "none",
              px: 2,
              borderRadius: "10px",
            }}
          >
            Modify Search
          </Button>
        </Box>
      </Paper>

      <Box mt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Box>
              <Typography>Filters</Typography>

              <Accordion
                defaultExpanded
                sx={{ boxShadow: "none", border: "none" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Price</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Slider
                    getAriaLabel={() => "Price range"}
                    value={priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={50000}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography>₹{priceRange[0]}</Typography>
                    <Typography>₹{priceRange[1]}</Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Divider />

              <Accordion
                defaultExpanded
                sx={{ boxShadow: "none", border: "none" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Departure Time</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={before6am}
                        alt="before6am"
                        style={{ height: "20px" }}
                      />
                      <Typography sx={{ fontSize: "10px" }}>
                        Before 6 AM
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box>
                        <WbSunnyOutlinedIcon sx={{ fontSize: "20px" }} />
                      </Box>
                      <Typography sx={{ fontSize: "10px" }}>
                        6 AM - 12 PM
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={partlyCloudy}
                        alt="partlyCloudy"
                        style={{ height: "20px" }}
                      />
                      <Typography sx={{ fontSize: "10px" }}>
                        12 PM - 6 PM
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box>
                        <NightsStayOutlinedIcon sx={{ fontSize: "20px" }} />
                      </Box>
                      <Typography sx={{ fontSize: "10px" }}>
                        After 6 PM
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Divider />

              <Accordion
                defaultExpanded
                sx={{ boxShadow: "none", border: "none" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Airlines</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Emirated" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Fly Dubai"
                    />
                    <FormControlLabel control={<Checkbox />} label="Qatar" />
                    <FormControlLabel control={<Checkbox />} label="Etihad" />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            {flights.length > 0 ? (
              <Box>
                <Typography variant="h5" sx={{ mb: 0 }}>
                  Flights
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" sx={{ mb: 0 }}>
                        Onward Flights
                      </Typography>
                      {flights[0]?.length > 0 ? (
                        flights[0].map((outboundFlight, fdi) => (
                          <RoundTripCard
                            key={`outbound-${fdi}`}
                            fdetails={outboundFlight}
                            durationCalculator={durationCalculator}
                            isSelected={selectedOutbound === outboundFlight} // Check if selected
                            onSelect={() =>
                              handleOutboundSelection(outboundFlight)
                            } // Pass selection handler
                          />
                        ))
                      ) : (
                        <Typography>No outbound flights available</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" sx={{ mb: 0 }}>
                      Return Flights
                      </Typography>
                      {flights[1]?.length > 0 ? (
                        flights[1].map((inboundFlight, fdi) => (
                          <RoundTripCard
                            key={`inbound-${fdi}`}
                            fdetails={inboundFlight}
                            durationCalculator={durationCalculator}
                            isSelected={selectedInbound === inboundFlight} // Check if selected
                            onSelect={() =>
                              handleInboundSelection(inboundFlight)
                            } // Pass selection handler
                          />
                        ))
                      ) : (
                        <Typography>No inbound flights available</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            ) : (
              <Paper sx={{ p: 3 }}>
                <Typography>No flights found</Typography>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* View fare */}
      <Box
        sx={{
          // width: "100%",
          // height: "100px",
          // background: "gray",
          position: "fixed",
          bottom: "0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "2",
          // color: "white",
        }}
      >
        {/* Render RoundTripFare only if both are available */}
        {selectedOutbound && selectedInbound && (
          <RoundTripFare
            durationCalculator={durationCalculator}
            outbound={selectedOutbound}
            inbound={selectedInbound}
          />
        )}
      </Box>
    </Container>
  );
}
export default RoundTrip;
