import React, { useState, useEffect } from "react";
import {
  TextField,
  Container,
  Grid,
  Typography,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { NewBBPSIcon } from "../../res/NewWebIcons";

export default function RElectricity() {
  const [operators, setOperators] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [billDetails, setBillDetails] = useState({});
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [inputFields, setInputFields] = useState([]);

  // Fetch operators from API
  const fetchOperators = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    try {
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getAllElecServicePro",
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setOperators(response.data || []);
      setApiError(null);
    } catch (error) {
      setApiError("Failed to fetch operators. Please try again later.");
      console.error("Error fetching operators:", error);
    }
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  // const fetchBillDetails = async (values) => {
  //   const jwtToken = sessionStorage.getItem("jwtToken");

  //   try {
  //     const { tel, operator, field1 } = values;
  //     const selectedOperator = operators.find((op) => op.opcode === operator);
  //     const mplanShortcut = selectedOperator ? selectedOperator.mplanShortcut : "";

  //     const response = await axios.get(
  //       "https://api.ipaisa.co.in/myapp/auth/getElectricityInfo",
  //       {
  //         params: {
  //           tel,
  //           operator: mplanShortcut,
  //           ...(field1 && { field1 }), // Include field1 only if it's not empty
  //         },
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}`,
  //         },
  //       }
  //     );

  //     setBillDetails(response.data);
  //     setIsResponseReceived(true);
  //     setApiError(null); // Clear any previous errors
  //     console.log("Bill Details:", response.data.records[0]);
  //   } catch (error) {
  //     setApiError("Failed to fetch bill details. Please try again.");
  //     console.error("Error fetching bill details:", error);
  //   }
  // };

  // Handle operator selection to populate additional fields
  const handleOperatorChange = (event) => {
    const selectedOpcode = event.target.value;
    const selectedOperator = operators.find((op) => op.opcode === selectedOpcode);

    if (selectedOperator?.inputParams) {
      const fields = Object.entries(selectedOperator.inputParams).map(
        ([key, label]) => ({ key, label })
      );
      setAdditionalFields(fields);
      setInputFields(fields.map((field) => field.key)); // Store keys of inputParams
    } else {
      setAdditionalFields([]);
      setInputFields([]);
    }

    formik.setFieldValue("operator", selectedOpcode);
  };
  const handleActionClick = (action) => {
    if (action === "Cancel") {
      console.log("Cancel action triggered. Current Input Fields:", inputFields);
    } else if (action === "Pay") {
      console.log("Pay action triggered. Current Input Fields:", inputFields);
    }
  };
  // Build the validation schema dynamically
  const buildValidationSchema = (additionalFields) => {
    const dynamicSchema = additionalFields.reduce((schema, field) => {
      schema[field.key] = Yup.string()
        .required(`${field.label} is required`);
      return schema;
    }, {});

    return Yup.object().shape({
      // tel: Yup.string()
      //   .matches(/^[0-9]{12}$/, "Please enter a valid 12-digit consumer number")
      //   .required("Consumer number is required"),
      operator: Yup.string().required("Operator is required"),
      ...dynamicSchema,
    });
  };

  // Update formik validation schema dynamically on additionalFields change
  useEffect(() => {
    formik.setFormikState((prevState) => ({
      ...prevState,
      validationSchema: buildValidationSchema(additionalFields),
    }));
  }, [additionalFields]);

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      // tel: "",
      operator: "",
      ...additionalFields.reduce((acc, field) => ({ ...acc, [field.key]: "" }), {}),
    },
    validationSchema: buildValidationSchema(additionalFields),
    onSubmit: async (values) => {
      handleApiSubmit(values); // Use a separate function for the API call
    },
  });
  const handleApiSubmit = async (values) => {
    try {
      const jwtToken = sessionStorage.getItem("jwtToken");
      const { tel, operator, ...additionalInputs } = values;

      const selectedOperator = operators.find((op) => op.opcode === operator);
      const mplanShortcut = selectedOperator ? selectedOperator.mplanShortcut : "";

      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getElectricityInfo",
        {
          params: {
            tel: additionalInputs.mn,
            operator: mplanShortcut,
            ...additionalInputs,
          },
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setBillDetails(response.data);
      setIsResponseReceived(true);
    } catch (error) {
      setApiError("Failed to fetch bill details. Please try again.");
      console.error("Error fetching bill details:", error);
    }
  };


  return (
    <Container maxWidth="lg" style={{ marginTop: "12%", marginBottom: "3%" }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        borderRadius={8}
        sx={{
          background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
          color: "white",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <Box alignItems="center" mb={2}>
              <Typography variant="h4" sx={{ fontWeight: "bold", fontSize: "35px" }}>
                Ingenious Electricity Bill Settlement
              </Typography>
            </Box>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "20px", marginTop: "3%" }}
            >
              Empower your life with the efficient management of electricity
              bills, ensuring uninterrupted power supply for your world of
              possibilities.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography
                variant="h4"
                color="#027BFE"
                sx={{ fontWeight: "bold", fontSize: "30px" }}
              >
                Electricity Bill Payment
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <NewBBPSIcon />
              </Box>

              {apiError && (
                <Typography
                  variant="body2"
                  color="error"
                  style={{ marginBottom: "20px" }}
                >
                  {apiError}
                </Typography>
              )}

              {/* Form Fields */}
              <TextField
                select
                fullWidth
                name="operator"
                label="Operator"
                value={formik.values.operator}
                onChange={handleOperatorChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.operator && Boolean(formik.errors.operator)
                }
                helperText={formik.touched.operator && formik.errors.operator}
                style={{ marginTop: "20px" }}
                disabled={isResponseReceived}
              >
                <MenuItem value="">Select Operator</MenuItem>
                {operators.length > 0 ? (
                  operators.map((operator) => (
                    <MenuItem key={operator.id} value={operator.opcode}>
                      {operator.providerName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No operators available</MenuItem>
                )}
              </TextField>

              {/* <TextField
                fullWidth
                type="text"
                label="Consumer Number"
                name="tel"
                value={formik.values.tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tel && Boolean(formik.errors.tel)}
                helperText={formik.touched.tel && formik.errors.tel}
                inputProps={{
                  maxLength: 12,
                  pattern: "[0-9]*",
                  title: "Please enter a 12-digit number",
                }}
                style={{ marginTop: "20px" }}
                disabled={isResponseReceived}
              /> */}

              {/* Render Additional Fields */}
              {additionalFields.map((field) => (
                <TextField
                  key={field.key}
                  fullWidth
                  type="text"
                  label={field.label}
                  name={field.key}
                  value={formik.values[field.key] || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[field.key] && Boolean(formik.errors[field.key])}
                  helperText={formik.touched[field.key] && formik.errors[field.key]}
                  style={{ marginBottom: "20px" }}
                />
              ))}


              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#FF0000",
                    color: "#FFFFFF",
                    textTransform: "none",
                    height: "40px",
                  }}
                  onClick={() => formik.resetForm()}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#027BFE",
                    color: "white",
                    fontWeight: "bold",
                    textTransform: "none",
                    height: "40px",
                  }}
                  onClick={formik.handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Box>

            {/* Display bill details after successful submission */}
            {isResponseReceived && billDetails.records && billDetails.records.length > 0 && (
              <Box mt={4} bgcolor="black" p={4} boxShadow={2} borderRadius={8}>
                <Typography variant="h5" color="#027BFE" sx={{ fontWeight: "bold" }}>
                  Bill Details
                </Typography>
                {billDetails.records.map((record, index) => (
                  <Box key={index} mt={2}>
                    <Typography variant="body1">
                      <strong>Customer Name:</strong> {record.CustomerName}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Bill Amount:</strong> {record.Billamount}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Bill Date:</strong> {record.Billdate}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Due Date:</strong> {record.Duedate}
                    </Typography>
                  </Box>

                ))}
              </Box>

            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
