import React, { useState } from "react";
import { Grid, Typography, TextField, MenuItem, Button, Box, Dialog, DialogContent, Card, CardContent, FormLabel } from "@mui/material";
import { NewBBPSIcon } from "../../res/NewWebIcons";

const NewPayLoan = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState("500");


  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <Box sx={{ padding: "2rem" }}>
      {/* Header */}
            <Grid style={{ marginTop:'1%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <FormLabel
                component="legend"
                style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
                >
                  Pay EMI Loan
            </FormLabel>
              <Typography>
                <NewBBPSIcon/>
              </Typography>
            </Grid>

      {/* Form */}
      <Grid container spacing={3} style={{marginTop:'1%'}}>
        {/* Lender Dropdown */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            select
            label="Lender"
            variant="outlined"
          >
            <MenuItem value="Lender A">Lender A</MenuItem>
            <MenuItem value="Lender B">Lender B</MenuItem>
          </TextField>
        </Grid>

        {/* Loan Payment Mode */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Loan Payment Mode"
            variant="outlined"
          />
        </Grid>

        {/* Loan Account Number */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Loan Account Number"
            variant="outlined"
          />
        </Grid>

        {/* Payable Amount and Button */}
        <Grid container item xs={12} justifyContent="flex-end">
          {/* Get Payable Amount Button */}
          <Button
            style={{
              backgroundColor: "#027BFE",
              color: "white",
              textTransform: "none",
              fontSize: "14px",
            }}
            onClick={handleModalOpen}
          >
            Get Payable Amount
          </Button>
        </Grid>
      </Grid>
                  {/* Payment Modal */}
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
          <DialogContent>
            <Grid container spacing={4}>
              {/* Left Section: Payment Details */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    border: "1px solid #D9D9D9",
                    borderRadius: "12px",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    Confirm your payment
                  </Typography>

                  {/* Consumer Number */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Loan Acc. No.
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      22671555447897
                    </Typography>
                  </Grid>

                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Customer Number
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      98897678767
                    </Typography>
                  </Grid>

                  {/* Amount */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {amount}
                    </Typography>
                  </Grid>

                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      color: "white",
                      backgroundColor: "#027BFE",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}

                  >
                    Proceed to Pay
                  </Button>
                </Box>
              </Grid>

              {/* Right Section: Wallet Info */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "#027BFE",
                    color: "white",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Wallet Balance
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" mt={1}>
                      1000
                    </Typography>
                    <Button
                      sx={{
                        mt: 3,
                        backgroundColor: "white",
                        color: "#027BFE",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Update Wallet
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
    </Box>
  );
};

export default NewPayLoan;
