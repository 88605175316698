import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// Import Swiper modules
import { Pagination, Autoplay } from 'swiper/modules';
import { React, useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  CardMedia,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  CardContent,
  IconButton,
  Slide
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Header from "./Header";
import OurServices from "../Assets/website/home/OurServices.png";
import utilitypayment from "../Assets/website/home/utilitypayment.png";
import FarmerImg from "../Assets/website/home/FarmerImg.jpg";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPSJPG.jpg";
import Footer from "./Footer";
import homeMobile from "../Assets/login/homeMobile.gif";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import BGIMG3 from "../Assets/website/home/Dollar.png";
import countimg from "../Assets/website/home/countimg.png";
import maskLady from "../Assets/website/home/homeMaskLady.png";
import cardImage2 from "../Assets/BlogsImage/BlogsImage/cardImg2.jpg";
import blogimage1 from "../Assets/BlogsImage/BlogsImage/BlogsImage/blogsImage2.jpg";
import blogimage from "../Assets/BlogsImage/BlogsImage/blogimage.jpg";
import { CallIcon, GameHomeIcon, GameIcon, TeamUserIcon } from "../../res/icons";
import { styled } from "@mui/system";
import FarmerCarausel1 from "../Assets/website/home/FarmerCarausel1.png";
import FarmerCarausel2 from "../Assets/website/home/FarmerCarausel2.png";
import FarmerCarausel3 from "../Assets/website/home/FarmerCarausel3.png";
import FlightBookingImg from "../Assets/website/home/FliightBookingImg.png";
import BusBookingImg from "../Assets/website/home/BusBookingImg.png";
import HotelBookingImg from "../Assets/website/home/HotelBookingImg.png";
import CardBusImg from "../Assets/website/home/CardBusImg.png";
import CardFlightImg from "../Assets/website/home/CardFlightImg.jpg";
import AirCraftHomeImg from "../Assets/website/home/AirCraftHomeImg.png";


const useStyles = makeStyles({
  countBox: {
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
    padding: "1rem",
    borderRadius: "8px",
    backgroundColor: "#031273",
  },
});

const Home = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(true); // Modal is open on page load
  const navigate = useNavigate();


  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenModal(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);


  const handleOpenContactUs = () => {
    navigate("/ContactUs");
  };
  const handleSearchFlights = () => {
    navigate("/signin");
  };
  const handleOpenAboutUs = () => {
    navigate("/Aboutus");
  };
  const handleOpenServices = () => {
    navigate("/Services");
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [openDialogs, setOpenDialogs] = useState([false, false, false]);

  const handleeClickOpen = (index) => {
    const updatedOpenDialogs = openDialogs.map((open, i) => i === index ? true : open);
    setOpenDialogs(updatedOpenDialogs);
  };

  const handleeClose = (index) => {
    const updatedOpenDialogs = openDialogs.map((open, i) => i === index ? false : open);
    setOpenDialogs(updatedOpenDialogs);
  };

  const CustomCard = styled(Card)({
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  });
  
  const cardContent = [
    {
      date: 'June 25, 2024',
      title: ' Unpacking the Power of UPI: Revolutionising Payments in India',
      image: blogimage,
      details: `
        In recent years, India has witnessed a digital payment revolution that has fundamentally transformed how people transact. At the heart of this revolution is the<b> Unified Payments Interface (UPI) </b>, a system that has made digital payments incredibly easy and accessible for millions of Indians. <br/><br/>  
        <b>1. Streamlined Convenience</b><br />
        One of the most significant benefits of Bharat Connect Bill and payment apps is the streamlined convenience they offer. These apps bring together various payment options together on one platform, allowing users to make payments for a wide range of services and bills. Whether it's electricity, water, gas, internet, or even credit card bills, these apps provide a one-stop solution, saving users time and effort.<br /><br/>
       <b> Let's explore how<b> UPI </b>works, its benefits, and its impact on the Indian economy.</b><br/></br>
        <b> What is UPI ?  </b><br/>
        The Unified Payments Interface (UPI) is a real-time payment system developed by the National Payments Corporation of India (NPCI) under the guidance of the Reserve Bank of India (RBI). Launched in April 2016, UPI allows users to link multiple bank accounts to a single mobile application, enabling seamless money transfers and payments directly from their smartphones.<br/><br/>
        <b>How Does UPI Work?</b><br/><br/>
        <b>UPI simplifies the process of money transfer through a single platform. Here's how it works:</b><br/>
        <br/>
        <b>1.	Registration: </b><br>
        Users need to download a UPI-enabled app like Google Pay, PhonePe, Paytm,  or BHIM. After downloading the app, they must register with their mobile number linked to their bank account and set a UPI PIN for secure transactions.<br/>
        <b>2.	Virtual Payment Address (VPA): </b><br/>
        Each user gets a unique virtual payment address (e.g., user@bank), which acts as their financial address. This eliminates the need to share sensitive bank details for transactions.<br/>
        <b>3.	Initiating Transactions: </b><br/>
        Users can send or receive money using the recipient's VPA, bank account number, or mobile number. Transactions are processed instantly, 24/7, without any delays.<br/>
        <b>4.	Security: </b><br/>
        UPI transactions are secured with two-factor authentication: the <b>UPI PIN</b>  and the mobile number linked to the bank account, ensuring safe and secure payments.<br/><br/>
        <b> Benefits of UPI </b><br/><br/>
        <b>1. Convenience:</b><br/>
        UPI provides a seamless and hassle-free payment experience. Users can make payments anytime, anywhere, without the need for physical cash or cards. The interface is user-friendly, making it accessible even to those who are not tech-savvy.<br/>
        <b>2. Interoperability:</b><br>
        UPI's interoperability allows users to link multiple bank accounts from different banks into a single app, making it easier to manage finances and transactions.<br/>
        <b>3.Cost-Effective:</b>
        UPI transactions are free of charge for most users, making them an economical choice for both individuals and businesses.<br/>
        <b>4. Instant Settlements:</b><br/>
        With UPI, money transfers happen in real-time, providing immediate confirmation and settlement of transactions. This is particularly beneficial for businesses and individuals who need quick access to funds<br/>
        <b>5. Wide Acceptance:</b>
        UPI is accepted by millions of merchants across India, from small roadside vendors to large e-commerce platforms. This widespread adoption has contributed to the rapid growth of digital payments in the country<br/>
        <b>6 .Enhanced Security:</b>
        UPI's robust security features, including two-factor authentication and encryption, ensure that users' financial information remains protected during transactions.<br/><br/>
        <b>Impact on the Indian Economy</b><br/>
        The introduction of UPI has had a profound impact on the Indian economy.<br/></br>
        <b>1. Financial Inclusion:</b><br/>
        UPI has played a crucial role in advancing financial inclusion by providing access to digital banking services to people across urban and rural areas. It has brought millions of previously unbanked individuals into the formal financial system.<br/>
        <b>2. Digital Transformation:</b><br/>
        UPI has accelerated India's digital transformation by promoting cashless transactions and reducing dependency on physical currency. This shift has increased transparency and reduced the shadow economy.<br>
        <b>3. Boost for Small Businesses:</b><br/>
        For small businesses and micro-entrepreneurs, UPI has been a game-changer. It has enabled them to accept digital payments with minimal infrastructure, expanding their customer base and improving their revenue streams.<br/>
        <b>4. Innovation and Competition:</b><br/>
        UPI has spurred innovation and competition among payment service providers, leading to the development of new financial products and services. This competitive environment has driven improvements in the user experience and expanded payment options.<br/><br/>
        <b>Future of UPI</b><br/>
        The future of UPI looks promising, with ongoing developments and innovations. The introduction of features like UPI 2.0, which includes overdraft facilities, one-time mandates, and invoice verification, is set to enhance its functionality further. Additionally, the integration of UPI with international payment systems and the expansion of its use cases beyond traditional banking will likely drive its continued growth.<br/><br/>
        <b>Conclusion</b><br/>
        UPI has revolutionised the payment landscape in India, making digital transactions simple, secure, and accessible to all. Its impact on financial inclusion, economic growth, and digital transformation has been significant, and its future looks bright with continued innovation and expansion. As UPI continues to evolve, it is set to play a pivotal role in shaping the future of payments in India and beyond. <br/> 
      `,
    },
    {
      date: 'July 09, 2024',
      title: 'The Simplicity of Making and Receiving Payments Using QR Codes',
      image: cardImage2,
      details: `
        In today's fast-paced digital world, the way we handle transactions has evolved significantly. Gone are the days of carrying wads of cash or fumbling for the right change. With the advent of technology, payments have become more convenient and secure. One of the most revolutionary developments in this space is the use of QR codes for making and receiving payments. Let's explore how this simple yet powerful tool is transforming the way we handle our finances.<br/><br/>
        <b>1. What Are QR Codes?</b><br />
        QR (Quick Response) codes are two-dimensional barcodes that can store a wealth of information. Originally developed for the automotive industry in Japan, QR codes have found applications across various sectors, including payments. They can be scanned using a smartphone camera, making them an accessible and user-friendly option for both businesses and consumers.<br /><br/>
        <b>2. The Simplicity of QR Code Payments - </b><br /><br/>
        <b>1. Easy Setup :</b><br />
       Setting up QR code payments is straightforward. Businesses can generate a unique QR code linked to their payment account using a variety of payment platforms. This code can be printed and displayed at checkout counters, integrated into digital receipts, or shared electronically with customers.<br />
        <b>2. User-Friendly Experience:</b><br />
        For consumers, making a payment is as simple as scanning the QR code with their smartphone camera. Most smartphones now come with built-in QR code scanning capabilities, eliminating the need for additional apps. Once scanned, the user is directed to a secure payment gateway where they can complete the transaction with just a few taps. Security is a top priority for Bharat Connect Bill and payment app companies. These platforms typically employ state-of-the-art encryption and authentication protocols to safeguard users' financial data. Users can trust that their transactions are secure and their personal information is protected.<br />
        <b>3. Speed and Efficiency:</b><br />
        QR code payments are incredibly fast. There’s no need to enter card details or wait for card readers to process the information. This speed enhances the customer experience and reduces wait times at checkout, making it a win-win for both parties involved.<br/>
        <b>4. Security:</b><br />
        Security is a major concern in any transaction. QR codes can be encoded with encrypted data, ensuring that sensitive information is protected. Many payment platforms also offer additional layers of security, such as two-factor authentication, to safeguard transactions.<br/><br/>
        <b>Advantages for Businesses -</b><br /><br/>
        <b>1. Cost-Effective:</b><br />
        Implementing QR code payments is cost-effective, especially for small businesses. There’s no need to invest in expensive point-of-sale systems or card readers. All that's required is a smartphone or tablet to manage transactions.<br/>
        <b>2. Enhanced Customer Engagement:</b><br />
        By incorporating QR codes into marketing materials, businesses can engage customers more interactively. For instance, QR codes can link to promotional offers, loyalty programmes, or surveys, creating a more immersive shopping experience.<br/><br />
        <b>Advantages for Consumers - </b><br /><br />
        <b>1. Contactless Transactions:</b><br />
        In a world where health and safety are paramount, QR code payments offer a contactless solution. This minimises physical contact, making transactions safer during times of health crises, such as the COVID-19 pandemic.<br/>
        <b>2. Flexibility:</b><br />
        Consumers can link multiple payment methods, such as credit cards, debit cards, or digital wallets, to their QR code apps, offering flexibility and convenience in how they choose to pay.<br/>
        <b>3. Record Keeping:</b><br />
        Digital transactions via QR codes automatically generate electronic records, helping consumers keep track of their spending effortlessly.<br /><br />
        <b>The Future of QR Code Payments - </b><br/>
        As technology continues to advance, the use of QR codes in payment systems is expected to grow. Innovations such as dynamic QR codes, which can change data with each transaction for added security, are already being developed. Moreover, the integration of QR codes with emerging technologies like blockchain and cryptocurrencies holds exciting possibilities for the future of digital payments.<br/><br />
        <b>Conclusion</b><br/>
        QR codes have simplified the way we make and receive payments, offering a fast, secure, and user-friendly alternative to traditional payment methods. Whether you’re a business owner looking to streamline operations or a consumer seeking convenience, QR codes provide an efficient solution for all. As they continue to evolve, QR codes are set to play an increasingly vital role in the digital economy, transforming the way we conduct transactions for years to come. 
  
      `,
    },
    {
      date: 'July 24, 2024',
      title: 'Transforming Convenience: The Rise of Bharat Connect Services and Payment Apps',
      image: blogimage1,
      details: `
        In today's fast-paced, digital world, the way we manage our financial obligations and expenses has undergone a revolutionary transformation. Gone are the days of standing in long queues to pay Bharat Connect Bills, writing out countless checks, or hunting for the nearest ATM. Thanks to technology and the advent of Bharat Connect Bill and payment apps, managing financial transactions has become a breeze. In this blog, we will explore how these apps have changed the way we handle Bharat Connect Bills and other payments<br/><br/>
        <b>1.	Streamlined Convenience</b><br />
       One of the most significant benefits of Bharat Connect Bill and payment apps is the streamlined convenience they offer. These apps bring together various payment options together on one platform, allowing users to make payments for a wide range of services and bills. Whether it's electricity, water, gas, internet, or even credit card bills, these apps provide a one-stop solution, saving users time and effort.<br/>
       <b>2.Digital Wallet Integration</b><br/>
       Bharat Connect Services and payment apps often come with integrated digital wallets, making it easy for users to add and store funds securely. These digital wallets enable users to pay bills and make purchases without having to enter credit card or bank account information every time. This not only speeds up the payment process but also enhances security by reducing the need to share sensitive financial data.<br/>
       <b>3.	Payment Reminders</b><br/>
       No more late fees and disconnections! These apps often provide automated payment reminders to ensure that you never miss a due date. Users can set up reminders for specific bills, helping them stay on top of their financial obligations and avoid the hassles of late payments.<br/>
       <b>4.	Secure Transactions</b><br/>
       Security is a top priority for Bharat Connect Bill and payment app companies. These platforms typically employ state-of-the-art encryption and authentication protocols to safeguard users' financial data. Users can trust that their transactions are secure and their personal information is protected.<br/>
       <b>5.	Historical Payment Records</b><br/>
       Keeping track of past payments can be a headache, especially if you're managing multiple bills. Bharat Connect Services and payment apps maintain a record of all your transactions, making it easy to access payment history whenever you need it. This feature is incredibly useful for budgeting and tax purposes.<br/>
       <b>6.	Accessibility</b><br/>
       The beauty of these apps lies in their accessibility. Most of them are available on both iOS and Android platforms, and many also offer web-based access. This means you can manage your payments from your smartphone, tablet, or computer, ensuring that you have control over your finances wherever you go.<br/>
       <b>7.	Real-Time Updates</b><br/>
       Bharat Connect Services and payment apps often provide real-time updates on payment status. Users receive confirmation as soon as their payments are processed, eliminating any doubts about the success of the transaction.<br/>
       <b>8. Rewards and Cashback</b><br/>
       To sweeten the deal, many Bharat Connect Bill and payment apps offer rewards, cashback, and loyalty programs. Users can earn points or cashback on transactions, which can be redeemed for discounts or future bill payments, making the entire process even more rewarding.<br/><br/>
       <b>Conclusion</b><br/>
       With their user-friendly interfaces, digital wallets, payment reminders, and real-time updates, these apps are helping individuals and businesses alike streamline their financial obligations. If you haven't already embraced this modern payment method, it might be time to make the switch and enjoy the convenience and efficiency of Bharat Connect Bill and payment apps. Say goodbye to the days of standing in long lines and hello to a simpler, more secure, and more rewarding way to manage your finances.
      `,
    }
  ];


  const cardData = [
    // {
    //   title: "iPaisa Bharat Connect Service",
    //   description: "iPaisa offers secure, convenient Bharat Connect service for paying bills, with real-time updates and multiple payment options.",
    //   image: BBPSLogo,
    //   bgColor: "#FFFFFF",
    //   textColor: "#0B1131",
    // },
    {
      title: "Your Flight, Your Way",
      description: "Explore a world of affordable travel with our tailored flight options. Whether you're planning a quick getaway or a dream vacation, find the best deals and enjoy a seamless booking experience.",
      image: CardFlightImg,
      bgColor: "#87CEEB",
      textColor: "#000000",
    },
    {
      title: "Bus Booking",
      description: "Make your journey stress-free with our reliable bus booking services. From daily commutes to long-distance travels, enjoy convenience, comfort, and choices that suit every traveler.",
      image: CardBusImg,
      bgColor: "#111849",
      textColor: "#FFFFFF",
    },    
    {
      title: "Our Services",
      description: "Your One-Stop Solution for Travel Needs: Seamless Flights, Hotels, and Bus Bookings with our exceptional service & unbeatable prices!",
      image: OurServices,
      bgColor: "#3029D9",
      textColor: "#FFFFFF",
    },
    // {
    //   title: "Bill Payments",
    //   description: "iPaisa makes bill payments easy with automated reminders, secure transactions, and real-time tracking features.",
    //   image: utilitypayment,
    //   bgColor: "#111849",
    //   textColor: "#FFFFFF",
    // },
    {
      title: "Carbon Credit Remittance",
      description: "Streamline your agricultural payments with our trusted platform, designed to meet the needs of farmers and suppliers.",
      image: FarmerImg,
      bgColor: "#FFA500",
      textColor: "#FFFFFF",
    },
  ];


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Header />
      {/* Carousel Section */}
      <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Swiper
        modules={[ Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        loop
        spaceBetween={30}
        slidesPerView={1}
      >
        <SwiperSlide>
          <img src={FlightBookingImg} alt="Farmer Image 1" style={{ width: '100%', height: 'auto' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={BusBookingImg} alt="Farmer Image 1" style={{ width: '100%', height: 'auto' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={HotelBookingImg} alt="Farmer Image 1" style={{ width: '100%', height: 'auto' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={FarmerCarausel1} alt="Farmer Image 1" style={{ width: '100%', height: 'auto' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={FarmerCarausel2} alt="Farmer Image 2" style={{ width: '100%', height: 'auto' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={FarmerCarausel3} alt="Farmer Image 3" style={{ width: '100%', height: 'auto' }} />
        </SwiperSlide>
      </Swiper>
      </Box>

{/* First Section */}
<Box
  sx={{
    minHeight: "450px",
    background: "radial-gradient(circle, #5975FF 0%, #3029D9 100%)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    padding: "2rem",
  }}
>
  {/* Left Side: Logo and Text */}
  <Grid
    item
    xs={12}
    md={6}
    sx={{
      mt: { xs: 1, md: 0 },
      textAlign: { xs: "center", md: "left" },
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", md: "flex-start" },
        mb: { xs: 2, md: 3 },
      }}
    >
      <CardMedia
        component="img"
        image={BBPSLogo}
        alt="BBPS Logo"
        sx={{
          height: "60px",
          width: "180px",
          backgroundColor: "white",
          mr: 2,
        }}
      />
      <Typography
        sx={{
          color: "white",
          fontSize: { xs: "24px", md: "32px" },
          textAlign: "left",
          fontWeight:'bold',
        }}
      >
        We Are Authorized Bharat Connect Agent Institution
      </Typography>
    </Box>
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: {
          xs: "24px",
          sm: "28px",
          md: "32px",
          lg: "40px",
          xl: "55px",
        },
        lineHeight: {
          xs: "32px",
          sm: "36px",
          md: "48px",
          lg: "60px",
          xl: "80px",
        },
        color: "white",
      }}
    >
      Top-Notch, Highly<br />
      Secured Technology!
    </Typography>
    <Typography
      sx={{
        height: "auto",
        fontSize: { xs: "12px", md: "16px" },
        color: "white",
        lineHeight: { xs: "20px", md: "28px" },
        mt: 2,
        whiteSpace: { xs: "normal", md: "pre-line" },
        textAlign: { xs: "center", md: "left" },
      }}
    >
      Welcome to iPaisa, your one-stop solution for seamless and secure mobile payments through Bharat Connect. With a user-friendly interface and a wide range of services, we bring you convenience like never before. Flight Bookings, Hotel Bookings, UPI payment, Bharat Connect Services, Send money, recharge, and more, all at your fingertips.
    </Typography>
    <Button
      variant="contained"
      sx={{
        color: "white",
        backgroundColor: "#111849",
        borderRadius: "4px",
        height: "30px",
        width: "166px",
        mt: 3,
        textTransform: "none",
        "&:hover": {
          backgroundColor: "white",
          color: "#3029D9",
          fontWeight: "bold",
        },
      }}
      onClick={handleOpenContactUs}
    >
      Let's Talk
    </Button>
  </Grid>

  {/* Right Side: Images */}
  <Grid
    item
    xs={12}
    md={6}
    sx={{
      mt: { xs: 3, md: 0 },
      display: "flex",
      justifyContent: "center",
    }}
    style={{ marginLeft: "7%" }}
  >
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        src={BGIMG3}
        style={{
          width: "130%",
          height: "auto",
        }}
        alt="Background"
      />
      <CardMedia
        component="img"
        image={homeMobile}
        alt="Home Mobile"
        sx={{
          width: { xs: "60%", md: "100%" },
          height: "auto",
          position: "absolute",
          top: { xs: "10%", md: "40%" },
          transform: { xs: "translateY(0)", md: "translateY(-40%)" },
          right: { xs: "10%", md: "150px" },
          left: { xs: "10%", md: "4%" },
        }}
      />
    </div>
  </Grid>
</Box>


      <Box
  sx={{
    padding: "2rem",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row", // Align items horizontally
    alignItems: "center",
    justifyContent: "space-around", // Add space between image and text
    gap: "1rem", // Add some spacing between image and text for smaller screens
    flexWrap: "wrap", // Ensure responsiveness
  }}
>
  {/* Image Section */}
  <img
    src={AirCraftHomeImg} // Use your imported image here
    alt="Aircraft Image"
    style={{
      width: "100%",
      maxWidth: "600px", // Restrict max size of the image
      height: "auto",
    }}
  />

  {/* Text Content */}
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      maxWidth: "600px", // Ensure text doesn't stretch too wide
    }}
  >
    <Typography
      sx={{
        fontSize: { xs: "16px", sm: "20px", md: "22px" },
        color: "#3029D9",
        textAlign: "left", // Align text to the left
        fontWeight: "600",
      }}
    >
      Discover the World with Ease
    </Typography>
    <Typography
      sx={{
        fontSize: { xs: "28px", sm: "32px", md: "36px" },
        color: "#000000",
        textAlign: "left", // Align text to the left
        fontWeight: "600",
      }}
    >
      Book Your Flights, <br/>
      Anytime, Anywhere
    </Typography>
    <Typography
      sx={{
        fontSize: { xs: "14px", sm: "16px", md: "18px" },
        color: "#4D5156",
        textAlign: "left", // Align text to the left
        marginTop: "1rem",
      }}
    >
      Unbeatable Prices | ✈️ Wide Range of Airlines |<br/> 📅 Flexible 
      Booking Options
    </Typography>
    <Typography color='#4D5156' sx={{marginLeft:'1rem'}}>
      • Plan Your Next Adventure: Compare, book, and fly effortlessly. <br/>
      • Exclusive Deals: Save big on domestic and international flights..<br/>
      • 24/7 Support: We're here to help, every step of the way..<br/>
    </Typography>
    <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#3029D9",
              borderRadius: "4px",
              height: "30px",
              width: "166px",
              mt: 3,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "#3029D9",
                fontWeight: "bold",
              },
            }}
            onClick={handleSearchFlights}
          >
            Search Flights Now
          </Button>
  </Box>
</Box>



      {/* Second */}
      <Box
        sx={{
          padding: "2rem",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: "2rem",
            marginLeft: "8%",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            sx={{
               
              fontWeight: "600",
              fontSize: "24px",
              color: "#3029D9",
            }}
          >
            How it works
          </Typography>
          <Typography
            sx={{
               
              fontWeight: "700",
              fontSize: { xs: "32px", sm: "40px", md: "48px" },
              color: "#0B1131",
              marginBottom: "1rem",
              lineHeight: {
                xs: "32px",
                sm: "36px",
                md: "55px",
                lg: "60px",
                xl: "80px",
              },
            }}
          >
            We Offer A Seamless <br />
            Payment Solution <br />
            Reliable, Fast And  <br />
            Secure
          </Typography>
          <Typography
            // sx={{
            //   color: "#0B1131",
            //    
            //   fontWeight: "500",
            //   fontSize: "16px",
            //   lineHeight: {
            //     xs: "32px",
            //     sm: "36px",
            //     md: "48px",
            //     lg: "28px",
            //     xl: "28px",
            //   },
            // }}
            sx={{
              height: "auto",
              fontSize: { xs: "12px", md: "16px" },
              color: "#000000",
              lineHeight: { xs: "20px", md: "28px" }, // Adjust lineHeight for mobile and desktop
              mt: 2,
               
              // Hide line breaks on mobile
              whiteSpace: { xs: "normal", md: "pre-line" },
              // Adjust text alignment
              textAlign: { xs: "center", md: "left" },
              // Apply custom style for text breaks on desktop
              "::after": {
                content: { xs: '""', md: "none" }, // Add line breaks only for desktop
              },
            }}
          >
            iPaisa simplifies financial transactions by offering a secure
            {"\n"} and user-friendly platform. It provides a one-stop solution
            for
            {"\n"} UPI payments, Bharat Connect Bill payments, merchant transactions,
            {"\n"} mobile recharges, and DTH payments. With bill reminders
            {"\n"} and transaction history features, iPaisa ensures a convenient
            {"\n"} and organized payment experience.
          </Typography>

          <Button
            variant="contained"
            sx={{ color: "white", backgroundColor: "#3029D9", marginTop: "2%",
              textTransform:'none',
              '&:hover': {
                  backgroundColor: "black",
                  color: '#ffffff',
                  fontWeight: "bold",
                },
             }}
            onClick={handleOpenAboutUs}
          >
            Read More
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            mt: { xs: 3, md: 0 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={countimg}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: { xs: "100%", md: "80%" },
              }}
              alt="Background"
            />
          </div>
        </Grid>
      </Box>

      {/* Third */}
      <Container
      sx={{
        padding: { xs: "20px", md: "0" },
        marginTop: "3%",
        marginBottom: "3%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          color: "#0B1131",
          fontWeight: 700,
          fontSize: { xs: "40px", md: "64px" },
          marginBottom: { xs: "20px", md: "40px" },
        }}
      >
        What We Offer
      </Typography>

      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                height: "100%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: card.bgColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "20px",
                borderRadius:'12px'
              }}
            >
              <CardMedia
                component="img"
                src={card.image}
                alt={card.title}
                sx={{ height: "140px", width: "auto", marginBottom: "20px" }}
              />
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  color: card.textColor,
                  marginBottom: "10px",
                }}
              >
                {card.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: card.textColor,
                }}
              >
                {card.description}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>

      {/* Forth */}
      <div
        style={{
          background: "radial-gradient(circle, #5975FF 0%, #3029D9 100%)",
          marginTop: "150px",
        }}
      >
        <Container
          sx={{
            marginTop: "3%",
            height: "auto",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "20px", md: "0", xl: "50px" },
            position: "relative",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              padding: "2rem",
              marginLeft: { xs: "0", md: "1%" }, // Adjust margin for mobile screens
              marginBottom: { xs: "10%", md: "20%" }, // Adjust margin for mobile screens
              textAlign: { xs: "center", md: "left" },
              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                 
                fontWeight: 600,
                fontSize: "24px",
                color: "#5975FF",
                marginTop: { xs: "10%", md: "30%" }, // Adjust margin for mobile screens
                lineHeight: "68px",
              }}
            >
              Key Features
            </Typography>
            <Typography
              sx={{
                 
                fontWeight: 700,
                fontSize: { xs: "32px", sm: "40px", md: "48px" },
                color: "white",
                marginBottom: "1rem",
                lineHeight: "55px",
              }}
            >
              Manage Your
              <br />
              Finances From <br />
              One Place
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                 
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                marginTop: "10%",
              }}
            >
              Bharat Connect: Easily pay your bills.
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                 
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                marginTop:'2%',
              }}
            >
              QR Code Scanning: Simply scan QR code to make quick
              payments at stores and merchants.
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                 
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                marginTop: "1%",
              }}
            >
              Cashback Rewards: Earn exciting cashback on every
              <br />
              transaction, making your payments even more rewarding.
            </Typography>

            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#111849",
                marginTop: "6%",
                 textTransform: 'none',
               '&:hover': {
                  backgroundColor: "white",
                  color: '#3029D9',
                  fontWeight: "bold",
                },
              }}
              onClick={handleOpenServices}
            >
              Check All Features
            </Button>
          </Grid>
          <Grid style={{paddingLeft:'10%'}}>
            <img src={maskLady} alt="Image" style={{width:'100%'}} />
          </Grid>


        </Container>
      </div>

      {/* Fifth2 */}

      <Box
        sx={{
          height: { xs: "auto", md: "960px" },
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
        }}
      >
        <Grid
          sx={{
            width: { xs: "100%", md: "622px" },
            marginLeft: { xs: "0", md: "10%" },
            height: "auto",
            display: "flex",
            // marginTop: "2%",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "90%", md: "300px" },
              marginBottom: { xs: "2rem", md: "0" },
            }}
          >
            <Card
              sx={{
                width: { xs: "100%", md: "290px" },
                height: { xs: "auto", md: "284px" },
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: "1rem",
              }}
            >
              <Box
                sx={{
                  width: { xs: "80px", md: "100px" },
                  height: { xs: "80px", md: "100px" },
                  borderRadius: "156px 156px 156px 0",
                  backgroundColor: "#3029D9",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: { xs: "5%", md: "8%" },
                  textAlign: "center",
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                }}
              >
               <GameIcon/>
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                   
                  fontSize: { xs: "20px", md: "24px" },
                  lineHeight: "34px",
                  fontWeight: 700,
                  marginTop: "4%",
                }}
              >
                Innovative
                <br /> Solutions
              </Typography>
            </Card>

            <Card
              sx={{
                width: { xs: "100%", md: "290px" },
                height: { xs: "auto", md: "284px" },
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: "0rem",
              }}
            >
              <Box
                sx={{
                  width: { xs: "80px", md: "100px" },
                  height: { xs: "80px", md: "100px" },
                  borderRadius: "156px 156px 156px 0",
                  backgroundColor: "#3029D9",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: { xs: "5%", md: "8%" },
                  textAlign: "center",
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                }}
              >
                <CallIcon/>
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                   
                  fontSize: { xs: "20px", md: "24px" },
                  lineHeight: "34px",
                  fontWeight: 700,
                  marginTop: "4%",
                }}
              >
                24/7
                <br />
                Support
              </Typography>
            </Card>
          </Grid>

          <Grid
            sx={{
              width: { xs: "90%", md: "250px" },
              marginLeft: { xs: "0", md: "4%" },
              marginBottom: { xs: "2rem", md: "0" },
            }}
          >
            <Card
              sx={{
                marginTop: { xs: "0", md: "50%" },
                width: { xs: "100%", md: "290px" },
                height: { xs: "auto", md: "284px" },
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: "1rem",
              }}
            >
              <Box
                sx={{
                  width: { xs: "80px", md: "100px" },
                  height: { xs: "80px", md: "100px" },
                  borderRadius: "156px 156px 156px 0",
                  backgroundColor: "#3029D9",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: { xs: "5%", md: "8%" },
                  textAlign: "center",
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                }}
              >
               <TeamUserIcon/>
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "20px", md: "24px" },
                  lineHeight: "34px",
                  fontWeight: 700,
                  marginTop: "4%",
                }}
              >
                Professional
                <br /> Team
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: { xs: "1rem", md: "2rem" },
            marginLeft: { xs: "0", md: "8%" },
            marginTop: { xs: "2%", md: "7%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            sx={{
               
              fontWeight: "600",
              fontSize: { xs: "20px", md: "24px" },
              color: "#3029D9",
            }}
          >
            Start Now
          </Typography>
          <Typography
            sx={{
               
              fontWeight: "700",
              fontSize: { xs: "28px", sm: "40px", md: "48px" },
              color: "#0B1131",
              marginBottom: "1rem",
              lineHeight: { xs: "48px", md: "68px" },
            }}
          >
            Pay All Your Bills <br/> in One Place
          </Typography>
          <Typography
            sx={{
              height: "auto",
              fontSize: { xs: "12px", md: "14px" },
              color: "#000000",
              lineHeight: { xs: "20px", md: "28px" }, // Adjust lineHeight for mobile and desktop
              mt: 2,
               
              // Hide line breaks on mobile
              whiteSpace: { xs: "normal", md: "pre-line" },
              // Adjust text alignment
              textAlign: { xs: "center", md: "left" },
              // Apply custom style for text breaks on desktop
              "::after": {
                content: { xs: '""', md: "none" }, // Add line breaks only for desktop
              },
            }}
          >
            Are you tired of managing multiple bills and visiting
            {"\n"} different websites or offices to pay them? We understand
            {"\n"} the hassle, and that's why we offer a comprehensive bill
            {"\n"} payment service that covers a wide range of utilities and
            {"\n"} services.
          </Typography>
          <Typography
            sx={{
              height: "auto",
              fontSize: { xs: "12px", md: "14px" },
              color: "#000000",
              lineHeight: { xs: "20px", md: "28px" }, // Adjust lineHeight for mobile and desktop
              mt: 2,
               
              // Hide line breaks on mobile
              whiteSpace: { xs: "normal", md: "pre-line" },
              // Adjust text alignment
              textAlign: { xs: "center", md: "left" },
              // Apply custom style for text breaks on desktop
              "::after": {
                content: { xs: '""', md: "none" }, // Add line breaks only for desktop
              },
            }}
          >
            At iPaisa, we understand that making payments for
            {"\n"} buying daily things can be a routine task, but it doesn't
            {"\n"} have to be boring or unrewarding. That's why we're thrilled
            {"\n"} to introduce our exclusive cashback offers and discounts{" "}
            {"\n"}
            for our valued users.{" "}
          </Typography>
        </Grid>
      </Box>
  {/* sixth */}
      <Grid
        style={{
          height: "auto",
          padding:'0rem 2rem 2rem 2rem',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
          <Grid style={{ marginBottom: '7%' }}>
          <Grid>
            <Typography variant="h6" gutterBottom align="center" style={{ fontWeight: 'bold', color: '#3029D9' }}>
              Our Blogs
            </Typography>
          </Grid>
          <Typography variant="h4" gutterBottom align="center" style={{ fontWeight: 'bold', marginBottom: '40px' }}>
            Latest News
          </Typography>
          <Grid container spacing={4} marginTop='1%'>
            {cardContent.map((card, index) => (
              <Grid item xs={12} md={4} key={index} sx={{ display: 'flex' }}>
                <CustomCard elevation='2'>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <img src={card.image} alt="Blog" style={{ width: '100%', borderRadius: '16px 16px 0 0' }} />
                    <Box sx={{ p: 2 }}>
                      <Typography style={{ color: '#3029D9', fontWeight: 'bold' }} variant="body2" color="textSecondary" gutterBottom>
                        {card.date}
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }} variant="h6" component="div" gutterBottom>
                        {card.title}
                      </Typography>
                    </Box>
                  </CardContent>
                  <Box sx={{ display: 'flex', p: 1, marginLeft: '4%', paddingBottom: '5%' }}>
                    <Button
                      sx={{
                        backgroundColor: '#3029D9',
                        color: 'white',
                        textTransform: 'none',
                        fontSize: '14px',
                        width: '40%',
                        '&:hover': {
                          backgroundColor: '#3029D9', // Keep the same background color
                          color: 'white', // Keep the same text color
                        }
                      }}
                      onClick={() => handleeClickOpen(index)} // Open dialog for the respective blog
                    >
                      Read More
                    </Button>
                  </Box>
                </CustomCard>
                <Dialog open={openDialogs[index]} onClose={() => handleClose(index)} maxWidth="md" fullWidth>
                  <DialogTitle>
                    <Typography variant="h6">{card.title}</Typography>
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={() => handleeClose(index)}
                      aria-label="close"
                      sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <div dangerouslySetInnerHTML={{ __html: card.details }} />
                  </DialogContent>
                </Dialog>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
{/* seventh */}
      <div style={{ backgroundColor: "#3029D9" }}>
        <Container
          sx={{
            padding: { xs: "1rem", sm: "2rem" }, // Responsive padding
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "auto", md: "400px" }, // Responsive height
            textAlign: "center", // Center text alignment
          }}
        >
          <Typography
            sx={{
               
              fontWeight: "600",
              fontSize: { xs: "20px", sm: "24px" }, // Responsive font size
              color: "#FFFFFF",
              lineHeight: { xs: "1.5", sm: "68px" },
              marginBottom: "1rem", // Margin bottom for spacing
            }}
          >
            Contact with us
          </Typography>
          <Typography
            sx={{
              color: "white",
               
              fontWeight: "700",
              fontSize: { xs: "36px", sm: "48px", md: "64px" }, // Responsive font size
              lineHeight: { xs: "1.2", sm: "68px" },
              marginBottom: "1rem", // Margin bottom for spacing
            }}
          >
            Subscribe
            <br />
            to Newsletter
          </Typography>
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#111849",
              borderRadius: "4px",
              height: "39px",
              marginTop: "4%",
              padding: { xs: "0.5rem 1rem", sm: "1rem 2rem" }, // Responsive padding
              fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
              textTransform: 'none',
              '&:hover': {
                  backgroundColor: "white",
                  color: '#3029D9',
                  fontWeight: "bold",
                },
            }}
            onClick={handleOpenContactUs}
          >
            Subscribe
          </Button>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Home;
