import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, Paper, CircularProgress, Container, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast
import axios from 'axios'; // Import axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import IpinModal from './IpinModal';
import { NoteIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import logo from "../Assets/login/iPaisaLogo2.png";


const ForgotPin2 = () => {
  const [openModal, setOpenModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading
  const [isMobileValid, setIsMobileValid] = useState(false); // To check if the mobile number is valid
  const [otpSent, setOtpSent] = useState(false); // State to manage if OTP has been sent
  const navigate = useNavigate(); // Initialize useNavigate

  const handleOpenModal = async () => {
    setLoading(true);
    setIsMobileValid(false);
    setOtpSent(false); // Reset OTP sent state

    if (phoneNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit mobile number.");
      setLoading(false);
      return;
    }

    try {
      // Validate mobile number API call
      const validateResponse = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/validatemobilenumber",
        { mobileno: phoneNumber }
      );

      if (validateResponse.data.mobileValidate) {
        setIsMobileValid(true);
        // Send OTP to the mobile number
        const otpResponse = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/otpmsg",
          { mobileNumber: phoneNumber }
        );

        if (otpResponse.status === 200) {
          toast.success("OTP has been sent to your WhatsApp Number");
          setOtpSent(true); // Mark that OTP has been sent
        }
      } else {
        toast.error("Mobile number is already registered.");
      }
    } catch (error) {
      console.error("Error validating mobile number:", error);
      toast.error("Error validating mobile number");
    } finally {
      setLoading(false); // Set loading to false after API call is complete
    }
  };

  // Handle OTP validation
  const handleOtpSubmit = async () => {
    if (!otp) {
      toast.error("Please enter the OTP.");
      return;
    }

    setLoading(true);
    try {
      const otpValidationResponse = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/validateotp",
        { mobileno: phoneNumber, otp: otp } // Use the saved mobile number
      );

      if (otpValidationResponse.data.status === "success") {
        toast.success("OTP verified successfully.");

        // Close any active toasts
        toast.dismiss();

        // Set a timeout for navigation
        setTimeout(() => {
          navigate('/resetipaisampin'); // Navigate to the Reset Password route
        }, 2000); // 2000 milliseconds = 2 seconds
      } else {
        toast.error("Invalid OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP");
    } finally {
      setLoading(false);
    }
  };


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClear = () => {
    setPhoneNumber('');
    setOtp('');
  }

  return (
    <>
      {/* <ToastContainer /> */}
      <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Settings</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Forgot IPIN</Typography>
            </Grid>
            <Grid style={{marginTop:'2%'}}>
              <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Forgot IPIN</Typography>
            </Grid>
      {/* IpinModal Component */}
        <IpinModal open={openModal} onClose={handleCloseModal} />

        <Paper elevation={2} sx={{ padding: '2rem', borderRadius:'12px', marginTop:'3%' }}>
                <Typography style={{fontSize:'12px', color:'#666', display:'flex', alignItems:"center", gap:'1%'}} >
                  <NoteIcon/>
                  Enter your registered WhatsApp Number below, and we'll send you a OTP to reset your IPIN.                
                </Typography>
            <form>
            <TextField
                fullWidth
                label="WhatsApp Number"
                variant="outlined"
                margin="normal"
                required
                style={{ marginBottom: '1.5rem' }}
                value={phoneNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numeric input, no alphabets
                  if (/^\d*$/.test(value)) {
                    setPhoneNumber(value);
                  }
                }}
              />
             {otpSent && (
              <TextField
                fullWidth
                label="Enter OTP"
                variant="outlined"
                margin="normal"
                required
                style={{ marginBottom: '1.5rem' }}
                value={otp}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numeric input, no alphabets
                  if (/^\d*$/.test(value)) {
                    setOtp(value);
                  }
                }}
              />
            )}
              <Grid item xs={12} style={{marginTop:'3%'}}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                  <Button variant="text"
                  onClick={handleClear}
                    sx={{ 
                      textTransform:'none',
                      width:'18%',
                      height:'50px',
                      borderRadius:'8px',
                      color:'#808080',
                      border:'1px solid #D9D9D9',
                  }}>
                    Cancel
                  </Button>
                  {/* Show CircularProgress if loading */}
                  {loading ? (
                    <CircularProgress size={24} style={{ marginLeft: '16px' }} />
                  ) : (
                    <>
                      {!otpSent ? (
                        <Button
                        sx={{
                          backgroundColor: '#027BFE',
                          color:'white',
                          textTransform:'none',
                          width:'18%',
                          borderRadius:'8px',
                          height:'50px',
                          ':hover': {
                            backgroundColor: '#0056b3',
                          },
                        }}
                        onClick={handleOpenModal}
                        >
                          Send OTP
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: '#007bff' }}
                          onClick={handleOtpSubmit}
                        >
                          Verify OTP
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </form>
          </Paper>
      </Container>
    </>
  );
};

export default ForgotPin2;
