
import axios from 'axios';
import { FETCH_WALLET_BALANCE, FETCH_PARTNER_DATA, FETCH_ERROR, FETCH_CHARGEBACK_BALANCE } from './types';

export const fetchWalletBalance = () => async dispatch => {
    try {
        const token = sessionStorage.getItem('jwtToken');
        const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/getwalletbalance', {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch({ type: FETCH_WALLET_BALANCE, payload: response.data.walletBalance });
        
        dispatch({ type: FETCH_CHARGEBACK_BALANCE, payload: response.data.chargeBackBalance });
    } catch (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
    }
};

export const fetchPartnerData = () => async dispatch => {
    try {
        const token = sessionStorage.getItem('jwtToken');
        const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/count', {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch({ type: FETCH_PARTNER_DATA, payload: response.data.data });
    } catch (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
    }
};
