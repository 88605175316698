import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Check, Dangerous } from "@mui/icons-material";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));
const VirtualAccReqList = () => {
  const [reqList, setReqList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const fetchList = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await axios.get(
        // "https://api.ipaisa.co.in/myapp/virtual/reqacclist",

        "https://api.ipaisa.co.in/myapp/virtual/reqacclist",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
          params: {
            created_at: ``,
            created_at_end: ``,
            created_at_start: ``,
            current: "1",
            order_id: ``,
            pageSize: "10",
            status: ``,
            unique_transaction_reference: ``,
            virtual_account_id: ``,
          },
        }
      );
      //   console.log(response.data);
      const resultsArr = response.data;
      setReqList(resultsArr);
      //   console.log(resultsArr);
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };

  const onApprove = async (id) => {
    // console.log("onApprove");
    setIsSubmitting(true);
    const jwtToken = sessionStorage.getItem("jwtToken");
    try {
      const response = await axios.post(
        // "https://api.ipaisa.co.in/myapp/virtual/changereq",
        "https://api.ipaisa.co.in/myapp/virtual/changereq",
        {
          // This is the request body
          id: id,
          action: "APPROVE",
          // Add other fields as needed
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      //   console.log(response.data.message);
      if (response.status !== 200) {
        // toast.error("Error with " + response.status);
        Swal.fire({
          title: "Something Went Wrong..!!",
          text: "Your Request is Declined with status code " + response.status,
          icon: "error",
        });
        fetchList();
        setIsSubmitting(false);
      }
      Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
      fetchList();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const onReject = async (id) => {
    // console.log("onReject");
    setIsSubmitting(true);
    const gotid = id;
    const jwtToken = sessionStorage.getItem("jwtToken");
    try {
      const response = await axios.post(
        // "https://api.ipaisa.co.in/myapp/virtual/changereq",

        "https://api.ipaisa.co.in/myapp/virtual/changereq",
        {
          // This is the request body
          id: gotid,
          action: "REJECT",
          // Add other fields as needed
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      // toast.info(response.data.message);
      //   console.log(response.data.message);
      if (response.status !== 200) {
        // toast.error("Error with " + response.status);
        Swal.fire({
          title: "Something Went Wrong..!!",
          text: "Your Request is Declined with status code " + response.status,
          icon: "error",
        });
        fetchList();
        setIsSubmitting(false);
      }
      Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
      fetchList();
      setIsSubmitting(false);
      //   console.log(response);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
   <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Typography sx={{fontSize:'22px', fontWeight:'bold', marginBottom:'1%'}}>This is Queen QR Request List</Typography>
        <TableContainer component={Paper} sx={{boxShadow:'none', borderRadius:'12px 12px 0px 0px'}}>
          <Table>
            <TableHead
              style={{
                backgroundColor: "#027BFE",
                color: "white",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Sr no
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  User Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  User Type
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {trnsList && trnsList.length > 0 ? (
                trnsList.map((txn, i) => (
                  <TableRow */}

              {reqList && reqList.length > 0 ? (
                reqList.map((list, i) => (
                  // {console.log()}
                  <TableRow
                    key={list.id} // Use unique key based on the transaction ID
                    className={`${classes.tableRow} ${i % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell align="center">{i + 1}</TableCell>{" "}
                    {/* Serial Number */}
                    <TableCell align="center">{list.label}</TableCell>{" "}
                    {/* Label or Name */}
                    <TableCell align="center">{list.role}</TableCell>{" "}
                    {/* Role or User Role */}
                    <TableCell align="center">
                      {/* {console.log(list.id)} */}
                      <LoadingButton
                        onClick={() => onApprove(list.id)}
                        sx={{ backgroundColor: "green", color:"white", textTransform:"none" }}
                        endIcon={<Check />}
                        loading={isSubmitting}
                      >
                        Approve
                      </LoadingButton>
          
                      <LoadingButton
                        onClick={() => onReject(list.id)}
                        sx={{ backgroundColor: "red", marginLeft: "8px", textTransform:'none', color:'white' }}
                        endIcon={<Dangerous />}
                        loading={isSubmitting}
                      >
                        Reject
                      </LoadingButton>
                    </TableCell>
                    {/* Action Buttons */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No List or You are not Authorized!!!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default VirtualAccReqList;
