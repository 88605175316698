import React, { useState } from "react";
import PartnersTableTop from './PartnersTableTop'
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Grid, TablePagination, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
    MenuItem,
    Switch
} from '@mui/material';
import { Visibility, Edit, Delete, Add } from '@mui/icons-material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { makeStyles } from '@mui/styles';
import { BrowserView, MobileView } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const initialData = [
    { srNo: 1, techId: 'TS001', name: 'John Doe', expertise: 'Networking', location: 'New York', status: 'Active' },
    { srNo: 2, techId: 'TS002', name: 'Jane Smith', expertise: 'Hardware', location: 'San Francisco', status: 'Inactive' },
];

const TechS = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(initialData);
    const [newTechSupport, setNewTechSupport] = useState({
        techId: '',
        name: '',
        expertise: '',
        location: '',
        status: 'Active',
    });
    const [addTechSupportOpen, setAddTechSupportOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };

    const handleEditOpen = (row) => {
        setEditRow({ ...row });
        setEditOpen(true);
    };

    const handleAddTechSupportOpen = () => {
        setAddTechSupportOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditOpen(false);
        setAddTechSupportOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (editOpen) {
            setEditRow({ ...editRow, [name]: value });
        } else if (addTechSupportOpen) {
            setNewTechSupport({ ...newTechSupport, [name]: value });
        } else {
            setSearchQuery(event.target.value);
        }
    };

    const handleSave = () => {
        const updatedData = data.map((item) => (item.techId === editRow.techId ? editRow : item));
        setData(updatedData);
        handleClose();
    };

    const handleDelete = (row) => {
        const newData = data.filter((item) => item.techId !== row.techId);
        setData(newData);
    };

    const handleAddTechSupport = () => {
        const newTech = { ...newTechSupport, srNo: data.length + 1 };
        setData([...data, newTech]);
        handleClose();
    };

    const filteredData = data.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

  return (
    <>
<BrowserView>
    <Box sx={{ padding: 3, marginTop:{xs:'20%', md:'5%'},  backgroundColor: '#F4F5FA', height: '100vh' }}>
        <PartnersTableTop />
        <Grid style={{ marginTop: '3%' }}>
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE' }}>
                            <TableRow>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Sr.No</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Tech ID</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Name</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Expertise</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Location</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Status</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const shouldDisplayRow = Object.values(row).some((value) =>
                                        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                                    );

                                    if (!shouldDisplayRow) {
                                        return null;
                                    }

                                    return (
                                        <TableRow key={index}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.srNo}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.techId}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.name}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.expertise}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.location}</TableCell>
                                            <TableCell sx={{ textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.status === 'Active' ? (
                                                <Switch color="success" defaultChecked={true} style={{ display: 'none' }} />
                                            ) : (
                                                <Switch color="default" style={{ display: 'none' }} />
                                            )}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton color="primary" aria-label="view" onClick={() => handleClickOpen(row)}>
                                                    <Visibility />
                                                </IconButton>
                                                <IconButton color="primary" aria-label="edit" onClick={() => handleEditOpen(row)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton color="secondary" aria-label="delete" onClick={() => handleDelete(row)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>

                    </Table>
                </TableContainer>
        </Grid>
                <Grid container justifyContent="flex-end">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>

    </Box>
</BrowserView>
<MobileView>
        <Box sx={{ padding: 3, marginTop:{xs:'20%', md:'5%'},  backgroundColor: '#F4F5FA', height: '100vh' }}>
        <PartnersTableTop />
        <Grid style={{ marginTop: '3%' }}>
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE' }}>
                            <TableRow>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Sr.No</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Tech ID</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Name</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Expertise</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Location</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Status</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const shouldDisplayRow = Object.values(row).some((value) =>
                                        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                                    );

                                    if (!shouldDisplayRow) {
                                        return null;
                                    }

                                    return (
                                        <TableRow key={index}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.srNo}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.techId}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.name}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.expertise}</TableCell>
                                            <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.location}</TableCell>
                                            <TableCell sx={{ textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.status === 'Active' ? (
                                                <Switch color="success" defaultChecked={true} style={{ display: 'none' }} />
                                            ) : (
                                                <Switch color="default" style={{ display: 'none' }} />
                                            )}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton color="primary" aria-label="view" onClick={() => handleClickOpen(row)}>
                                                    <Visibility />
                                                </IconButton>
                                                <IconButton color="primary" aria-label="edit" onClick={() => handleEditOpen(row)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton color="secondary" aria-label="delete" onClick={() => handleDelete(row)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>

                    </Table>
                </TableContainer>
        </Grid>
                <Grid container justifyContent="flex-end">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>

    </Box>
</MobileView>
 </>

  )
}

export default TechS
