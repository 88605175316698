import React, { useState, useEffect } from "react";
import { styled, Box, Typography, Grid, Paper, Dialog, DialogContent, Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ToggleActivation = () => {
  const [isActive, setIsActive] = useState(false); // State to track activation status
  const [loading, setLoading] = useState(false); // Loading state
  const [modalOpen, setModalOpen] = useState(false); // Modal state
  const [modalMessage, setModalMessage] = useState(""); // Modal message

  // Fetch the current activation status on component mount
  useEffect(() => {
    const fetchActivationStatus = async () => {
      const token = sessionStorage.getItem("jwtToken"); // Get token from session storage
      try {
        const response = await fetch(
          "https://api.ipaisa.co.in/myapp/admin/status-users'",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json(); // Parse the response JSON
          if (data.status === "ACTIVE") {
            setIsActive(true); // Turn on the switch
          } else if (data.status === "INACTIVE") {
            setIsActive(false); // Turn off the switch
          }
        } else {
          console.error("Failed to fetch activation status");
        }
      } catch (error) {
        console.error("Error fetching activation status:", error);
      }
    };

    fetchActivationStatus();
  }, []);

  const handleToggle = async (event) => {
    const checked = event.target.checked; // Get the new toggle state
    setLoading(true);

    const token = sessionStorage.getItem("jwtToken"); // Get token from session storage
    try {
      const response = await fetch(
        "https://api.ipaisa.co.in/myapp/admin/activate_deactivate",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ activate: checked }), // Send the toggle state in the request payload
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response JSON
        if (data.status === "activated") {
          setIsActive(true); // Turn on the switch
          setModalMessage(data.message); // Show activation message
        } else if (data.status === "deactivated") {
          setIsActive(false); // Turn off the switch
          setModalMessage(data.message); // Show deactivation message
        }
        setModalOpen(true); // Open the modal
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating activation status:", error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  return (
    <>
      <Paper
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "18px",
          boxShadow: "none",
          padding: "1rem",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {isActive ? "Users Activated" : "Users Deactivated"}
          </Typography>
          <Box>
            <IOSSwitch
              checked={isActive}
              onChange={handleToggle}
              disabled={loading}
            />
          </Box>
        </Grid>
      </Paper>

      {/* Modal */}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: "2rem",
          }}
        >
          <CheckCircleIcon
            style={{
              fontSize: "3rem",
              color: "#4CAF50",
              marginBottom: "1rem",
            }}
          />
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginBottom: "1rem" }}
          >
            {modalMessage}
          </Typography>
          <Button
            style={{
              backgroundColor: "#027BFE",
              color: "#FFFFFF",
              padding: "0.5rem 2rem",
              borderRadius: "6px",
            }}
            onClick={handleCloseModal}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ToggleActivation;
