import React from 'react';
import { Button, Grid2, Typography, Dialog } from '@mui/material';
import { SuccessPopIcon } from '../../res/icons';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@material-ui/core';
import { BbpsBIcon, PrintIcon } from '../../res/NewWebIcons';
import logo from "../Assets/login/iPaisaLogo2.png";


const BillInvoicePopup = ({ handleClose }) => {
    const currentDateTime = new Date().toLocaleString(); // Getting current date and time

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '400px', // Set your desired width here
                    padding: '2rem' // Adjust padding as needed
                }
            }}
        >
          <Grid2>
            <Grid2>

              {/* Header Section */}
              <Grid2 sx={{display:'flex', justifyContent:'space-between', textAlign:'center', alignContent:'center', alignItems:'center'}}>
                <Grid2 sx={{cursor:'pointer'}}>
                  <PrintIcon />
                </Grid2>

                <Grid2>
                  <Typography style={{fontSize:'18px', fontWeight:'bold', color:'#343C6A'}}>Invoice</Typography>
                </Grid2>

                <Grid2>
                  <CloseIcon style={{cursor:'pointer'}} onClick={handleClose} />
                </Grid2>
              </Grid2>
              <Divider style={{marginTop:'3%', marginBottom:'3%' }}/>

              {/* Logo Section */}
              <Grid2 style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Grid2>
                    <img src={logo} alt="iPaisa Logo" style={{height: 53, width: 53, backgroundColor: 'transparent', borderRadius: '50%'}} />
                </Grid2>
                <Grid2 style={{ display: 'flex', justifyContent: 'center'}}>
                    <BbpsBIcon />
                </Grid2>
              </Grid2>

              {/* Transaction Date Section */}
              <Grid2 style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: '14px', color:'#27984C' }}>
                  Transaction Date and Time: {currentDateTime}
                </Typography>
              </Grid2>
              <Divider style={{marginTop:'3%', marginBottom:'3%' }}/>  

              {/* Details Section */}
              <Grid2 container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth:'100%' }}>
                {/* Left Side (Labels) */}
                <Grid2 container direction="column" spacing={1}>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Merchant Name</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Merchant Mobile Number</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Service Type</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Service Provider</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Consumer Number</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Transaction ID</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Bill Amount</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Transaction Status</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Biller ID</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Payment Channel</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>Payment Mode</Typography>
                    </Grid2>
                </Grid2>

                {/* Right Side (Values) */}
                <Grid2 container direction="column" spacing={1}>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>Naitik Vishwakarma</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>901XXXX079</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>Electricity Bill</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>Maharashtra State Electricity Distbn Co Ltd</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>160221081762</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>AM012028X0O2PQBHTHB5</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>370.00</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>Success</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>1234567899</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>iPaisa</Typography>
                    </Grid2>
                    <Grid2 item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>Online</Typography>
                    </Grid2>
                </Grid2>
            </Grid2>

            </Grid2>
          </Grid2>
        </Dialog>
      );
};

export default BillInvoicePopup;
