import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, MenuItem, Grid, Card, CardContent, Paper, CircularProgress, Container, Backdrop, Autocomplete } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import WalletBalanceComponent from './WalletBalanceComponent';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import InsufficiantB from './InsufficiantB';
import PayoutPopup from './PayoutPopup';
import PayoutFailedPopup from './PayoutFailedPopup';
import BulkPayoutSucPop from './BulkPayoutSucPop';
import BulkPayoutFailed from './BulkPayoutFailed';
import { useNavigate } from 'react-router-dom';
import IpinModal from '../IpaisaMpin/IpinModal';
import SetPasswordModal from "../IpaisaMpin/SetIPMpin";  // Import SetPassword modal


const payoutModes = [
    { value: 'NEFT', label: 'NEFT' },
    { value: 'RTGS', label: 'RTGS' },
    { value: 'IMPS', label: 'IMPS' },
    // Add more options as needed
];

const PayoutSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    payeeaccountNumber: Yup.string().required('Payee Account Number is required'),
    bankIfsc: Yup.string().required('Bank IFSC is required'),
    transferMode: Yup.string().required('Transfer Mode is required'),
    transferAmount: Yup.number().required('Transfer Amount is required').positive('Amount must be positive'),
    remark: Yup.string(),
    // purpose: Yup.string(),
});

const BulkPayoutSchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
});

const NewPayout = () => {

    const [walletBalance, setWalletBalance] = useState(0);
    const [showBulkPayout, setShowBulkPayout] = useState(false);
    const bulkPayout = sessionStorage.getItem("bulkPayout") === 'true';
    const [showSuccessPop, setShowSuccessPop] = useState(false);
    const [showFailedPop, setShowFailedPop] = useState(false);
    const [insufficiantB, setInsufficiantB] = useState(false);
    const [bulkPayoutSuccess, setBulkPayoutSuccess] = useState(false);
    const [bulkPayoutFailed, setBulkPayoutFailed] = useState(false);
    const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false); // Controls SetPassword modal
    const [isIpinModalOpen, setIpinModalOpen] = useState(false);  // Controls IPIN modal
    const [isLoading, setIsLoading] = useState(false); // State for loader
    const [pendingSubmission, setPendingSubmission] = useState(null); // Store form values to submit after IPIN
    const formikRef = React.useRef(null);



    const navigate = useNavigate();
    const handleClose = () => {
        setShowSuccessPop(false);
        setShowFailedPop(false);
        setInsufficiantB(false);
        setBulkPayoutSuccess(false);
        setBulkPayoutFailed(false);
    };

    const [bankDetails, setBankDetails] = useState([]);

    const fetchWalletBalance = async () => {
        try {
            const token = sessionStorage.getItem("jwtToken");
            const response = await axios.get(
                "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setWalletBalance(response.data.walletBalance || 0);
        } catch (error) {
            console.error("Error fetching wallet balance:", error);
        }
    };

    const getBankDetails = async () => {
        try {
            const token = sessionStorage.getItem("jwtToken");
            const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/getBankDetail',
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // return response.data.body;
            const bankData = response?.data?.body || [];
            setBankDetails(bankData);
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    };

    useEffect(() => {
        fetchWalletBalance();
        getBankDetails();
    }, [walletBalance]);
    const handlePayClick = async (values) => {
        setPendingSubmission(values); // Store form values for later submission
        try {
            // Fetch active payout provider
            const token = sessionStorage.getItem('jwtToken'); // Adjust if you store the token differently
            const response = await axios.get(
                'https://api.ipaisa.co.in/myapp/auth/get-active-payout-provider',
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const providerData = response.data?.data || {};
                const activeProvider = providerData.providerName;

                console.log('Active Gateway Provider:', activeProvider);

                // Save provider name in sessionStorage for later use
                sessionStorage.setItem('activeProvider', activeProvider);

                // Determine which modal to open based on MPIN status
                if (sessionStorage.getItem("isTxnMpinSet") === "False") {
                    setOpenSetPasswordModal(true); // Open SetPassword modal if MPIN is not set
                } else {
                    setIpinModalOpen(true); // Open IPIN modal if MPIN is set
                }
            } else {
                toast.error(response.data?.message || 'Unable to fetch active payout provider');
            }
        } catch (error) {
            console.error('Error fetching active payout provider:', error);
            toast.error('Failed to fetch active payout provider');
        }
    };

    // This function is called once the IPIN is validated.
    const handleIpinValid = async (ipin) => {
        setIsLoading(false);
        console.log('Submitted IPIN:', ipin);
        setIpinModalOpen(false);

        // Proceed with bulk payout submission after IPIN validation only if there's a pending submission
        if (pendingSubmission) {
            await handleSubmitAfterIpin(pendingSubmission); // Call the submit function directly

            // Use Formik's resetForm via the reference
            if (formikRef.current) {
                formikRef.current.resetForm(); // Reset the form explicitly
            }
            setPendingSubmission(null); // Clear pending submission after processing
        }
    };

    const handleCloseModal = () => {
        setIpinModalOpen(false);
        setOpenSetPasswordModal(false);  // Close SetPassword modal
    };

    const getLocation = async () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve(position.coords),
                    (error) => reject(error)
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    };

    const handleSubmitAfterIpin = async (values) => {
        setIsLoading(true);

        const activeProvider = sessionStorage.getItem('activeProvider');

        if (values.amount > walletBalance) {
            toast.error("Insufficient wallet balance.");
            setInsufficiantB(true); // Show failed pop if balance is insufficient
            setIsLoading(false);
            return;
        }

        try {
            const location = await getLocation();
            const token = sessionStorage.getItem('jwtToken');

            if (activeProvider === 'INSTANTPAY') {
                // Call the specific API for Easebuzz
                const payload = {
                    ...values,
                    payeraccountNumber: '120664700000021',
                    latitude: location.latitude.toFixed(4),
                    longitude: location.longitude.toFixed(4),
                };

                const response = await axios.post(
                    'https://api.ipaisa.co.in/myapp/auth/instantpay/payout',
                    payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    toast.success('Payout successful');
                    fetchWalletBalance();
                    setShowSuccessPop(true);
                } else {
                    toast.error(response.data.message || 'Failed to process payout');
                }
            } else if (activeProvider === 'RAZORPAY') {
                // Razorpay Payout API call
                const razorpayPayload = {
                    ...values // Optional remark field
                };

                const razorpayResponse = await axios.post(
                    'https://api.ipaisa.co.in/myapp/auth/razorpayout',
                    razorpayPayload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (razorpayResponse.status === 200) {
                    toast.success('Payout via Razorpay initiated.');
                    fetchWalletBalance();
                    setShowSuccessPop(true);
                } else {
                    toast.error(razorpayResponse.data.message || 'Failed to process Razorpay payout');
                }
            } else {
                toast.error('Unsupported provider');
            }
        } catch (error) {
            console.error('Payout failed:', error);
            setShowFailedPop(true);
        } finally {
            setIsLoading(false);
        }
    };



    const handleBulkSubmit = async (values, { setSubmitting }) => {
        try {
            const token = sessionStorage.getItem('jwtToken');
            const file = values.file;

            // Wrap Papa.parse in a Promise to use async/await
            const parsedData = await new Promise((resolve, reject) => {
                Papa.parse(file, {
                    header: true,
                    complete: (result) => {
                        const rows = result.data;
                        const processedRows = rows.map((row, index) => {
                            if (row.payeeaccountNumber) {
                                row.payeeaccountNumber = Number(row.payeeaccountNumber).toFixed(0);
                            }
                            console.log(`Processed Row ${index + 1}:`, row);
                            return row;
                        });

                        // Resolve with processed rows
                        resolve(processedRows);
                    },
                    error: (error) => {
                        console.error('Error parsing CSV:', error);
                        reject(error);
                    }
                });
            });

            // Generate a new CSV file from the processed data
            const csvString = Papa.unparse(parsedData);
            const newCsvFile = new Blob([csvString], { type: 'text/csv' });

            // Create FormData and append the generated CSV file
            const formData = new FormData();
            formData.append('file', newCsvFile, 'processed_payout.csv');
            formData.append('name', "instantPay");

            // Submit the FormData with the generated CSV file to the API
            const response = await fetch('https://api.ipaisa.co.in/myapp/auth/launchjob', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData
                
            });

            if (parseFloat(values.amount) > walletBalance) {
                setInsufficiantB(true);
                setSubmitting(false);
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to submit bulk payout');
            }

            toast.success('Bulk Payout successful');
            setBulkPayoutSuccess(true);
        } catch (error) {
            console.error('Bulk Payout failed:', error);
            toast.error('Bulk Payout failed');
            setBulkPayoutFailed(true);
        } finally {
            // Ensure form submission state is reset
            setSubmitting(false);
        }
    };


    const inputHeight = '56px';

    const BulkFileUpload = ({ setFieldValue }) => {
        const [selectedFile, setSelectedFile] = useState(null);
        const [error, setError] = useState('');

        const { getRootProps, getInputProps } = useDropzone({
            accept: '.csv',
            onDrop: (acceptedFiles, rejectedFiles) => {
                if (acceptedFiles.length > 0) {
                    setFieldValue('file', acceptedFiles[0]);
                    setSelectedFile(acceptedFiles[0]);
                    setError('');
                } else if (rejectedFiles.length > 0) {
                    setError('Only CSV files are allowed');
                }
            },
        });

        const handleRemoveFile = () => {
            setFieldValue('file', null);
            setSelectedFile(null);
            setError('');
        };

        console.log('bankDetails', bankDetails)
        return (
            <div>
                <div
                    {...getRootProps()}
                    style={{
                        border: '2px dashed #CCCCCC',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        marginBottom: '16px',
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography>Drag and Drop a CSV file here or click to select a file</Typography>
                </div>
                {error && (
                    <Typography color="error" variant="body2">
                        {error}
                    </Typography>
                )}
                {selectedFile && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Typography>{selectedFile.name}</Typography>
                            <Button variant="outlined" color="error" onClick={handleRemoveFile}>
                                Remove
                            </Button>
                        </Box>
                    </Box>
                )}
                <Typography sx={{ mt: 1 }}>
                    <a href="/data.csv" download="sample.csv" style={{ textDecoration: 'none', color: '#007bff' }}>
                        Sample CSV file
                    </a>
                </Typography>
            </div>
        );
    };
    console.log(bankDetails);

    return (
        <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            {/* Loader backdrop */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
                <Typography variant="h6" style={{ marginLeft: '1rem' }}>IPIN validating...</Typography>
            </Backdrop>
            <Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Balance</Typography>
                    <PartnerArrowIcon />
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Pay Out</Typography>
                </Grid>

                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '20px', color: '#343C6A' }}>Pay Out</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '3%' }}>
                {/* Wallet Balance Card */}
                <WalletBalanceComponent walletBalance={walletBalance} />

                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <Paper style={{ boxShadow: 'none', padding: '2rem', borderRadius: '8px', boxShadow: 'none' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mb: 2 }}>
                            <Button
                                onClick={() => setShowBulkPayout(false)}
                                style={{
                                    borderBottom: showBulkPayout ? 'none' : '2px solid #027BFE',
                                    textTransform: 'none',
                                    color: showBulkPayout ? '#808080' : '#027BFE',
                                    marginRight: '10px',
                                    fontSize: '16px'
                                }}
                            >
                                Payout
                            </Button>
                            {bulkPayout && (
                                <Button
                                    onClick={() => setShowBulkPayout(true)}
                                    style={{
                                        borderBottom: showBulkPayout ? '2px solid #027BFE' : 'none',
                                        textTransform: 'none',
                                        color: showBulkPayout ? '#027BFE' : '#808080',
                                        fontSize: '16px'
                                    }}
                                >
                                    Bulk Payout
                                </Button>
                            )}
                        </Box>

                        {bulkPayout && showBulkPayout ? (
                            <Formik
                                initialValues={{ file: null }}
                                validationSchema={BulkPayoutSchema}
                                onSubmit={handleBulkSubmit}
                            >
                                {({ isSubmitting, setFieldValue, errors, touched, isValid, resetForm }) => (
                                    <Form>
                                        <BulkFileUpload setFieldValue={setFieldValue} />
                                        {errors.file && touched.file ? (
                                            <div style={{ color: 'red' }}>{errors.file}</div>
                                        ) : null}
                                        <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                            <Button
                                                sx={{
                                                    border: '1.5px solid #FF0000',
                                                    color: '#FF0000',
                                                    textTransform: "none",
                                                    width: { xs: '100%', md: '18%' },
                                                    fontSize: '16px',
                                                    borderRadius: '8px',
                                                    height: inputHeight
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                sx={{
                                                    backgroundColor: "#027BFE",
                                                    color: "white",
                                                    textTransform: "none",
                                                    width: { xs: '100%', md: '18%' },
                                                    fontSize: '16px',
                                                    borderRadius: '8px',
                                                    height: inputHeight,
                                                    '&:hover': {
                                                        backgroundColor: "#027BFE",
                                                        color: '#FFFFFF',
                                                    },
                                                }}
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Bulk Payout'}
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>


                        ) : (
                            <Formik
                                initialValues={{
                                    name: '',
                                    payeeaccountNumber: '',
                                    bankIfsc: '',
                                    transferMode: '',
                                    transferAmount: '',
                                    remark: '',
                                    purpose: 'REIMBURSEMENT',
                                }}
                                validationSchema={PayoutSchema}
                                onSubmit={handlePayClick}
                                innerRef={formikRef} // Attach the ref to Formik
                            >

                                {({ isSubmitting, errors, touched, setFieldValue, values, isValid, resetForm }) => (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} >
                                                <Field
                                                    as={TextField}
                                                    name="name"
                                                    label="Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {bankDetails?.length > 0 ? (
                                                    <Autocomplete
                                                        options={bankDetails}
                                                        getOptionLabel={(option) => {
                                                            // Ensure both accountNumber and name exist and are not undefined
                                                            return `${option.accountNumber || 'No Account Number'} - ${option.name || 'No Name'}`;
                                                        }}
                                                        isOptionEqualToValue={(option, value) => option.accountNumber === value.accountNumber} // Compare with accountNumber
                                                        onChange={(event, selectedOption) => {
                                                            if (selectedOption) {
                                                                // Update your state or form values directly here
                                                                setFieldValue('payeeaccountNumber', selectedOption.accountNumber);
                                                                setFieldValue('bankIfsc', selectedOption.ifsc);
                                                                setFieldValue('name', selectedOption.name);
                                                                // Add any other fields you want to auto-fill here
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Account Number"
                                                                fullWidth
                                                                variant="outlined"
                                                                error={Boolean(errors.payeeaccountNumber && touched.payeeaccountNumber)}
                                                                helperText={touched.payeeaccountNumber && errors.payeeaccountNumber}
                                                            />
                                                        )}
                                                    />
                                                ) : (
                                                    <Typography variant="body1" color="error" style={{ marginTop: '16px' }}>
                                                        No bank details added
                                                    </Typography>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="bankIfsc"
                                                    label="Bank IFSC"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="transferMode"
                                                    label="Transfer Mode"
                                                    select
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    {payoutModes.map((mode) => (
                                                        <MenuItem key={mode.value} value={mode.value}>
                                                            {mode.label}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="transferAmount"
                                                    label="Transfer Amount"
                                                    variant="outlined"
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="remark"
                                                    label="Remarks"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: '3%' }}>
                                            <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                                <Button
                                                    sx={{
                                                        border: '1.5px solid #FF0000',
                                                        color: '#FF0000',
                                                        textTransform: "none",
                                                        width: { xs: '100%', md: '18%' },
                                                        fontSize: '16px',
                                                        borderRadius: '8px',
                                                        height: inputHeight
                                                    }}
                                                    onClick={() => resetForm()} // Reset form on cancel
                                                    disabled={isSubmitting} // Disable if form is invalid or submitting
                                                >Cancel</Button>
                                                <Button
                                                    type="submit"
                                                    sx={{
                                                        backgroundColor: "#027BFE",
                                                        color: "white",
                                                        textTransform: "none",
                                                        width: { xs: '100%', md: '18%' },
                                                        fontSize: '16px',
                                                        borderRadius: '8px',
                                                        height: inputHeight,
                                                        '&:hover': {
                                                            backgroundColor: "#027BFE",
                                                            color: '#FFFFFF',
                                                        },
                                                    }}
                                                    disabled={!isValid || isSubmitting} // Disable if form is invalid or submitting
                                                >
                                                    Pay
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            {showSuccessPop && <PayoutPopup handleClose={handleClose} />}
            {showFailedPop && <PayoutFailedPopup handleClose={handleClose} />}
            {insufficiantB && <InsufficiantB handleClose={handleClose} />}
            {bulkPayoutSuccess && <BulkPayoutSucPop handleClose={handleClose} />}
            {bulkPayoutFailed && <BulkPayoutFailed handleClose={handleClose} />}

            {sessionStorage.getItem("isTxnMpinSet") === "False" ? (
                <SetPasswordModal
                    open={openSetPasswordModal}
                    onClose={() => setOpenSetPasswordModal(false)}
                    onMpinValid={handleIpinValid}
                />
            ) : (
                <IpinModal
                    open={isIpinModalOpen}
                    onClose={handleCloseModal}
                    onMpinValid={handleIpinValid}
                />
            )}

        </Box>
    )
}

export default NewPayout
