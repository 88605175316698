import React, { useState, useEffect } from "react";
import axios from "axios";

function Redirectmp() {
  // State variables to hold input values
  const [parameter1, setParam1] = useState("");
  const [parameter2, setParam2] = useState("");
  const [parameter3, setParam3] = useState("");

  useEffect(() => {
    // Check if the current domain is 'test.ipaisa.co.in'
    if (window.location.hostname === "https://test.ipaisa.co.in") {
      console.log("Hello");
    }

    const fetchParameters = async () => {
      const fileResponse = await fetch("/AgreementRetailer.pdf");
      const fileBlob = await fileResponse.blob();

      // Create a FormData object
      const formData = new FormData();
      formData.append("file", new File([fileBlob], "AgreementRetailer.pdf"));

      try {
        const token = sessionStorage.getItem('jwtToken');

        const response = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/get-sign-doc",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response);

        // Assuming the API returns an object with param1, param2, and param3
        setParam1(response.data.parameter1);
        setParam2(response.data.parameter2);
        setParam3(response.data.parameter3);

        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error fetching parameters:", error);
      }
    };

    fetchParameters();
  }, []);

  const handleButtonClick = async () => {
    try {
      
      const encryptedSessionKey = parameter1;
      const encryptedJson = parameter2;
      const encryptedHash = parameter3;

     
      const Test_URL = "https://demosignergateway.emsigner.com/eMsecure/V3_0/Index";
      const Production_URL = "https://gateway.emsigner.com/eMsecure/V3_0/Index";

      
      const form = document.createElement("form");
      form.method = "POST";
      form.action = Test_URL; 

     
      const input1 = document.createElement("input");
      input1.type = "text";
      input1.name = "Parameter1";
      input1.value = encryptedSessionKey;

      const input2 = document.createElement("input");
      input2.type = "text";
      input2.name = "Parameter2";
      input2.value = encryptedJson;

      const input3 = document.createElement("input");
      input3.type = "text";
      input3.name = "Parameter3";
      input3.value = encryptedHash;

      
      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);

      
      const submitButton = document.createElement("input");
      submitButton.type = "submit";
      submitButton.value = "GateWay Redirectmp";

      
      form.appendChild(submitButton);

      
      document.body.appendChild(form);
      form.submit(); 

      console.log("Form submitted to:", Test_URL);
    } catch (error) {
      console.error("Error while handling the button click:", error);
      alert("Failed to redirect to gateway!");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h3>Make a POST API Call</h3>
      <div>
        <label>
          Parameter 1:{" "}
          <input
            type="text"
            value={parameter1}
            onChange={(e) => setParam1(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Parameter 2:{" "}
          <input
            type="text"
            value={parameter2}
            onChange={(e) => setParam2(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Parameter 3:{" "}
          <input
            type="text"
            value={parameter3}
            onChange={(e) => setParam3(e.target.value)}
          />
        </label>
      </div>
      <button
        onClick={handleButtonClick}
        style={{
          marginTop: "10px",
          padding: "10px 20px",
          backgroundColor: "blue",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        Hit API
      </button>
    </div>
  );
}

export default Redirectmp;
