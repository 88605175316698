import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import SinglePayout from './SinglePayout';
import BulkPayout from './BulkPayout';

const MainPayout = () => {
    const [payoutType, setPayoutType] = useState('single');
    const [showBulkPayout, setShowBulkPayout] = useState(false);

    useEffect(() => {
        // Check if bulkPayout is set to true in sessionStorage
        const bulkPayoutEnabled = sessionStorage.getItem('bulkPayout') === 'true';
        setShowBulkPayout(bulkPayoutEnabled);
    }, []);

    return (
        <Box p={4} mt={10}>
            <Grid container spacing={2} justifyContent="left" style={{ marginBottom: '20px' }}>
                <Grid item>
                    <Button
                        variant={payoutType === 'single' ? 'contained' : 'outlined'}
                        color="primary"
                        onClick={() => setPayoutType('single')}
                    >
                        Single Payout
                    </Button>
                </Grid>
                {showBulkPayout && (
                    <Grid item>
                        <Button
                            variant={payoutType === 'bulk' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => setPayoutType('bulk')}
                        >
                            Bulk Payout
                        </Button>
                    </Grid>
                )}
            </Grid>

            {/* Render SinglePayout or BulkPayout based on payoutType */}
            {payoutType === 'single' ? <SinglePayout /> : <BulkPayout />}

        </Box>
    );
};

export default MainPayout;
