import React, { useState } from "react";
import { Grid, Typography, TextField, MenuItem, Button, Card, CardContent, Box, Dialog, DialogContent, FormLabel } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { toast } from "react-toastify";
import { NewBBPSIcon } from "../../res/NewWebIcons";

const NewTVRecharge = () => {
  const [showPlans, setShowPlans] = useState(false); // State to toggle plans
  const [selectedPlan, setSelectedPlan] = useState(null); // State to store selected plan
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = (plan) => {
    setSelectedPlan(plan); // Save the selected plan
    setModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedPlan(null); // Clear the selected plan on modal close
  };

  // Sample Recharge Plans
  const rechargePlans = [
    { id: 1, name: "Plan A", price: "₹199", validity: "28 days" },
    { id: 2, name: "Plan B", price: "₹299", validity: "56 days" },
    { id: 3, name: "Plan C", price: "₹499", validity: "84 days" },
  ];

  // Handler to show plans
  const handleProceedToRecharge = () => {
    setShowPlans(true);
  };

  // Handler to go back to the form
  const handleBack = () => {
    setShowPlans(false);
    setSelectedPlan(null); // Reset the selected plan
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      {/* Form Fields */}
        <Grid style={{ marginTop:'1%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <FormLabel
            component="legend"
            style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
          >
              Recharge DTH or TV
          </FormLabel>
          <Typography>
            <NewBBPSIcon/>
          </Typography>
        </Grid>
      {!showPlans && (
        <Grid container spacing={3} sx={{marginTop:'1%'}}>
          {/* DTH Operator Dropdown */}
          <Grid item xs={12} md={6}>
            <TextField fullWidth select label="DTH Operator" variant="outlined">
              <MenuItem value="Tata Sky">Tata Sky</MenuItem>
              <MenuItem value="Dish TV">Dish TV</MenuItem>
              <MenuItem value="Airtel Digital TV">Airtel Digital TV</MenuItem>
            </TextField>
          </Grid>

          {/* Customer ID Field */}
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Customer ID" variant="outlined" />
          </Grid>

          {/* Proceed to Recharge Button */}
          <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleProceedToRecharge}
              style={{
                backgroundColor: "#027BFE",
                color: "white",
                textTransform: "none",
                fontSize: "16px",
                padding: "10px 20px",
              }}
            >
              Proceed to Recharge
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Recharge Plans Section */}
      {showPlans && (
        <Grid container spacing={3}>
          {/* Back Button */}
          <Grid item xs={12}>
            <Button
              onClick={handleBack}
              style={{
                backgroundColor: "#ccc",
                color: "#333",
                textTransform: "none",
              }}
            >
              <ArrowBackIos style={{ fontSize: "16px" }} />
              Back
            </Button>
          </Grid>

          {/* Recharge Plans */}
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Select a Recharge Plan
            </Typography>
          </Grid>

          {rechargePlans.map((plan) => (
            <Grid item xs={12} md={4} key={plan.id}>
              <Grid
                style={{
                  border: selectedPlan?.id === plan.id ? "2px solid #027BFE" : "1px solid #ccc",
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                <CardContent>
                  <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    {plan.name}
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    Price: {plan.price}
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: "15px", color: "gray" }}>
                    Validity: {plan.validity}
                  </Typography>
                  <Button
                    onClick={() => handleModalOpen(plan)} // Pass plan to modal
                    style={{
                      backgroundColor: "#027BFE",
                      color: "white",
                      textTransform: "none",
                      padding: "8px 15px",
                      borderRadius: "4px",
                    }}
                  >
                    Pay
                  </Button>
                </CardContent>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Payment Modal */}
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={4}>
            {/* Left Section: Payment Details */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  border: "1px solid #D9D9D9",
                  borderRadius: "12px",
                  backgroundColor: "#F8F8F8",
                }}
              >
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  Confirm your payment
                </Typography>

                {/* Plan Name */}
                <Grid container justifyContent="space-between" mb={2}>
                  <Typography variant="body1" color="textSecondary">
                    Plan
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedPlan?.name || "N/A"}
                  </Typography>
                </Grid>

                {/* Amount */}
                <Grid container justifyContent="space-between" mb={2}>
                  <Typography variant="body1" color="textSecondary">
                    Amount
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedPlan?.price || "N/A"}
                  </Typography>
                </Grid>

                {/* Validity */}
                <Grid container justifyContent="space-between" mb={2}>
                  <Typography variant="body1" color="textSecondary">
                    Validity
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedPlan?.validity || "N/A"}
                  </Typography>
                </Grid>

                <Button
                  fullWidth
                  sx={{
                    mt: 3,
                    color: "white",
                    backgroundColor: "#027BFE",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Proceed to Pay
                </Button>
              </Box>
            </Grid>

            {/* Right Section: Wallet Info */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: "12px",
                  border: "1px solid #D9D9D9",
                  backgroundColor: "#027BFE",
                  color: "white",
                }}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography variant="h6" fontWeight="bold">
                    Wallet Balance
                  </Typography>
                  <Typography variant="h4" fontWeight="bold" mt={1}>
                    1000
                  </Typography>
                  <Button
                    sx={{
                      mt: 3,
                      backgroundColor: "white",
                      color: "#027BFE",
                      textTransform: "none",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Update Wallet
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NewTVRecharge;
