import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import BGIMG1 from "../Assets/website/home/bg1.png";
import BGIMG2 from "../Assets/website/home/bg-2.png";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
// import wallet from "../Assets/website/services/Wallet.png";
import countimg from "../Assets/website/home/countimg.png";
import particle from "../Assets/website/services/particle.png";
import sc2 from "../Assets/website/services/sc2.png";
import BlogGrpImg from '../Assets/website/services/BlogsGrpImg.png'
import UtilityImg from "../Assets/website/services/UtilityImg.png";
import { WalletServiceIcon } from "../../res/icons";
import { useNavigate } from "react-router-dom";

const Services = ({index}) => {
  const navigate = useNavigate();

  const handleNavigate = (index) => {
    if (index === 0) {
      navigate("/signin");
    }
  };

  return (
    <div>
      <Header />
     {/* First Section */}
     <Container
        sx={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "3%",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "700",
            fontSize: { xs: "25px", sm: "30px", md: "45px" },
            textAlign: "center",
            color: "#0B1131",
            marginBottom: "3rem",
          }}
        >
          Services
        </Typography>
        <Grid container justifyContent="center">
          <Typography
            variant="h5"
            sx={{
              color: "#4D5156",
              fontSize: { xs: "14px", sm: "16px" },
              fontWeight: "500",
              lineHeight: "28px",
              maxWidth: "700px",
              textAlign: "start",
            }}
          >
            iPaisa simplifies financial transactions by offering a secure and
            user-friendly platform. It provides a one-stop solution for UPI
            Payments, Bharat Connect Bill Payments, Merchant Transactions,
            Mobile Recharges, and DTH Payments. With bill reminders and
            transaction history features, iPaisa ensures a convenient and
            organized payment experience.
          </Typography>
        </Grid>
        <Grid
          style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}
        >
          <img src={BlogGrpImg} style={{ width: "70%" }} alt="" />
        </Grid>
      </Container>
      {/* Service Cards */}
      <Container style={{ marginTop: "4rem" }}>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            padding: { xs: "0 10px", md: "0" },
          }}
        >
          {[...Array(5)].map((_, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "444px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: { xs: "10px", md: "20px" },
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)",
                  },
                  "&:active": {
                    transform: "translateY(-2px)",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                  },
                  cursor: "pointer",
                }}
                onClick={() => handleNavigate(index)}
              >
                <WalletServiceIcon />

                <div style={{ position: "relative" }}>
                  <img
                    src={BGIMG1}
                    style={{
                      width: "300px",
                      position: "absolute",
                      bottom: 0,
                      left: -50,
                      top: 30,
                    }}
                  />
                  <img
                    src={BGIMG2}
                    style={{
                      width: "300px",
                      position: "absolute",
                      bottom: 0,
                      top: 120,
                      right: -20,
                    }}
                  />
                </div>

                <Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginTop: { xs: "40px", md: "74px" },
                      fontSize: { xs: "20px", md: "24px" },
                      fontWeight: 700,
                      lineHeight: { xs: "1.2", md: "1.5" },
                      color: "#0B1131",
                    }}
                  >
                    {index === 0 && "Flight Bookings"}
                    {index === 1 && "Bharat Connect"}
                    {index === 2 && "Merchant Payments"}
                    {index === 3 && "Mobile Recharge & DTH Payments"}
                    {index === 4 && "Bill Reminders & History"}
                    {index === 5 && "UPI Payments"}
                  </Typography>

                  <Typography
                    sx={{
                      textAlign: "center",
                      marginTop: {
                        xs: "10px",
                        md: index === 5 ? "10px" : "20px",
                      },
                      marginBottom: "4%",
                      fontSize: { xs: "14px", md: "16px" },
                      fontWeight: 500,
                      lineHeight: { xs: "1.2", md: "1.5" },
                      color: "#4D5156",
                      padding: { xs: "10px", md: "0" },
                    }}
                  >
                    {index === 0 &&
                      "Explore a world of affordable travel with our tailored flight options. Whether you're planning a quick getaway or a dream vacation, find the best deals and enjoy a seamless booking experience."}
                    {index === 1 &&
                      "iPaisa offers secure, convenient Bharat Connect service for paying bills, with real-time updates and multiple payment options."}
                    {index === 2 &&
                      "Enjoy hassle-free transactions at retail outlets, e-commerce platforms, and various service providers. iPaisa supports quick and secure payments, benefiting both customers and merchants."}
                    {index === 3 &&
                      "Recharge your mobile phones and pay for DTH services conveniently. iPaisa supports all major telecom operators and DTH service providers, ensuring you stay connected and entertained without any inconvenience."}
                    {index === 4 &&
                      "Stay organized with timely bill reminders and maintain a comprehensive transaction history. Never miss a payment deadline again."}
                    {index === 5 &&
                      "Link your bank account to iPaisa and make quick and secure peer-to-peer payments in real-time, eliminating the need for traditional banking methods."}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* thire */}
      <Box
        sx={{
          padding: "2rem",
          backgroundColor: "#3029D9",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          marginTop: "5rem",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: { xs: "1rem", md: "2rem" },
            marginLeft: { xs: "0", md: "8%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: { xs: "24px", sm: "32px", md: "40px", lg: "48px" },
              color: "white",
              lineHeight: { xs: "32px", sm: "40px", md: "48px", lg: "55px" },
              marginBottom: "1rem",
            }}
          >
            Bharat Connect
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              color: "#D9D9D9",
              marginBottom: "1rem",
              lineHeight: { xs: "20px", sm: "24px", md: "28px" },
            }}
          >
            Bharat Connect revolutionizes the way bills are
            paid in India, offering a unified platform for convenient, secure,
            and efficient bill payments.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            marginLeft: "9%",
          }}
        >
          <Card
            sx={{
              width: { xs: "100%", sm: "400px", md: "420px" },
              height: "auto",
              boxShadow: "none",
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              image={sc2}
              alt="Mobile Screen"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </Card>
        </Grid>
      </Box>
      {/* forth */}
      <Box
        sx={{
          padding: "2rem",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          marginTop: "5rem",
        }}
      >
      <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            marginLeft: "9%",
          }}
        >
          <Card
            sx={{
              width: { xs: "100%", sm: "400px", md: "420px" },
              height: "auto",
              boxShadow: "none",
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              image={UtilityImg}
              alt="UtilityImg"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: { xs: "1rem", md: "2rem" },
            marginLeft: { xs: "0", md: "8%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: { xs: "24px", sm: "32px", md: "40px", lg: "48px" },
              color: "#000000",
              lineHeight: { xs: "32px", sm: "40px", md: "48px", lg: "55px" },
              marginBottom: "1rem",
            }}
          >
            Bharat Connect
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              color: "#4D5156",
              marginBottom: "1rem",
              lineHeight: { xs: "20px", sm: "24px", md: "28px" },
            }}
          >
            Get the insurance you need, simplified. We leverage tech to offer customized coverage, instant quotes, and seamless claims. Protect your future with a faster, more convenient insurance experience.
          </Typography>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};

export default Services;
