import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Container,
  Card,
  CardContent,
  Box,
  InputAdornment,

} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import { ReactComponent as ChargeBackCard } from '../Assets/Dashboard/ChargeBackCard.svg';
import axios from "axios";


const RetailerChargeBackReports = () => {
  const [list, setList] = useState([]);
  const [chargeBackBalance, setChargeBackBalance] = useState(0);
  const [chargeBackCount, setChargeBackCount] = useState(0);

  // Fetch wallet balance for the Charge Back Card
  const fetchBalance = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setChargeBackBalance(response.data.chargeBackBalance || 0);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  // Fetch chargeBackCount for the zone
  const fetchZoneCount = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/mycount",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            txnid: "iPaisa1733740756545",
          },
        }
      );
      setChargeBackCount(response.data.count || 0);
    } catch (error) {
      console.error("Error fetching zone data:", error);
    }
  };

  // Load transaction data
  const loadData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    const url = "https://api.ipaisa.co.in/myapp/auth/getchargebacklistuser";
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      if (response?.status === 200 && response.data?.users) {
        setList(response.data.users);
      }
    } catch (e) {
      console.error("Error loading data:", e);
    }
  };

  useEffect(() => {
    loadData();
    fetchBalance();
    fetchZoneCount();
  }, []);

  // Function to determine the color based on chargeBackCount
  const getCellStyle = (count) => {
    if (count === 0) {
      return { backgroundColor: "green", color: "white" }; // Green Zone
    } else if (count <= 5) {
      return { backgroundColor: "blue", color: "white" }; // Blue Zone
    } else if (count <= 15) {
      return { backgroundColor: "gold", color: "black" }; // Yellow Zone
    } else {
      return { backgroundColor: "red", color: "white" }; // Red Zone
    }
  };


  const lineHeight = '48px';

  return (
  <Box style={{ padding:'2rem',marginTop: 'calc(3% + 56px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Charge Back</Typography>
            <PartnerArrowIcon />
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Charge Back Report</Typography>
      </Grid>
      <Grid container style={{ marginTop: '1%', display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Typography */}
        <Grid item xs={12} md={8} style={{ order: { xs: 1, md: 1 } }}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              fontSize: '19px',
              color: '#343C6A',
              textAlign: { xs: 'center', md: 'left' }, // Center on small screens
            }}
          >
            Charge Back Report
          </Typography>
        </Grid>

        {/* Paper with Warning */}
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              padding: "0.8rem",
              borderRadius: "10px",
              boxShadow: "none",
            }}
          >
            <Typography
              style={{
                ...getCellStyle(chargeBackCount),
                borderRadius: "10px",
                padding: "0.5rem",
                textAlign: "center",
              }}
            >
              {chargeBackCount === 0
                ? "You are in Green Zone!"
                : chargeBackCount <= 5
                ? "You are in Blue Zone!"
                : chargeBackCount <= 15
                ? "You are in Yellow Zone!"
                : "You are in Red Zone!"}
            </Typography>
          </Paper>
        </Grid>
      </Grid>


      <Grid style={{marginTop:'2%'}}>
        <Grid container spacing={1}>
          {/* Card Section */}
          <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
            <Paper style={{ boxShadow:'none', padding: '2rem', borderRadius: '10px', boxShadow:'none'}}>
              <Card style={{ width: "100%", height: "260px", borderRadius: "12px", alignContent: "center", textAlign: 'center', backgroundColor:'#ff0000', boxShadow:'none'}}>
                <CardContent>
                  <ChargeBackCard />
                  <Typography color="textSecondary" sx={{ color: "#FFFFFF", fontSize: "22px" }} gutterBottom>
                    Charge Back
                  </Typography>
                  <Typography variant="h5" sx={{ color: "#FFFFFF", fontSize: "30px" }} component="h2" >
                    ₹ {chargeBackBalance.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>

          {/* Form Section */}
          <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px', boxShadow:'none', height:'350px', scrollbarWidth:'thin' }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#027BFE" }}>
                      <TableRow>
                        <TableCell sx={{ color: "white" }}>Sr No</TableCell>
                        <TableCell sx={{ color: "white" }}>Transaction Type</TableCell>
                        <TableCell sx={{ color: "white" }}>Transaction Date</TableCell>
                        <TableCell sx={{ color: "white" }}>
                          Transaction Amount
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>ChargeBack Amount</TableCell>
                        <TableCell sx={{ color: "white" }}>
                          ChargeBack Created
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={7} style={{ textAlign: "center" }}>
                            No Transactions
                          </TableCell>
                        </TableRow>
                      ) : (
                        list.map((txn, i) => (
                          <TableRow>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{txn.txnType}</TableCell>
                            <TableCell>
                              {txn.txnDate
                              ? new Date(txn.txnDate).toLocaleString()
                              : "N/A"}
                            </TableCell>
                            <TableCell>{txn.amount}</TableCell>
                            <TableCell>{txn.chargeBackAmount}</TableCell>
                            <TableCell>
                              {txn.created
                              ? new Date(txn.created).toLocaleString()
                              : "N/A"}
                            </TableCell>
                            <TableCell>{txn.settlementStatus}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
          </Grid>
        </Grid>
      </Grid>
  </Box>
  );
};

export default RetailerChargeBackReports;
