import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Divider, FormControl, Grid, Grid2, InputLabel, MenuItem, Paper, Select, TextField, Typography, Stack, Stepper, Step, StepLabel, styled, StepConnector, stepConnectorClasses, QontoConnector, QontoStepIcon } from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ClockIcon, PlusIcon, clockIcon } from '../res/NewWebIcons';
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import PropTypes from 'prop-types';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    issueType: Yup.string().required('Issue Type is required'),
    subject: Yup.string().required('Subject is required'),
    remark: Yup.string().required('Remark is required'),
    amount: Yup.number(),
    bankAccount: Yup.string(),
    paymentMode: Yup.string(),
    utrNumber: Yup.number(),
    bankReceipt: Yup.mixed(),
}).when('issueType', {
    is: 'Offline Bank Funds',
    then: Yup.object().shape({
        amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
        bankAccount: Yup.string().required('Bank Account is required'),
        paymentMode: Yup.string().required('Payment Mode is required'),
        utrNumber: Yup.number().required('UTR Number is required').positive('UTR Number must be positive'),
        bankReceipt: Yup.mixed().required('Bank Receipt is required'),
    }),
});



// Connector styles
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, #F97316 0%, #F97316  50%, #22C55E 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}));

// Step icon styles
const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[700],
    }),
    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                //   backgroundImage:
                //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
                backgroundColor: '#22C55E',
                boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            },
        },
        {
            props: ({ ownerState }) => ownerState.completed,
            style: {
                //   backgroundImage:
                //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
                backgroundColor: '#F97316',
                boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            },
        },
    ],
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const icons = {
        1: <Check />,
        2: <MoreHorizIcon />,
        3: <MoreHorizIcon />,
        4: <CheckCircleOutlineIcon />
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

// Steps data
const steps = ['Ticket Raised', 'Approved', 'In Progress', 'Ticket Closed'];

const stepDates = ['28/09/2024', '29/09/2024', '30/09/2024', '01/10/2024'];



const NewCreateTicket = () => {
    const [userTickets, setUserTickets] = useState([]);
    const fetchTickets = async () => {
        try {
            const userId = sessionStorage.getItem('userId');

            if (!userId) {
                throw new Error("User ID not found in sessionStorage");
            }

            const response = await axios.get('https://testapisupport.ipaisa.co.in/supportapp/auth/ticket/getbyuser', {
                headers: {
                    'userid': userId,
                },
            });

            setUserTickets(response.data);
            console.log('Fetched tickets:', response.data);
        } catch (error) {
            console.error('Error fetching tickets:', error.message);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);


    const handleNumberChange = (e) => {
        const { id, value } = e.target;
        const newValue = value.replace(/\D/g, ''); // Replace any non-digit character with an empty string

        formik.setFieldValue(id, newValue);
    };

    const formik = useFormik({
        initialValues: {
            issueType: '',
            subject: '',
            remark: '',
            amount: '',
            bankAccount: '',
            paymentMode: '',
            utrNumber: '',
            bankReceipt: null,
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            await handleSubmit(values, resetForm);
        },
    });

    const handleSubmit = async (values, resetForm) => {
        const formData = new FormData();

        formData.append('issueType', values.issueType);
        formData.append('subject', values.subject);
        formData.append('remark', values.remark);

        if (values.issueType === 'Offline Bank Funds' || values.issueType === 'paymentissue') {
            formData.append('amount', values.amount);
            formData.append('bankAccount', values.bankAccount);
            formData.append('paymentMode', values.paymentMode);
            formData.append('utr_ref_Number', values.utrNumber); // Updated field name to match curl
            formData.append('bankReceipt', values.bankReceipt); // Ensure this is a File object
        }

        // Add the new fields
        // formData.append('service_id', values.serviceId);
        // formData.append('service_name', values.serviceName);

        try {
            const token = sessionStorage.getItem("jwtToken");
            const uType = sessionStorage.getItem("uType");
            const mobile = sessionStorage.getItem("mobileNumber"); // Retrieve mobile number from sessionStorage

            if (!mobile) {
                throw new Error("Mobile number not found in sessionStorage");
            }

            const response = await axios.post(
                'https://testapisupport.ipaisa.co.in/supportapp/auth/ticket/create',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                        mobile, // Add the retrieved mobile number to headers
                        utype: uType, // Add the 'utype' header
                    },
                }
            );

            console.log('Form submitted successfully:', response.data);
            resetForm();
            fetchTickets();
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };


    const areRequiredFieldsFilled =
        formik.values.amount &&
        formik.values.bankAccount &&
        formik.values.paymentMode &&
        formik.values.utrNumber &&
        !formik.errors.amount &&
        !formik.errors.bankAccount &&
        !formik.errors.paymentMode &&
        !formik.errors.utrNumber;

    const lineHeight = '50px';
    const navigate = useNavigate();
    const handleMoreTicket = () => {
        console.log('clicked on more ticket')
        navigate('/TicketHistory');

    }

    return (
        <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid>
                <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Create Ticket</Typography>
            </Grid>

            <Grid style={{ marginTop: '3%' }}>
                <Paper component="form" onSubmit={formik.handleSubmit} style={{ backgroundColor: 'white', padding:'2rem', boxShadow:'none', borderRadius:'12px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} sm={6}>
                            <FormControl fullWidth error={formik.touched.issueType && Boolean(formik.errors.issueType)}>
                                <InputLabel id="issue-type-label">Issue Type</InputLabel>
                                <Select
                                    labelId="issue-type-label"
                                    id="issueType"
                                    name="issueType"
                                    value={formik.values.issueType}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    label="Issue Type"
                                >
                                    <MenuItem value="Offline Bank Funds">Offline Bank Funds</MenuItem>
                                    <MenuItem value="infoissue">Info Issue</MenuItem>
                                    {/* <MenuItem value="paymentissue">Payment Issue</MenuItem> */}
                                    <MenuItem value="otherissue">Kyc Verfication</MenuItem>
                                    <MenuItem value="Other Issue">Other Issue</MenuItem>
                                </Select>
                                {formik.touched.issueType && formik.errors.issueType && (
                                    <Typography color="error">{formik.errors.issueType}</Typography>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                fullWidth
                                id="subject"
                                name="subject"
                                label="Subject"
                                value={formik.values.subject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                placeholder="Enter subject"
                                error={formik.touched.subject && Boolean(formik.errors.subject)}
                                helperText={formik.touched.subject && formik.errors.subject}
                            />
                        </Grid>
                        {formik.values.issueType !== 'Offline Bank Funds' && formik.values.issueType !== 'paymentissue' && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="remark"
                                    name="remark"
                                    label="Remark"
                                    value={formik.values.remark}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    placeholder="Enter your remark"
                                    multiline
                                    rows={4}
                                    error={formik.touched.remark && Boolean(formik.errors.remark)}
                                    helperText={formik.touched.remark && formik.errors.remark}
                                />
                            </Grid>
                        )}
                        {formik.values.issueType === 'Offline Bank Funds' && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Offline Bank Issue Details</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="amount"
                                        name="amount"
                                        label="Amount"
                                        value={formik.values.amount}
                                        onChange={handleNumberChange} // Use the custom handleChange function here
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        placeholder="Enter amount"
                                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                                        helperText={formik.touched.amount && formik.errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)}>
                                        <InputLabel id="bank-account-label">Bank Account</InputLabel>
                                        <Select
                                            labelId="bank-account-label"
                                            id="bankAccount"
                                            name="bankAccount"
                                            value={formik.values.bankAccount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            label="Bank Account"
                                        >
                                            <MenuItem value="account1">Yes Bank</MenuItem>
                                        </Select>
                                        {formik.touched.bankAccount && formik.errors.bankAccount && (
                                            <Typography color="error">{formik.errors.bankAccount}</Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={formik.touched.paymentMode && Boolean(formik.errors.paymentMode)}>
                                        <InputLabel id="payment-mode-label">Payment Mode</InputLabel>
                                        <Select
                                            labelId="payment-mode-label"
                                            id="paymentMode"
                                            name="paymentMode"
                                            value={formik.values.paymentMode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            label="Payment Mode"
                                        >
                                            <MenuItem value="mode1">Mode 1</MenuItem>
                                            <MenuItem value="mode2">Mode 2</MenuItem>
                                            <MenuItem value="mode3">Mode 3</MenuItem>
                                        </Select>
                                        {formik.touched.paymentMode && formik.errors.paymentMode && (
                                            <Typography color="error">{formik.errors.paymentMode}</Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="utrNumber"
                                        name="utrNumber"
                                        label="UTR Number"
                                        value={formik.values.utrNumber}
                                        onChange={handleNumberChange} // Use the custom handleChange function here
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        placeholder="Enter UTR number"
                                        error={formik.touched.utrNumber && Boolean(formik.errors.utrNumber)}
                                        helperText={formik.touched.utrNumber && formik.errors.utrNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} style={{ marginBottom: '2%' }}>
                                    <Button
                                        sx={{
                                            height: lineHeight,
                                            width: '100%',
                                            fontSize: { xs: '8px', md: '15px' },
                                            textTransform: 'none',
                                            borderRadius: '8px',
                                            fontWeight: 'bold',
                                            backgroundColor: "#FFFFFF",
                                            border: '1px solid #027BFE',
                                            color: '#027BFE',
                                            '&:hover': {
                                                backgroundColor: '#FFFFFF',
                                                color: '#027BFE',
                                            },
                                            gap: '4%',
                                        }}
                                        component="label"
                                        fullWidth
                                    >
                                        Upload Bank Receipt
                                        <PlusIcon />
                                        <input
                                            type="file"
                                            name="bankReceipt"
                                            hidden
                                            onChange={(event) => {
                                                formik.setFieldValue('bankReceipt', event.currentTarget.files[0]);
                                            }}
                                            disabled={!areRequiredFieldsFilled}
                                        />
                                    </Button>
                                    {formik.values.bankReceipt && (
                                        <Typography variant="body1">
                                            Selected File: {formik.values.bankReceipt.name}
                                        </Typography>
                                    )}
                                    {formik.touched.bankReceipt && formik.errors.bankReceipt && (
                                        <Typography color="error">{formik.errors.bankReceipt}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="offlineRemark"
                                        name="remark"
                                        label="Remark"
                                        value={formik.values.remark}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        placeholder="Enter your remark"
                                        multiline
                                        rows={4}
                                        error={formik.touched.remark && Boolean(formik.errors.remark)}
                                        helperText={formik.touched.remark && formik.errors.remark}
                                    />
                                </Grid>
                            </>
                        )}

                        {formik.values.issueType === 'paymentissue' && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Payment Issue</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="amount"
                                        name="amount"
                                        label="Amount"
                                        value={formik.values.amount}
                                        onChange={handleNumberChange} // Use the custom handleChange function here
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        placeholder="Enter amount"
                                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                                        helperText={formik.touched.amount && formik.errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)}>
                                        <InputLabel id="bank-account-label">Bank Account</InputLabel>
                                        <Select
                                            labelId="bank-account-label"
                                            id="bankAccount"
                                            name="bankAccount"
                                            value={formik.values.bankAccount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            label="Bank Account"
                                        >
                                            <MenuItem value="account1">Yes Bank</MenuItem>
                                        </Select>
                                        {formik.touched.bankAccount && formik.errors.bankAccount && (
                                            <Typography color="error">{formik.errors.bankAccount}</Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={formik.touched.paymentMode && Boolean(formik.errors.paymentMode)}>
                                        <InputLabel id="payment-mode-label">Payment Mode</InputLabel>
                                        <Select
                                            labelId="payment-mode-label"
                                            id="paymentMode"
                                            name="paymentMode"
                                            value={formik.values.paymentMode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            label="Payment Mode"
                                        >
                                            <MenuItem value="mode1">Mode 1</MenuItem>
                                            <MenuItem value="mode2">Mode 2</MenuItem>
                                            <MenuItem value="mode3">Mode 3</MenuItem>
                                        </Select>
                                        {formik.touched.paymentMode && formik.errors.paymentMode && (
                                            <Typography color="error">{formik.errors.paymentMode}</Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="utrNumber"
                                        name="utrNumber"
                                        label="UTR Number"
                                        value={formik.values.utrNumber}
                                        onChange={handleNumberChange} // Use the custom handleChange function here
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        placeholder="Enter UTR number"
                                        error={formik.touched.utrNumber && Boolean(formik.errors.utrNumber)}
                                        helperText={formik.touched.utrNumber && formik.errors.utrNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} style={{ marginBottom: '2%' }}>
                                    <Button
                                        sx={{
                                            height: lineHeight,
                                            width: '100%',
                                            fontSize: { xs: '8px', md: '15px' },
                                            textTransform: 'none',
                                            borderRadius: '8px',
                                            fontWeight: 'bold',
                                            backgroundColor: "#FFFFFF",
                                            border: '1px solid #027BFE',
                                            color: '#027BFE',
                                            '&:hover': {
                                                backgroundColor: '#FFFFFF',
                                                color: '#027BFE',
                                            },
                                            gap: '4%',
                                        }}
                                        component="label"
                                        fullWidth
                                    >
                                        Upload Bank Receipt
                                        <PlusIcon />
                                        <input
                                            type="file"
                                            name="bankReceipt"
                                            hidden
                                            onChange={(event) => {
                                                formik.setFieldValue('bankReceipt', event.currentTarget.files[0]);
                                            }}
                                            disabled={!areRequiredFieldsFilled}
                                        />
                                    </Button>
                                    {formik.values.bankReceipt && (
                                        <Typography variant="body1">
                                            Selected File: {formik.values.bankReceipt.name}
                                        </Typography>
                                    )}
                                    {formik.touched.bankReceipt && formik.errors.bankReceipt && (
                                        <Typography color="error">{formik.errors.bankReceipt}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="offlineRemark"
                                        name="remark"
                                        label="Remark"
                                        value={formik.values.remark}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        placeholder="Enter your remark"
                                        multiline
                                        rows={4}
                                        error={formik.touched.remark && Boolean(formik.errors.remark)}
                                        helperText={formik.touched.remark && formik.errors.remark}
                                    />
                                </Grid>
                            </>
                        )}

                        {formik.values.issueType === 'infoissue' && (
                            <Grid item xs={12} md={3} style={{ marginBottom: '2%' }}>
                                <Button
                                    sx={{
                                        height: lineHeight,
                                        width: '100%',
                                        fontSize: { xs: '8px', md: '15px' },
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        fontWeight: 'bold',
                                        backgroundColor: "#FFFFFF",
                                        border: '1px solid #027BFE',
                                        color: '#027BFE',
                                        '&:hover': {
                                            backgroundColor: '#FFFFFF',
                                            color: '#027BFE',
                                        },
                                        gap: '4%',
                                    }}
                                    component="label"
                                    fullWidth
                                >
                                    Upload Bank Receipt
                                    <PlusIcon />
                                    <input
                                        type="file"
                                        name="bankReceipt"
                                        hidden
                                        onChange={(event) => {
                                            formik.setFieldValue('bankReceipt', event.currentTarget.files[0]);
                                        }}
                                    />
                                </Button>
                                {formik.values.bankReceipt && (
                                    <Typography variant="body1">
                                        Selected File: {formik.values.bankReceipt.name}
                                    </Typography>
                                )}
                                {formik.touched.bankReceipt && formik.errors.bankReceipt && (
                                    <Typography color="error">{formik.errors.bankReceipt}</Typography>
                                )}
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ marginTop: { xs: '0%', md: '1%' } }}>
                            <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                <Button
                                    onClick={formik.handleReset}
                                    sx={{
                                        border: '1.5px solid #FF0000',
                                        color: '#FF0000',
                                        textTransform: 'none',
                                        fontSize: '16px',
                                        height: lineHeight,
                                        width: { xs: '100%', md: '16%' },
                                        borderRadius: "9px",
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => handleSubmit(formik.values, formik.resetForm)}
                                    sx={{
                                        backgroundColor: "#027BFE",
                                        color: "white",
                                        fontSize: '16px',
                                        height: lineHeight,
                                        textTransform: "none",
                                        width: { xs: '100%', md: '16%' },
                                        '&:hover': {
                                            backgroundColor: "#027BFE",
                                            color: '#FFFFFF',
                                        },
                                        borderRadius: "9px",
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid maxWidth='100%' style={{ display: "flex", justifyContent: 'space-between', marginTop: '2%', gap: '2%' }}>
                <Grid style={{ width: '40%' }}>
                    <Paper style={{ boxShadow:'none', padding: '1rem', borderRadius: '12px', marginBottom: '4%', boxShadow:'none' }}>
                        <Grid>
                            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '17px' }}>My Tickets</Typography>
                                <ReadMoreIcon style={{ cursor: 'pointer' }} onClick={handleMoreTicket} />
                            </Grid>

                            <Grid style={{ paddingTop: '6%' }}>
                                {/* Check if userTickets array has tickets and render only the first 2 */}
                                {userTickets.slice(0, 2).length > 0 ? (
                                    userTickets.slice(0, 2).map((ticket, index) => (
                                        <Grid key={ticket.id || index} style={{ paddingTop: '4%' }}>
                                            <Paper style={{ boxShadow:'none', padding: '1rem', borderRadius: '12px', borderLeft: '4px solid red', boxShadow:'3px' }}>
                                                <Grid>
                                                    {/* Display Ticket ID */}
                                                    <Typography color='textSecondary'>{`#${ticket.id}`}</Typography>
                                                    {/* Display Ticket Subject, with fallback */}
                                                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', paddingTop: '1%' }}>
                                                        {ticket.subject || 'No Subject'}
                                                    </Typography>
                                                </Grid>
                                                <Divider />
                                                <Grid style={{ paddingTop: '4%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                                        {/* Display Ticket Date */}
                                                        <ClockIcon />
                                                        <Typography style={{ color: '#818181', fontSize: '14px', marginLeft: '8px' }}>
                                                            {ticket.date ? new Date(ticket.date).toLocaleString() : 'No Date'}
                                                        </Typography>
                                                    </Grid>
                                                    {/* Display Ticket Status, with default value of 'Pending' */}
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#FFE5E5',
                                                            color: '#FF0000',
                                                            textTransform: 'none',
                                                            fontWeight: 'bold',
                                                            borderRadius: '8px',
                                                            width: '30%',
                                                        }}
                                                    >
                                                        {ticket.status || 'Pending'}
                                                    </Button>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography>No tickets found</Typography>
                                )}
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
                <Grid style={{ width: '60%' }}>
                    {/* Ticket Process Section */}
                    <Paper style={{ boxShadow:'none', padding: '1rem', borderRadius: '12px', boxShadow:'none' }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '17px', marginBottom: '1rem' }}>
                            Ticket Process
                        </Typography>

                        {/* Search Section */}
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Search"
                                    InputProps={{
                                        style: {
                                            borderRadius: '10px',
                                            borderColor: '#E0E0E0',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    style={{
                                        backgroundColor: '#027BFE',
                                        color: 'white',
                                        textTransform: 'none',
                                        width: '100%',
                                        height: '56px',
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                    }}
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>

                        {/* Ticket Information */}
                        {userTickets.length > 0 ? (
                            // Render only the last ticket
                            <div key={userTickets[userTickets.length - 1].id}>
                                <Typography color="textSecondary" style={{ marginTop: '4%' }}>
                                    {`#${userTickets[userTickets.length - 1].id}`}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '1%' }}>
                                    {userTickets[userTickets.length - 1].subject || 'No Subject'}
                                </Typography>

                                <Grid style={{ display: 'flex', alignItems: 'center', paddingTop: '1%' }}>
                                    <ClockIcon />
                                    <Typography style={{ color: '#818181', fontSize: '14px', marginLeft: '8px' }}>
                                        {new Date(userTickets[userTickets.length - 1].date).toLocaleString() || 'No Date'}
                                    </Typography>
                                </Grid>

                                <Divider style={{ paddingTop: '2%' }} />

                                {/* Stepper Component */}
                                <Stack sx={{ width: '100%', paddingTop: '4%' }} spacing={4}>
                                    <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
                                        {steps.map((label, stepIndex) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                    <Typography>{label}</Typography>
                                                    <Typography variant="caption" color="textSecondary">
                                                        {stepDates[stepIndex]}
                                                    </Typography>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Stack>
                            </div>
                        ) : (
                            <Typography>No tickets found</Typography>
                        )}
                    </Paper>
                </Grid>


            </Grid>
        </Box>
    )
}

export default NewCreateTicket
