import React, { useState } from 'react';
import { Grid, TextField, MenuItem, Button, FormControl, InputLabel, Select, Box, FormLabel, Dialog, DialogContent, Typography, Card, CardContent } from '@mui/material';
import { NewBBPSIcon } from '../../res/NewWebIcons';

const NewBroadbandlandline = () => {
  const [operator, setOperator] = useState('');
  const [number, setNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [modalOpen, setModalOpen] = useState(false);


  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const operators = ['Operator 1', 'Operator 2', 'Operator 3']; // You can replace this with actual operator data

  const handleGetBill = () => {
    // Logic to get the bill (could be an API call)
    console.log('Fetching Bill for:', { operator, number, amount });
  };

  return (

    <Box sx={{ padding: "2rem" }}>
            {/* Header */}
                  <Grid style={{ marginTop:'1%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <FormLabel
                      component="legend"
                      style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
                      >
                        Pay Broadband / Landline Bill
                  </FormLabel>
                    <Typography>
                      <NewBBPSIcon/>
                    </Typography>
                  </Grid>
        <Grid container spacing={3} style={{marginTop:'1%'}}>
        {/* Select Operator */}
        <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth>
            <InputLabel>Operator</InputLabel>
            <Select
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
                label="Operator"
            >
                {operators.map((op, index) => (
                <MenuItem key={index} value={op}>
                    {op}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
        </Grid>

        {/* Number with STD Code */}
        <Grid item xs={12} sm={4} md={4}>
            <TextField
            fullWidth
            label="Number with STD Code"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            />
        </Grid>

        {/* Amount */}
        <Grid item xs={12} sm={4} md={4}>
            <TextField
            fullWidth
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            />
        </Grid>

        {/* Get Bill Button */}
        <Grid item xs={12} justifyContent="flex-end" style={{display:'flex', justifyContent:'flex-end'}}>
            <Box>
            <Button
            style={{
                backgroundColor: "#027BFE",
                color: "white",
                textTransform: "none",
                fontSize: "14px",
                width:'16%'
              }}
              onClick={handleModalOpen}
            >
                Get Bill
            </Button>
            </Box>
        </Grid>
        </Grid>

    {/* Payment Modal */}
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
          <DialogContent>
            <Grid container spacing={4}>
              {/* Left Section: Payment Details */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    border: "1px solid #D9D9D9",
                    borderRadius: "12px",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    Confirm your payment
                  </Typography>

                  {/* Consumer Number */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Operator
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {operator}
                    </Typography>
                  </Grid>

                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Customer Number
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {number}
                    </Typography>
                  </Grid>

                  {/* Amount */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {amount}
                    </Typography>
                  </Grid>

                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      color: "white",
                      backgroundColor: "#027BFE",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}

                  >
                    Proceed to Pay
                  </Button>
                </Box>
              </Grid>

              {/* Right Section: Wallet Info */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "#027BFE",
                    color: "white",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Wallet Balance
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" mt={1}>
                      1000
                    </Typography>
                    <Button
                      sx={{
                        mt: 3,
                        backgroundColor: "white",
                        color: "#027BFE",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Update Wallet
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
    </Box>
  );
};

export default NewBroadbandlandline;
