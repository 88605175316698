import { Grid, Container, Paper, Typography, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import logo from "../Assets/login/iPaisaLogo2.png";
import { Colors } from '../Reports/Colors';

const styles = {
    container: {
        padding: '1rem',
        width: '100%',
        maxWidth: '1000px',
        boxSizing: 'border-box',
        backgroundColor: '#f0f0f0',
        minHeight: '90vh',
    },
    paper: {
        padding: '2rem',
        backgroundColor: Colors.bgColor,
        boxSizing: 'border-box',
        color: Colors.textColor,
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '-2%',
    },
    logoSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '6%',
    },
    tableHeadCell: {
        fontWeight: 'bold',
        fontSize: '10px',
        color: Colors.textColor,
    },
    footerText: {
        fontSize: '12px',
        color: Colors.textColor,
    },
};

const formatDate = (dateString) => new Date(dateString).toLocaleDateString() || 'N/A';
const formatTime = (dateString) => new Date(dateString).toLocaleTimeString() || 'N/A';

const ReverseMoneyInvoice = ({ data }) => {
    const userID = sessionStorage.getItem('userId');
    const userName = sessionStorage.getItem('username');

    if (!data) return null;

    return (
        <Container style={styles.container}>
            <Paper elevation={3} style={styles.paper}>
                <Grid style={styles.header}>
                    <Typography>Reverse Money Receipt</Typography>
                </Grid>

                <Grid style={{ marginTop: '1%' }}>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid style={styles.logoSection}>
                            <img src={logo} alt="iPaisa Logo" style={{ height: 65, width: 65, borderRadius: '50%' }} />
                            <Typography variant="h4" style={{ fontWeight: 'bold' }}>iPaisa</Typography>
                        </Grid>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>EDSOM FINTECH PRIVATE LIMITED</Typography>
                    </Grid>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1%' }}>
                        <Grid>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{formatDate(data.dt)}</Typography>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{formatTime(data.dt)}</Typography>
                        </Grid>
                        <Grid>
                            <Typography style={{ fontSize: '14px', textAlign: "end" }}>201, BANER ROAD, PUNE, MAHARASHTRA - 411045</Typography>
                            <Typography style={{ fontSize: '14px', textAlign: "end" }}>edsomfintech@gmail.com</Typography>
                        </Grid>
                    </Grid>

                    <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "3%" }}>
                        <Grid>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>From:</Typography>
                            <Typography variant="body1">{data.receiverName}</Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>To:</Typography>
                            <Typography variant="body1">{userName}</Typography>
                        </Grid>
                    </Grid>

                    <TableContainer style={{ marginTop: '5%' }}>
                        <Table aria-label="transaction table" style={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    {['Txn ID', 'Receiver ID', 'Reversed By', 'Receiver Name', 'User Type', 'Amount', 'Status'].map((header, index) => (
                                        <TableCell key={index} style={styles.tableHeadCell}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontSize: '10px', color: Colors.textColor }}>{data.tId || 'N/A'}</TableCell>
                                    <TableCell style={{ fontSize: '10px', color: Colors.textColor }}>{data.receiverId || 'N/A'}</TableCell>
                                    <TableCell style={{ fontSize: '10px', color: Colors.textColor }}>{data.reverseBy || 'N/A'}</TableCell>
                                    <TableCell style={{ fontSize: '10px', color: Colors.textColor }}>{data.receiverName || 'N/A'}</TableCell>
                                    <TableCell style={{ fontSize: '10px', color: Colors.textColor }}>{data.utype || 'N/A'}</TableCell>
                                    <TableCell style={{ fontSize: '10px', color: Colors.textColor }}>{data.amount || 'N/A'}</TableCell>
                                    <TableCell style={{ fontSize: '10px', color: Colors.textColor }}>
                                        {data.status || 'N/A'}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid style={{ marginTop: '4%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Thank you for transacting at iPaisa!</Typography>
                            <Typography style={styles.footerText}>This is a system-generated receipt and does not require a signature.</Typography>
                            <Typography style={styles.footerText}>Queries? Write to care@ipaisa.co.in</Typography>
                        </Grid>
                        <Grid>
                            <Divider />
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Total Amount: {data.amount}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default ReverseMoneyInvoice;
