import React, { useState } from "react";
import {
  SwipeableDrawer,
  Box,
  Typography,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import mealImg from "../Assets/Services/Flight/mealblurred.jpg";

const MealDrawer = ({ open, onClose, onOpen, mealData, mealDynamicData }) => {
  const [selectedMeals, setSelectedMeals] = useState([]);

  const handleToggleMeal = (meal) => {
    setSelectedMeals((prevSelectedMeals) => {
      const isAlreadySelected = prevSelectedMeals.some(
        (selectedMeal) =>
          selectedMeal.AirlineCode === meal.AirlineCode &&
          selectedMeal.FlightNumber === meal.FlightNumber &&
          selectedMeal.Code === meal.Code
      );

      if (isAlreadySelected) {
        // Remove meal from selected
        const updatedMeals = prevSelectedMeals.filter(
          (selectedMeal) =>
            !(
              selectedMeal.AirlineCode === meal.AirlineCode &&
              selectedMeal.FlightNumber === meal.FlightNumber &&
              selectedMeal.Code === meal.Code
            )
        );
        console.log("Selected Meals:", updatedMeals);
        return updatedMeals;
      } else {
        // Add meal to selected
        const updatedMeals = [...prevSelectedMeals, meal];
        console.log("Selected Meals:", updatedMeals);
        return updatedMeals;
      }
    });
  };

  const calculateTotalPrice = () => {
    return selectedMeals.reduce((total, meal) => total + meal.Price, 0);
  };

  const renderMeals = () => {
    if (mealDynamicData?.[0]?.length) {
      const dynamicMeals = mealDynamicData[0];

      return dynamicMeals
        .filter((meal) => meal.Code !== "NoMeal")
        .map((meal, index) => {
          const isSelected = selectedMeals.some(
            (selectedMeal) =>
              selectedMeal.AirlineCode === meal.AirlineCode &&
              selectedMeal.FlightNumber === meal.FlightNumber &&
              selectedMeal.Code === meal.Code
          );

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={mealImg}
                  alt="Meal Image"
                />
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    {meal.AirlineDescription || "No Description Available"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Price:{" "}
                    <strong>
                      {meal.Currency === "INR" ? "₹" : meal.Currency}{" "}
                      {meal.Price}
                    </strong>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Route:{" "}
                    <strong>
                      {meal.Origin} ➡ {meal.Destination}
                    </strong>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Flight:{" "}
                    <strong>
                      {meal.AirlineCode} - {meal.FlightNumber}
                    </strong>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    color={isSelected ? "secondary" : "primary"}
                    size="small"
                    onClick={() => handleToggleMeal(meal)}
                  >
                    {isSelected ? "Remove" : "Add"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        });
    }

    if (mealData?.length) {
      return mealData.map((meal, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardMedia
              component="img"
              height="140"
              image={mealImg}
              alt="Meal Image"
            />
            <CardContent>
              <Typography variant="h6" fontWeight="bold">
                {meal.Description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleToggleMeal(meal)}
              >
                Add
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ));
    }

    return (
      <Typography variant="body1" color="textSecondary">
        No meals available.
      </Typography>
    );
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      sx={{ zIndex: 1300 }}
    >
      <Box sx={{ width: 950, p: 3, mb: "150px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold" fontFamily="open sans">
            Select Meal
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            {renderMeals()}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          background: "#e3e3e3",
          width: "100%",
          height: "50px",
          position: "fixed",
          bottom: 0,
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {selectedMeals ? (
          <Typography variant="h6" color="primary">
           Total Price: ₹{calculateTotalPrice()}
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            Please select a meal
          </Typography>
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default MealDrawer;
