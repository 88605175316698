import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Download, FilterAlt } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import * as writeXLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const HierarchyTrans = () => {
  const [trnsList, setTranslist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageFrom, setPageFrom] = useState(0); // Total pages
  const [pageTo, setPageTo] = useState(0); // Total pages
  const [pageSize, setPageSize] = useState(50);
  const [hasNext, setHasNext] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null); // For filter icon menu
  const [availableUserTypes, setAvailableUserTypes] = useState([]);
  const [allavailableUsers, setUsers] = useState([]);
  const [userType, setUserType] = useState("");
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [filters, setFilters] = useState({
    id: "",
    startDate: dayjs().format("YYYY-MM-DD"), // Default to 30 days ago
    endDate: dayjs().format("YYYY-MM-DD"), // Default to today
    utr: "",
    uid: "",
  }); // Filter states
  var totalAmount = 0;
  const classes = useStyles();
  const navigate = useNavigate();

  const statusColors = {
    received: "#67C529",
    pending: "#E8DC22",
    uncaptured: "#E8DC22",
    "timed out": "#E29D95",
    failure: "#FF0000",
    unsettled: "#E8DC22",
    "partially refunded": "#3D8AC5",
    refunded: "#655AA1",
  };

  //Handle for usertype selection
  const handleUserChange = (event) => {
    // setUserType(event.target.value);
    const selectedUser = event.target.value;
    console.log(selectedUser.userid);
    // setSelectedUserId(null);
    setFilters((prevFilters) => ({
      ...prevFilters,
      uid: selectedUser.userid,
    }));
  };

  const handleClickOpenDownloadWindow = () => {
    setOpen(true);
  };

  const handleCloseDownloadWindow = () => {
    setOpen(false);
  };

  const onClickGetList = () => {
    fetchTransList(1);
  };
  //Handle for usertype selection
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    // setSelectedUserId(null);
  };

  // Handle filter menu open/close
  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchTransList(newPage);
  };
  // Separate function to handle DatePicker change
  const handleDateChange = (key, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
    }));
  };

  // Function to handle filter changes for text inputs
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  // Apply filters and call the fetchList function with page 1
  const applyFilters = () => {
    handleFilterMenuClose();
    if (
      filters.endDate &&
      filters.startDate &&
      dayjs(filters.endDate).isBefore(dayjs(filters.startDate))
    ) {
      // toast.error("End Date cannot be less than Start Date.");
      Swal.fire({
        title: "Something Went Wrong..!!",
        text: "End Date cannot be less than Start Date.",
        icon: "error",
      });
      return;
    } else {
      // setErrorMessage(""); // Clear error if validation passes
      fetchTransList(1); // Trigger fetch with the current filters
    }
    // console.log("Filters applied:", filters); // Debugging
  };

  const fetchList = async (page) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    // console.log(filteredTransactions + " this is filter");

    try {
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/virtual/hierarchy",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      //   console.log(response);
      const resultsArr = response.data;
      setUsers(resultsArr);
      //   console.log(resultsArr);
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };
  const handleNavigation = () => {
    navigate("/virtualtrans"); // Specify your target route here
  };

  //   const Dropdown = ({ users, onUserSelect }) => {
  //     const [selectedUser, setSelectedUser] = useState('');

  //     const handleChange = (event) => {
  //       const userId = event.target.value;
  //       setSelectedUser(userId);
  //       onUserSelect(userId); // Call parent function to notify about selected user
  //     };
  const fetchTransList = async (page) => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    // console.log(filteredTransactions + " this is filter");

    var tosendpagsize = pageSize;
    console.log("this is paheSize", tosendpagsize);
    // Prepare the params based on the filters state
    const params = {
      created_at: ``,
      created_at_start: filters.startDate
        ? dayjs(filters.startDate).format("YYYY-MM-DD")
        : ``,
      created_at_end: filters.endDate
        ? dayjs(filters.endDate).format("YYYY-MM-DD")
        : ``,
      current: page,
      order_id: ``,
      pageSize: tosendpagsize,
      status: filters.status || ``,
      unique_transaction_reference: filters.utr || ``,
      user_id: filters.uid || ``,
    };

    // console.log(params);
    try {
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/virtual/hierarchytransaction",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
          params: params, // Use the dynamic params here
        }
      );
      // console.log(response.data.data);
      const resultsArr = response.data.data.results;
      const settingHasNext = response.data.data.pagination.hasNext;
      const settingPageFrom = response.data.data.pagination.showing.from;
      const settingPageTo = response.data.data.pagination.showing.to;
      // console.log(resultsArr);
      setTranslist(resultsArr);
      setHasNext(settingHasNext);
      setPageFrom(settingPageFrom);
      setPageTo(settingPageTo);
      // console.log(resultsArr);
      return resultsArr;
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };

  const handleDownload = async () => {
    console.log("download clicked");
    setLoading(true);
    setPageSize(1000);
    setTriggerDownload(true);
  };

  const listDownload = async () => {
    console.log("In List download");
    // setPageSize(1000);
    // setPageSize(1000);

    try {
      // Assuming fetchList is asynchronous and returns the data

      let allData = [];
    let currentPage = 1; // Start from the first page
    let hasNextPage = hasNext;
    console.log("In list download");

    while (hasNextPage) {
      // Fetch the data for the current page
      const response = await fetchTransList(currentPage);

      // Append the results from the current page to the full dataset
      allData = [...allData, ...response];

      // Check if there are more pages to fetch
      hasNextPage = response.length === pageSize; // If response has fewer items than pageSize, we've reached the last page
      currentPage++; // Move to the next page
    }

      if (!allData || !allData.length) {
        console.error("No data available to download.");
        handleCloseDownloadWindow();
        Swal.fire({
          title: "Transactions Report",
          text: "There is no Transactions",
          icon: "info",
          timer: 2000,
        });
        return;
      }

      // Format the data for Excel (select necessary fields)
      const formattedData = allData.map((transaction) => ({
        TransactionID: transaction.id,
        Status: transaction.status,
        transaction_date: transaction.transaction_date,
        CreatedAt: transaction.created_at,
        PaymentMode: transaction.payment_mode,
        Narration: transaction.narration,
        UTR: transaction.unique_transaction_reference,
        RemitterName: transaction.remitter_full_name,
        RemitterUPI: transaction.remitter_upi_handle,
        Amount: transaction.amount,
        VirtualAccount: transaction.virtual_account.virtual_account_number,
        VirtualAccountUPI: transaction.virtual_account.virtual_upi_handle,
      }));

      // Create a worksheet from the data
      const worksheet = writeXLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and add the worksheet
      const workbook = writeXLSX.utils.book_new();
      writeXLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

      // Create and download the Excel file
      writeXLSX.writeFile(workbook, "UPI_Transactions_Report.xlsx");
      setLoading(false);
      handleCloseDownloadWindow();
      window.location.reload();
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  useEffect(() => {
    fetchList(currentPage);
    if (triggerDownload) {
      console.log("in if trigger Download");
      listDownload();
    }
    const uType = sessionStorage.getItem("uType");
    if (uType === "ADMIN" || uType === "SUBADMIN") {
      setAvailableUserTypes([
        "CHANNELPARTNER",
        "SUPERDISTRIBUTOR",
        "MASTERDISTRIBUTOR",
        "AREADISTRIBUTOR",
        "RETAILER",
      ]);
    } else if (uType === "CHANNELPARTNER") {
      setAvailableUserTypes([
        "SUPERDISTRIBUTOR",
        "MASTERDISTRIBUTOR",
        "AREADISTRIBUTOR",
        "RETAILER",
      ]);
    } else if (uType === "SUPERDISTRIBUTOR") {
      setAvailableUserTypes([
        "MASTERDISTRIBUTOR",
        "AREADISTRIBUTOR",
        "RETAILER",
      ]);
    } else if (uType === "MASTERDISTRIBUTOR") {
      setAvailableUserTypes(["AREADISTRIBUTOR", "RETAILER"]);
    } else if (uType === "AREADISTRIBUTOR") {
      setAvailableUserTypes(["RETAILER"]);
    } else {
      setAvailableUserTypes([]);
    }
  }, [triggerDownload]);

  return (
    <>
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Typography sx={{fontSize:'22px', fontWeight:'bold', marginBottom:'1%'}}>This is Hierarchy-wise QR Transactions List </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 size={{ md: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Select User Type</InputLabel>
              <Select
                value={userType}
                sx={{ width: "100%" }}
                label="Select User Type"
                labelId="user-type-label"
                onChange={handleUserTypeChange}
              >
                {availableUserTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 size={{ md: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="user-label">Select User</InputLabel>
              <Select
                //   value={userType}
                sx={{ width: "100%" }}
                label="Select User"
                labelId="user-label"
                onChange={handleUserChange}
              >
                {userType ? (
                  allavailableUsers.filter((user) => user.userRole === userType)
                    .length > 0 ? (
                    allavailableUsers
                      .filter((user) => user.userRole === userType)
                      .map((user) => (
                        <MenuItem key={user} value={user}>
                          {user.userName}
                        </MenuItem>
                      ))
                  ) : (
                    <MenuItem disabled>No users found</MenuItem>
                  )
                ) : (
                  <MenuItem disabled>Please select a user type first</MenuItem>
                )}
                {/* {allavailableUsers.length > 0 ? (
                  allavailableUsers
                    .filter((type) => type.userRole === userType)
                    .map((type) => (
                      <MenuItem key={type} value={type}>
                        {type.userName}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem disabled>No users found</MenuItem>
                )} */}
              </Select>
            </FormControl>
          </Grid2>

          <Grid2 size={{ md: 2 }}>
            <LoadingButton
              variant="contained"
              size="large"
              onClick={onClickGetList}
            >
              Get List
            </LoadingButton>
          </Grid2>
        </Grid2>
        {/* Filter Button with Icon */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          {/* <Select
            labelId="user-select-label"
            id="user-select"
            value=""
            label="Select User"
            onChange={handleChange}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select> */}
          <IconButton onClick={handleFilterMenuClick}>
            <FilterAlt />
          </IconButton>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleClickOpenDownloadWindow}>
              <Typography>Download Report </Typography> <Download />
            </IconButton>
            <Typography
              onClick={handleNavigation}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginLeft: 2,
              }}
            >
              Back
            </Typography>
          </Box>
          {/* Modal Window */}
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleCloseDownloadWindow}
          >
            <DialogTitle id="responsive-dialog-title">
              Download Report
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You can Download Upto 1000 Transactions
              </DialogContentText>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
              {/* <Autocomplete
                sx={{ width: "100%", marginTop: 2 }}
                options={accList}
                loading={!accList.length} // Show a spinner until data is loaded
                getOptionLabel={(option) =>
                  `${option.label} ${option.virtual_upi_handle || ""} `
                }
                inputValue={inputValue}
                onInputChange={(event, value) => {
                  // value will be the entire selected object
                  console.log("Selected value:", value); // Log the selected value
                  setInputValue(value);
                  if (value) {
                  } else {
                    console.log("No value selected");
                  }
                }}
                onChange={(event, value) => {
                  // This will handle the selection of the option (the entire object)
                  console.log("Selected object:", value); // Log the selected object
                  if (value) {
                    handleSearchInputChange(event, value); // Pass the selected object here
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search with label"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!accList.length ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  />
                )}
              /> */}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDownloadWindow}>
                Cancel
              </Button>

              <LoadingButton
                autoFocus
                loading={loading}
                onClick={handleDownload}
              >
                <Download /> Download
              </LoadingButton>
            </DialogActions>
          </Dialog>
          {/* Filter Menu */}
          <Menu
            anchorEl={filterMenuAnchor}
            open={Boolean(filterMenuAnchor)}
            onClose={handleFilterMenuClose}
            disableAutoFocus
            disableEnforceFocus
          >
            <MenuItem>
              <TextField
                label="Transaction ID"
                name="id"
                value={filters.id}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
                autoFocus
              />
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <TextField
                label="UTR Number"
                name="utr"
                value={filters.utr}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
              />
            </MenuItem>

            <MenuItem>
              <Button variant="contained" onClick={applyFilters}>
                Apply Filters
              </Button>
            </MenuItem>
          </Menu>
        </Box>

        {/* Table Container Started...!!! */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{
                backgroundColor: "#027BFE",
                color: "white",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Sr. No.
                </TableCell>
                {/* <TableCell style={{ color: 'white' }} align="center">Type</TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Remitter Full Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Payment Mode
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Amount
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  UTR Number
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Transaction Date
                </TableCell>
                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Full Name
                </TableCell> */}
                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Phone No.
                </TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  UPI ID/Handle
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Remmitter Account No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Remmitter IFSC No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Label
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Virtual UPI Handle
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trnsList && trnsList.length > 0 ? (
                trnsList.map((txn, i) => {
                  if (txn.status === "failure") {
                  } else {
                    totalAmount = totalAmount + txn.amount;
                  }
                  return (
                    <TableRow
                      key={txn.id} // Use unique key based on the transaction ID
                      className={`${classes.tableRow} ${i % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell>{i + 1}</TableCell> {/* Serial Number */}
                      {/* <TableCell>{txn.id}</TableCell> Transaction ID */}
                      <TableCell>{txn.remitter_full_name}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {/* Colored dot */}
                          <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                statusColors[txn.status] || "black", // Default color if status is not found
                              marginRight: 1, // Space between dot and status text
                            }}
                          />
                          {/* <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                txn.status === "received" ? "green" : "red", // Set color based on status
                              marginRight: 1, // Space between dot and status text
                            }}
                          /> */}
                          {/* Status text */}
                          <Typography>{txn.status}</Typography>
                        </Box>
                      </TableCell>
                      {/* Transaction Status */}
                      <TableCell>{txn.payment_mode}</TableCell>
                      {/* Payment Mode */}
                      <TableCell>{txn.amount}</TableCell>
                      {/* {(totalAmount = totalAmount + txn.amount)} */}
                      {/* Transaction Amount */}
                      <TableCell>{txn.unique_transaction_reference}</TableCell>
                      {/* UTR */}
                      {/* Remitter Name */}
                      <TableCell>
                        {dayjs(txn.transaction_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </TableCell>
                      {/* {"Transaction Date"} */}
                      {/* Remitter Phone */}
                      {/* {"Remitter Name "} */}
                      {/* UPI Handle */}
                      {/* <TableCell>{txn.remitter_phone_number}</TableCell> */}
                      {/* {"Remitter Phone Number "} */}
                      {/* Creation Date */}
                      <TableCell>{txn.remitter_upi_handle}</TableCell>
                      {/* {"Remitter UPI "} */}
                      {/* Settlement Date */}
                      <TableCell>{txn.remitter_account_number}</TableCell>
                      {/* {"Account Number "} */}
                      {/* Bank Settlement Date */}
                      <TableCell>{txn.remitter_account_ifsc}</TableCell>
                      {/* {"IFSC "} */}
                      {/* Virtual Account */}
                      <TableCell>{txn.virtual_account.label}</TableCell>
                      {/* {"Virtual Label "} */}
                      <TableCell>
                        {txn.virtual_account.virtual_upi_handle}
                      </TableCell>
                      {/* {"Virtual UPI "} */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No transactions available
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={9} align="center" sx={{ width: "100%" }}>
                  <Typography>Total Amount = Rs. {totalAmount}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage <= 1}
                    size="small"
                    sx={{ marginRight: 1 }}
                  >
                    Back
                  </Button>
                  <span>
                    Showing Transactions From {pageFrom} To {pageTo}
                  </span>
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={!hasNext}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  >
                    Next
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default HierarchyTrans;
