import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Container,
  Card,
  CardContent,
  Box,
  InputAdornment,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import { ReactComponent as ChargeBackCard } from "../Assets/Dashboard/ChargeBackCard.svg";
import axios from "axios";
import { Padding } from "@mui/icons-material";

const AdminChargeBackReports = () => {
  const [list, setList] = useState([]);
  const loadData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    const url =
      "https://api.ipaisa.co.in/myapp/auth/getchargebackallocationlistparent";
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      console.log(response.data);
      if (response?.status === 200 && response.data?.users) {
        setList(response.data.users);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
  <Box style={{ padding:'2rem',marginTop: 'calc(3% + 56px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Charge Back</Typography>
            <PartnerArrowIcon />
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Charge Back Allotement</Typography>
      </Grid>
              <Grid style={{marginTop:'1%'}}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                    fontSize: '19px',
                    color: '#343C6A',
                    textAlign: { xs: 'center', md: 'left' }, // Center on small screens
                  }}
                >
                  Charge Back Allotement
                </Typography>
              </Grid>
        <Grid style={{marginTop:'3%'}}>
          <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px', boxShadow:'none' }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#027BFE" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Sr No</TableCell>
                  <TableCell sx={{ color: "white" }}>ChargeBack ID</TableCell>
                  <TableCell sx={{ color: "white" }}>Transaction Date</TableCell>
                  <TableCell sx={{ color: "white" }}> Transaction Amount </TableCell>
                  <TableCell sx={{ color: "white" }}>ChargeBack Amount</TableCell>
                  <TableCell sx={{ color: "white" }}> Freeze Amount </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    ChargeBack Created
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      No Transactions
                    </TableCell>
                  </TableRow>
                ) : (
                  list.map((txn, i) => (
                    <TableRow>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{txn.chargeback?.chId}</TableCell>
                      <TableCell>
                      {txn.chargeback?.txnDate
                      ? new Date(txn.chargeback.txnDate).toLocaleString()
                      : "N/A"}
                      </TableCell>
                      <TableCell>{txn.chargeback?.amount}</TableCell>
                      <TableCell>{txn.chargeback?.chargeBackAmount}</TableCell>
                      <TableCell>{txn.freezeAmount}</TableCell>
                      <TableCell>
                      {txn.chargeback?.created
                      ? new Date(txn.chargeback.created).toLocaleString()
                      : "N/A"}
                      </TableCell>
                      <TableCell>{txn.chargeback?.settlementStatus}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
    </Box>
  );
};

export default AdminChargeBackReports;
