import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Paper,
  Typography,
  Slider,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormGroup,
  Tabs,
  Tab,
  Stack,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import brand from "../Assets/Services/Flight/brand.svg";
import before6am from "../Assets/Services/Flight/sun.svg";
import partlyCloudy from "../Assets/Services/Flight/partly_cloudy.svg";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import { Grid } from "@material-ui/core";
import EastIcon from "@mui/icons-material/East";
import Line from "../Assets/Services/Flight/Line.svg";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import FlightIcon from "@mui/icons-material/Flight";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import { useLocation, useNavigate } from "react-router-dom";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import OneWayCard from "./OneWayCard";
import DOMPurify from "dompurify";

function OneWay() {
  const navigate = useNavigate();

  const location = useLocation();

  // const flightsData = location.state || [];

  const { flightsData, searchDetails } = location.state || {};

  // console.log("searchDetails", searchDetails);

  const flights = flightsData?.Response?.Results || [];

  const traceid = flightsData?.Response?.TraceId || null;
  // console.log("traceid===", traceid)
  // console.log("Flights Data------: ", flightsData);
  // console.log("Flights: ", flights);

  const [priceRange, setPriceRange] = useState([0, 50000]);

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const durationCalculator = (source, destination) => {
    const start = new Date(source);
    const end = new Date(destination);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return "Invalid Date";
    }

    const diffInMs = end.getTime() - start.getTime();

    const diffInHours = diffInMs / (1000 * 60 * 60);
    const hours = Math.floor(diffInHours);
    const minutes = Math.round((diffInHours - hours) * 60);

    return `${hours} hrs ${minutes} mins`;
  };

  return (
    <Container px={3} maxWidth="lg" sx={{ marginTop: "8%" }}>
      <Typography>Service &gt; Flight Booking</Typography>

      <Paper
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {searchDetails.from}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <EastIcon />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {searchDetails.to}
            </Typography>
          </Box>
          <img src={Line} alt="Line" />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                opacity: 0.8,
              }}
            >
              Departure
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "short",
                year: "2-digit",
                weekday: "long",
              }).format(new Date(searchDetails.departDate))}
            </Typography>
          </Box>
          <img src={Line} alt="Line" />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                opacity: 0.8,
              }}
            >
              Travellers
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {/* 01 */}
              {searchDetails.travelers}
            </Typography>
          </Box>
          <img src={Line} alt="Line" />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                opacity: 0.8,
              }}
            >
              Travel Class
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {/* Economy */}
              {searchDetails.travelClass}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              background: "#027BFE",
              color: "white",
              textTransform: "none",
              px: 2,
              borderRadius: "10px",
            }}
          >
            Modify Search
          </Button>
        </Box>
      </Paper>

      <Box mt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Box>
              <Typography>Filters</Typography>

              <Accordion
                defaultExpanded
                sx={{ boxShadow: "none", border: "none" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Price</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Slider
                    getAriaLabel={() => "Price range"}
                    value={priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={50000}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography>₹{priceRange[0]}</Typography>
                    <Typography>₹{priceRange[1]}</Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Divider />

              <Accordion
                defaultExpanded
                sx={{ boxShadow: "none", border: "none" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Departure Time</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={before6am}
                        alt="before6am"
                        style={{ height: "20px" }}
                      />
                      <Typography sx={{ fontSize: "10px" }}>
                        Before 6 AM
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box>
                        <WbSunnyOutlinedIcon sx={{ fontSize: "20px" }} />
                      </Box>
                      <Typography sx={{ fontSize: "10px" }}>
                        6 AM - 12 PM
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={partlyCloudy}
                        alt="partlyCloudy"
                        style={{ height: "20px" }}
                      />
                      <Typography sx={{ fontSize: "10px" }}>
                        12 PM - 6 PM
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box>
                        <NightsStayOutlinedIcon sx={{ fontSize: "20px" }} />
                      </Box>
                      <Typography sx={{ fontSize: "10px" }}>
                        After 6 PM
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Divider />

              <Accordion
                defaultExpanded
                sx={{ boxShadow: "none", border: "none" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Airlines</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Emirated" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Fly Dubai"
                    />
                    <FormControlLabel control={<Checkbox />} label="Qatar" />
                    <FormControlLabel control={<Checkbox />} label="Etihad" />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            {/* Flight Details */}
            {/* <LinearProgress variant="determinate" /> */}
            {flights.length > 0 ? (
              flights.map((inner, index) =>
                inner.map((fdetails, i) => (
                  <>
                    <OneWayCard
                      key={i}
                      fdetails={fdetails}
                      durationCalculator={durationCalculator}
                      traceid={traceid}
                      searchDetails={searchDetails}
                    />
                  </>
                ))
              )
            ) : (
              <Typography>No flights available</Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default OneWay;
