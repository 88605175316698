// //working
// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   Paper,
//   Button,
//   Stack,
//   Divider,
//   useMediaQuery,
//   useTheme,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
// } from "@mui/material";
// import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
// import FlightIcon from "@mui/icons-material/Flight";
// import FlightClassIcon from "@mui/icons-material/FlightClass";
// // ... other imports

// const RoundTripCard = ({
//   fdetails,
//   handleToggleTabs,
//   showTabs,
//   activeTab,
//   durationCalculator,
// }) => {
//   // ... (rest of the code, including importAllLogos and airlineLogos)
//   const importAllLogos = (requireContext) => {
//     const logos = {};
//     requireContext.keys().forEach((key) => {
//       const airlineCode = key.replace("./", "").replace(".gif", "");
//       logos[airlineCode] = requireContext(key);
//     });
//     return logos;
//   };
//   const airlineLogos = importAllLogos(
//     require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
//   );

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const isMediumScreen = useMediaQuery(
//     theme.breakpoints.up("sm") && theme.breakpoints.down("md")
//   );

//   const [selectedCard, setSelectedCard] = useState(false);

//   const handleCardSelection = () => {
//     setSelectedCard(!selectedCard);
//   };

//   return (
//     <Box sx={{ mt: 2, maxWidth: "100%" }}>
//       {/* ... (rest of the code, including meal icon and header) */}

//       <Paper
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           borderRadius: "0px 0px 20px 20px",
//           mt: -1,
//           border: selectedCard ? "2px solid #007bff" : "none",
//           backgroundColor: selectedCard ? "#f0f0f0" : "inherit",
//         }}
//       >
//         <Stack
//           direction="row"
//           alignItems="center"
//           justifyContent="space-between"
//         >
//           <Stack direction="row" alignItems="center">
//             <Radio checked={selectedCard} onChange={handleCardSelection} />
//             <img
//               src={airlineLogos[fdetails.Segments[0][0].Airline.AirlineCode]}
//               alt={`${fdetails.Segments[0][0].Airline.AirlineName} logo`}
//               style={{ width: 30, height: 30, marginRight: 10 }}
//             />
//             <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
//               {fdetails.Segments[0][0].Airline.AirlineName} -{" "}
//               {fdetails.Segments[0][0].Airline.FlightNumber}
//             </Typography>
//           </Stack>
//           <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
//             ₹ {fdetails.Fare.PublishedFare * 1.11}
//           </Typography>
//         </Stack>

//         <Box
//           sx={{
//             px: 1,
//             py: 3,
//             display: "flex",
//             justifyContent: "space-between",
//             flexDirection: isSmallScreen ? "column" : "row",
//             alignItems: "center", // Align items to the center
//           }}
//         >
//           <Stack
//             direction={isSmallScreen ? "column" : "row"}
//             spacing={2}
//             alignItems="center"
//           >
//             <Stack direction="column" alignItems="center">
//               <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
//                 {console.log(
//                   fdetails.Segments[0][fdetails.Segments[0].length - 1]
//                 )}
//                 {new Date(
//                   fdetails.Segments[0][0].Origin.DepTime
//                 ).toLocaleTimeString("en-GB", {
//                   hour: "2-digit",
//                   minute: "2-digit",
//                 })}
//               </Typography>
//               <Typography>
//                 {fdetails.Segments[0][0].Origin.Airport.CityName}
//               </Typography>
//             </Stack>

//             <Stack direction="row" alignItems="center">
//               <FlightIcon sx={{ fontSize: 20, color: "#027BFE" }} />
//               <Typography
//                 sx={{ fontSize: 12, fontWeight: 600, color: "#027BFE" }}
//               >
//                 {durationCalculator(
//                   fdetails.Segments[0][0].Origin.DepTime,
//                   fdetails.Segments[0][fdetails.Segments[0].length - 1]
//                     .Destination.ArrTime
//                 )}
//               </Typography>
//             </Stack>

//             <Stack direction="column" alignItems="center">
//               <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
//                 {new Date(
//                   fdetails.Segments[0][
//                     fdetails.Segments[0].length - 1
//                   ].Destination.ArrTime
//                 ).toLocaleTimeString("en-GB", {
//                   hour: "2-digit",
//                   minute: "2-digit",
//                 })}
//               </Typography>
//               <Typography>
//                 {
//                   fdetails.Segments[0][fdetails.Segments[0].length - 1]
//                     .Destination.Airport.CityName
//                 }
//               </Typography>
//             </Stack>
//           </Stack>
//           <Stack direction="column" alignItems="center">
//             <FlightClassIcon sx={{ fontSize: 20, color: "#027BFE" }} />
//             <Typography
//               sx={{ fontSize: 12, fontWeight: 600, color: "#027BFE" }}
//             >
//               {
//                 fdetails.Segments[0][fdetails.Segments[0].length - 1]
//                   .NoOfSeatAvailable
//               }{" "}
//               Left
//             </Typography>
//           </Stack>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default RoundTripCard;

//working

import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  Divider,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import FlightIcon from "@mui/icons-material/Flight";
import FlightClassIcon from "@mui/icons-material/FlightClass";
// ... other imports

const RoundTripCard = ({
  fdetails,
  handleToggleTabs,
  showTabs,
  activeTab,
  durationCalculator,
  selectedValue,
  onRadioChange,
  isSelected,
  onSelect,
}) => {
  const airlineCode = fdetails.Segments[0][0].Airline.AirlineCode;
  const flightKey = `${airlineCode}-${fdetails.Segments[0][0].Airline.FlightNumber}`;
  // ... (rest of the code, including importAllLogos and airlineLogos)
  const importAllLogos = (requireContext) => {
    const logos = {};
    requireContext.keys().forEach((key) => {
      const airlineCode = key.replace("./", "").replace(".gif", "");
      logos[airlineCode] = requireContext(key);
    });
    return logos;
  };
  const airlineLogos = importAllLogos(
    require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.up("sm") && theme.breakpoints.down("md")
  );

  const [selectedCard, setSelectedCard] = useState(false);

  const handleCardSelection = () => {
    setSelectedCard(!selectedCard);
  };

  return (
    <Box sx={{ mt: 2, maxWidth: "100%" }}>
      {/* ... (rest of the code, including meal icon and header) */}

      <Paper
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          // mt: -1,
          border: selectedCard ? "2px solid #007bff" : "none",
          // border: selectedValue === flightKey ? "2px solid #007bff" : "1px solid #ddd",
          backgroundColor: selectedCard ? "#f0f0f0" : "white",
        }}
        onClick={onSelect}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <Radio checked={isSelected} onChange={onSelect} />
            <img
              src={airlineLogos[fdetails.Segments[0][0].Airline.AirlineCode]}
              alt={`${fdetails.Segments[0][0].Airline.AirlineName} logo`}
              style={{
                width: 30,
                height: 30,
                marginRight: 10,
                borderRadius: "5px",
              }}
            />
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {fdetails.Segments[0][0].Airline.AirlineName} -{" "}
              {fdetails.Segments[0][0].Airline.FlightNumber}
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              // width: "85px",
            }}
          >
            ₹ {(fdetails.Fare.PublishedFare * 1.11).toFixed(0)}
          </Typography>
        </Stack>

        <Box
          sx={{
            px: 1,
            py: 3,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center", // Align items to the center
          }}
        >
          <Stack
            direction={isSmallScreen ? "column" : "row"}
            spacing={4}
            alignItems="center"
          >
            <Stack direction="column" alignItems="center">
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {new Date(
                  fdetails.Segments[0][0].Origin.DepTime
                ).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
              <Typography>
                {fdetails.Segments[0][0].Origin.Airport.CityName}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <FlightIcon sx={{ fontSize: 20, color: "#027BFE" }} />
              <Typography
                sx={{ fontSize: 12, fontWeight: 600, color: "#027BFE" }}
              >
                {durationCalculator(
                  fdetails.Segments[0][0].Origin.DepTime,
                  fdetails.Segments[0][fdetails.Segments[0].length - 1]
                    .Destination.ArrTime
                )}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center">
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {new Date(
                  fdetails.Segments[0][
                    fdetails.Segments[0].length - 1
                  ].Destination.ArrTime
                ).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
              <Typography>
                {
                  fdetails.Segments[0][fdetails.Segments[0].length - 1]
                    .Destination.Airport.CityName
                }
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" alignItems="center">
            <FlightClassIcon sx={{ fontSize: 20, color: "#027BFE" }} />
            <Typography
              sx={{ fontSize: 12, fontWeight: 600, color: "#027BFE" }}
            >
              {
                fdetails.Segments[0][fdetails.Segments[0].length - 1]
                  .NoOfSeatAvailable
              }{" "}
              Left
            </Typography>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export default RoundTripCard;
