import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const UserServiceManagement = () => {
  const [userType, setUserType] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userData, setUserData] = useState(null); // To store the API response
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [providerpayinData, setPayinProviderData] = useState([]); // Initialize as empty array
  const [providerpayoutData, setPayoutProviderData] = useState([]); // Initialize as empty array
  const [selectedPayinProvider, setSelectedPayinProvider] = useState('');
  const [selectedPayoutProvider, setSelectedPayoutProvider] = useState('');
  const [service, setService] = useState('');
  const handleUserTypeChange = async (event) => {
    const selectedType = event.target.value;
    setUserType(selectedType);
    setSelectedUserId(null);

    // Fetch users based on selected user type
    const jwtToken = sessionStorage.getItem('jwtToken');
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/admin/getAllPartner`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          params: {
            userrole: selectedType,
          },
        }
      );

      if (response.status === 200) {
        setUsers(response.data); // Assuming the response contains the list of users
      } else {
        console.error('Failed to fetch partners');
      }
    } catch (error) {
      console.error('Error fetching partners:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserSelection = async (userId) => {
    setSelectedUserId(userId);
    const jwtToken = sessionStorage.getItem('jwtToken');

    try {
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/admin/checkUserPayinPayoutService?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.data.statusCodeValue === 200) {
        setUserData(response.data.body); // Store response data
      } else {
        console.error('Failed to fetch pay-in / pay-out service data');
      }
    } catch (error) {
      console.error('Error fetching pay-in / pay-out service data:', error);
    }
  };

  const fetchPayinProviderData = async () => {
    const jwtToken = sessionStorage.getItem('jwtToken');
    try {
      const response = await axios.get(
        'https://api.ipaisa.co.in/myapp/admin/get-all-gatway-provider',
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log('Payin Provider Response:', response.data);

      if (response.data.status === 200 && response.data.users) {
        setPayinProviderData(response.data.users); // Store provider data
      } else {
        console.error('Unexpected response format for PayIn providers:', response.data);
      }
    } catch (error) {
      console.error('Error fetching PayIn provider data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPayoutProviderData = async () => {
    const jwtToken = sessionStorage.getItem('jwtToken');
    try {
      const response = await axios.get(
        'https://api.ipaisa.co.in/myapp/admin/get-all-payout-provider',
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log('Payout Provider Response:', response.data);

      if (response.data.status === 200 && response.data.users) {
        setPayoutProviderData(response.data.users); // Store provider data
      } else {
        console.error('Unexpected response format for Payout providers:', response.data);
      }
    } catch (error) {
      console.error('Error fetching PayOut provider data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProviderSelect = async ( providerName, providerType) => {
    // const userId = sessionStorage.getItem('userId');
    let serviceType;
    let url;

    // Set the service based on providerType
    if (providerType === 'payin') {
      setService('payinService');
      serviceType = 'payinService'; // Correct parameter for payin
    } else {
      setService('payoutService');
      serviceType = 'payoutService'; // Correct parameter for payout
    }

    // Set the URL based on providerType
    if (providerType === 'payin') {
      url = `https://api.ipaisa.co.in/myapp/admin/updateUserPayinPayoutService?userId=${selectedUserId}&payinService=${providerName}`;
    } else {
      url = `https://api.ipaisa.co.in/myapp/admin/updateUserPayinPayoutService?userId=${selectedUserId}&payoutService=${providerName}`;
    }

    try {
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('jwtToken')}`,
          },
        }
      );

      if (response.data.statusCodeValue === 200) {
        console.log('API call successful');

        // Check providerType and show the corresponding success message
        const activeService = providerType === 'payin' ? 'active_payin' : 'active_payout';
        toast.success(`${response.data.body[activeService]} - activated`);  // Show success notification

      } else {
        console.error('API call failed');
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };



  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleClear = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    fetchPayinProviderData(); // Fetch PayIn data by default on component mount
    fetchPayoutProviderData(); // Fetch PayOut data by default on component mount
  }, []);

  return (
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>User Type</InputLabel>
              <Select
                label="User Type"
                value={userType}
                onChange={handleUserTypeChange}
              >
                <MenuItem value="AREADISTRIBUTOR">Area Distributor</MenuItem>
                <MenuItem value="RETAILER">Retailer</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>User</InputLabel>
              <Select
                value={selectedUserId || ''}
                onChange={(e) => handleUserSelection(e.target.value)}
                label="User"
              >
                {users
                  .filter((user) =>
                    user.Name.toLowerCase().includes(searchQuery)
                  )
                  .map((user) => (
                    <MenuItem key={user.userId} value={user.userId}>
                      {user.userId} - {user.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
          >
            <Button
              onClick={handleClear}
              sx={{
                backgroundColor: 'gray',
                color: 'white',
                textTransform: 'none',
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>

        {/* Render user data in table */}
        {userData && (
          <TableContainer style={{ marginTop: '2rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Service</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Status</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Active Payin</TableCell>
                  <TableCell>{userData.active_payin}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Active Payout</TableCell>
                  <TableCell>{userData.active_payout}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Render PayIn Providers dropdown */}
        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>PayIn Provider</InputLabel>
              <Select
                value={selectedPayinProvider}
                onChange={(e) =>
                  handleProviderSelect(e.target.value, 'payin')
                }
                label="PayIn Provider"
              >
                {providerpayinData.map((provider) => (
                  <MenuItem key={provider.providerName} value={provider.providerName}>
                    {provider.providerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Render Payout Providers dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Payout Provider</InputLabel>
              <Select
                value={selectedPayoutProvider}
                onChange={(e) =>
                  handleProviderSelect(e.target.value, 'payout')
                }
                label="Payout Provider"
              >
                {providerpayoutData.map((provider) => (
                  <MenuItem key={provider.providerName} value={provider.providerName}>
                    {provider.providerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
    </Box>
  );
};

export default UserServiceManagement;
