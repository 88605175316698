import { Box, Container, Paper, Typography } from "@mui/material";
import VirtualAccountReady from "./VirtaulAccountReady";
import { useEffect, useState } from "react";
import axios from "axios";
import VirtualAccountNotReady from "./VirtualAccountNotReady";

const QRCode = () => {
  const [virtualAccount, setVirtualAccount] = useState([]);
  const [status, setStatus] = useState([]);

  const fetchMyAccount = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    try {
      const response = await axios.get(
        // "https://api.ipaisa.co.in/myapp/virtual/myaccount",
        "https://api.ipaisa.co.in/myapp/virtual/myaccount",

        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      //   console.log(response.data);

      setVirtualAccount(response.data);
      //   console.log(response.data);
      setStatus(response.status);
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };

  useEffect(() => {
    fetchMyAccount();
  }, []);

  return (
    <>
  <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Typography sx={{fontSize:'22px', fontWeight:'bold', marginBottom:'1%'}}>My QR Code Info</Typography>
        {status === 200 ? (
          <VirtualAccountReady virtualAccount={virtualAccount} />
        ) : (
          <VirtualAccountNotReady />
        )}
      </Box>
    </>
  );
};

export default QRCode;
