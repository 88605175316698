import { Button, Container, Grid, IconButton, InputBase, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from "react";
import {AddUserIcon, PartnerArrowIcon} from '../../res/NewWebIcons';
import SearchIcon from "@mui/icons-material/Search";
import AddUserDialog from './AddUserDialog';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deletePartner, fetchPartners, togglePartnerStatus, updatePartners } from '../../redux/actions/partnerActions';



const PartnersTableTop = () => {
  const dispatch = useDispatch();
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [userType, setUserType] = useState('');

  useEffect(() => {
      const storedUserType = sessionStorage.getItem('uType');
      setUserType(storedUserType);
  }, []);

  const handleAddUserOpen = () => {
    setAddUserOpen(true);
  };

  const handleAddUser = (values) => {
    console.log('New User:', values);
    toast.success('User added successfully');
};

const handleAddUserClose = () => {
    setAddUserOpen(false);
    // dispatch(fetchPartners());

};

  const [searchQuery, setSearchQuery] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    return (
    <Grid>
        <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
            <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Partner</Typography>
            <PartnerArrowIcon/>
            <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Partners</Typography>
        </Grid>

        <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
            <Grid>
            <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Partners</Typography>
            </Grid>
          
            <Grid style={{display:'flex', gap:'5%'}}>
            <Grid style={{
                display:'flex',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                maxWidth: '60%',
                borderRadius: 10,
                border: '1px solid rgba(0, 0, 0, 0.3)',
                }}>

            <IconButton>
                <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
              </IconButton>
              <InputBase
                style={{ color: 'rgba(0, 0, 0, 3)' }}
                placeholder="Search"
                sx={{ width: '100%'}}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={() => setShowSearchInput(!showSearchInput)}
              />
            </Grid>
                <Button style={{textTransform:'none', backgroundColor:'#027BFE', color:'#FFFFFF', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', paddingRight:'5%', fontFamily:'sans-serif'}}  onClick={handleAddUserOpen}>
                <AddUserIcon/>
                Add User</Button>
            </Grid>

            <AddUserDialog
                open={addUserOpen}
                handleClose={handleAddUserClose}
                handleAddUser={handleAddUser}
            />
            
        </Grid>
    </Grid>
  )
}

export default PartnersTableTop
