import React, { useState } from "react";
import {
  SwipeableDrawer,
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CardMedia,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import baggageImg from "../Assets/Services/Flight/baggage.webp";

const BaggageDrawer = ({ open, onClose, onOpen, baggageData }) => {
  const [selectedBaggage, setSelectedBaggage] = useState(null); // Change state to store a single baggage

  const handleToggleBaggage = (baggage) => {
    if (selectedBaggage?.AirlineCode === baggage.AirlineCode && selectedBaggage.FlightNumber === baggage.FlightNumber && selectedBaggage.Code === baggage.Code) {
      // Deselect the baggage if it's already selected
      setSelectedBaggage(null);
    } else {
      // Select the new baggage, deselect the previous one
      setSelectedBaggage(baggage);
    }
  };

  const renderBaggage = () => {
    const baggageList = baggageData?.[0] || [];

    if (baggageList.length > 0) {
      return baggageList
        .filter((baggage) => baggage.Code !== "NoBaggage")
        .map((baggage, index) => {
          const isSelected = selectedBaggage?.AirlineCode === baggage.AirlineCode && selectedBaggage.FlightNumber === baggage.FlightNumber && selectedBaggage.Code === baggage.Code;

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="120"
                  image={baggageImg}
                  alt="Baggage Image"
                />
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    Weight: {baggage.Weight} kg
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Price:{" "}
                    <strong>
                      {baggage.Currency === "INR" ? "₹" : baggage.Currency}{" "}
                      {baggage.Price}
                    </strong>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    color={isSelected ? "secondary" : "primary"}
                    size="small"
                    onClick={() => handleToggleBaggage(baggage)}
                  >
                    {isSelected ? "Deselect" : "Select"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        });
    }

    return (
      <Typography variant="body1" color="textSecondary">
        No baggage options available.
      </Typography>
    );
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      sx={{ zIndex: 1300 }}
    >
      <Box sx={{ width: 950, p: 3, mb: "150px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold" fontFamily={"open sans"}>
            Select Baggage
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            {renderBaggage()}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          background: "#e3e3e3",
          width: "100%",
          height: "50px",
          position: "fixed",
          bottom: 0,
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {selectedBaggage ? (
          <Typography variant="h6" color="primary">
            Total Price:{" "}
            <strong>
              {selectedBaggage.Currency === "INR" ? "₹" : selectedBaggage.Currency}{" "}
              {selectedBaggage.Price}
            </strong>
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            Please select a baggage
          </Typography>
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default BaggageDrawer;
