import React, { useState } from "react";
import {
  SwipeableDrawer,
  Box,
  Typography,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SeatDrawer = ({
  open,
  onClose,
  onOpen,
  seatDynamicData = [],
  seatPreferenceData = [],
}) => {
  console.log("seatDynamicData from seatDynamicDrawer", seatDynamicData);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  const toggleSeatSelection = (segmentIndex, seat) => {
    setSelectedSeats((prev) => {
      const currentSegment = prev[segmentIndex] || [];
      const isSelected = currentSegment.some((s) => s.Code === seat.Code);

      const updatedSegment = isSelected
        ? currentSegment.filter((s) => s.Code !== seat.Code)
        : [...currentSegment, seat];

      const newSelectedSeats = { ...prev, [segmentIndex]: updatedSegment };

      // Update total price
      const newTotalPrice = Object.values(newSelectedSeats)
        .flat()
        .reduce((sum, s) => sum + s.Price, 0);
      setTotalPrice(newTotalPrice);

      return newSelectedSeats;
    });
  };

  const renderSeats = (seats, segmentIndex) => {
    return seats.map((seat) => {
      const isSelectable = seat.AvailablityType === 1;
      const isSelected =
        selectedSeats[segmentIndex]?.some((s) => s.Code === seat.Code) || false;

      return (
        <Tooltip key={seat.Code} title={`₹${seat.Price || 0}`} arrow>
          <Button
            onClick={() =>
              isSelectable && toggleSeatSelection(segmentIndex, seat)
            }
            sx={{
              margin: 1,
              backgroundColor: isSelected
                ? "green"
                : isSelectable
                  ? "white"
                  : "gray",
              color: isSelected || isSelectable ? "black" : "lightgray",
              border: "1px solid",
              borderColor: isSelected ? "darkgreen" : "gray",
              cursor: isSelectable ? "pointer" : "not-allowed",
            }}
          >
            {seat.Code || "No Seat"}
          </Button>
        </Tooltip>
      );
    });
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        sx={{ zIndex: 1300 }}
      >
        <Box sx={{ width: 950, p: 3, mb: "90px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="bold" fontFamily={"open sans"}>
              Select Seat
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mt={3}>
            {seatDynamicData.length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                Seat selection not available.
              </Typography>
            ) : (
              seatDynamicData.map((segment, segmentIndex) => (
                <Box key={segmentIndex} mb={4}>
                  <Typography variant="subtitle1" fontWeight="bold" mb={2}>
                    Segment {segmentIndex + 1} (
                    {segment.SegmentSeat[0]?.RowSeats[0]?.Seats[0]?.Origin} →{" "}
                    {segment.SegmentSeat[0]?.RowSeats[0]?.Seats[0]?.Destination}
                    )
                  </Typography>
                  {segment.SegmentSeat.map((segmentSeat, segIndex) =>
                    segmentSeat.RowSeats.map((row, rowIndex) => (
                      <Box
                        key={`${segIndex}-${rowIndex}`}
                        display="flex"
                        flexWrap="wrap"
                        mb={2}
                      >
                        <Typography variant="subtitle2" mr={2} mt={2}>
                          Row {row.Seats[0]?.RowNo}:
                        </Typography>
                        {renderSeats(row.Seats, `${segmentIndex}-${rowIndex}`)}
                      </Box>
                    ))
                  )}
                </Box>
              ))
            )}
          </Box>
        </Box>
        <Box
          sx={{
            background: "#e3e3e3",
            width: "100%",
            height: "50px",
            position: "fixed",
            bottom: 0,
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Total Price: ₹{totalPrice}</Typography>
        </Box>
      </SwipeableDrawer>
      {/* Bottom Price Box */}
    </>
  );
};

export default SeatDrawer;
